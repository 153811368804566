import axios from "axios";
import { useEffect, useState } from "react";
import "../Assets/css/bootstrap.min.css";
import Header from "./header";
import Footer from "./footer";
import { ReactSession } from "react-client-session";
import Modal from "react-bootstrap/Modal";
import Dropdown from "react-bootstrap/Dropdown";
import baseurl from "./globals";
import moment from "moment";
import { useAsyncError, useNavigate } from "react-router-dom";
import Header_mobile from "./header_mobile";
import { AiOutlinePlusCircle } from 'react-icons/ai';
import { FcClearFilters } from 'react-icons/fc';


function Materiels() {
    const navigate = useNavigate();

    const [refresh, setRefresh] = useState(false);

    const [listMaterial, setListMaterials] = useState([]);



    const [currentPage, setCurrentPage] = useState(1);
    const [linePerPage, setlinePerPage] = useState(13);
    const indexOfLastPost = currentPage * linePerPage;
    const indexOfFirstPost = indexOfLastPost - linePerPage;
    const currentMateriel = listMaterial.slice(indexOfFirstPost, indexOfLastPost)

    const [perm_view_equipement, setPerm_view_equipement] = useState(-1);
    const [perm_edit_equipement, setPerm_edit_equipement] = useState(-1);
    const [perm_add_equipement, setPerm_add_equipement] = useState(-1);
    const [perm_view_materials, setPerm_view_materials] = useState(-1);
    const [perm_edit_materials, setPerm_edit_materials] = useState(-1);
    const [perm_add_materials, setPerm_add_materials] = useState(-1);
    const [perm_view_affectation_site, setPerm_view_affectation_site] = useState(-1);
    const [perm_edit_affectation_site, setPerm_edit_affectation_site] = useState(-1);
    const [perm_add_affectation_site, setPerm_add_affectation_site] = useState(-1);
    const [perm_create_intervention, setPerm_create_intervention] = useState(-1);
    const [perm_edit_intervention, setPerm_edit_intervention] = useState(-1);
    const [perm_view_intervention, setPerm_view_intervention] = useState(-1);
    const [perm_assign_intervention, setPerm_assign_intervention] = useState(-1);
    const [perm_close_intervention, setPerm_close_intervention] = useState(-1);
    const [perm_edit_assign, setPerm_edit_assign] = useState(-1);
    const [perm_add_signature, setPerm_add_signature] = useState(-1);
    const [perm_cancel_intervention, setPerm_cancel_intervention] = useState(-1);
    const [perm_Report, setPerm_Report] = useState(-1);
    const [perm_List_User, setPerm_List_User] = useState(-1);
    const [perm_add_user, setPerm_add_user] = useState(-1);
    const [perm_edit_user, setPerm_edit_user] = useState(-1);
    const [perm_status_user, setPerm_status_user] = useState(-1);
    const [perm_edit_equipment_user, setPerm_edit_equipment_user] = useState(-1);
    const [perm_history_equipement, setPerm_history_equipement] = useState(-1);
    const [perm_status_equipement, setPerm_status_equipement] = useState(-1);
    const [perm_add_user_equipement, setPerm_add_user_equipement] = useState(-1);


    const [windowSize, setWindowSize] = useState(getWindowSize());

    useEffect(() => {
        handleOnLoad();
        function handleWindowResize() {
            setWindowSize(getWindowSize());
        }

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    function getWindowSize() {
        const { innerWidth, innerHeight } = window;
        return { innerWidth, innerHeight };
    }

    const handleOnLoad = () => {
        axios.post(baseurl + "?p=getUserPermissions", {
            userId: ReactSession.get('prev_id')
        }).then((response) => {
            (response.data.list).map((e, index) => {
                switch (e['usrper_per_id']) {
                    case 214:
                        setPerm_view_equipement(e['usrper_val']);
                        break;

                    case 215:
                        setPerm_edit_equipement(e['usrper_val']);
                        break;

                    case 216:
                        setPerm_add_equipement(e['usrper_val']);
                        break;

                    case 217:
                        setPerm_view_materials(e['usrper_val']);
                        if (e['usrper_val'] === 0) {
                            navigate('/home')
                        }
                        break;

                    case 218:
                        setPerm_edit_materials(e['usrper_val']);
                        break;

                    case 219:
                        setPerm_add_materials(e['usrper_val']);
                        break;

                    case 223:
                        setPerm_view_affectation_site(e['usrper_val']);
                        break;

                    case 224:
                        setPerm_edit_affectation_site(e['usrper_val']);
                        break;

                    case 225:
                        setPerm_add_affectation_site(e['usrper_val']);
                        break;

                    case 300:
                        setPerm_create_intervention(e['usrper_val']);
                        break;

                    case 301:
                        setPerm_edit_intervention(e['usrper_val']);
                        break;

                    case 302:
                        setPerm_view_intervention(e['usrper_val']);
                        break;

                    case 303:
                        setPerm_assign_intervention(e['usrper_val']);
                        break;

                    case 304:
                        setPerm_close_intervention(e['usrper_val']);
                        break;

                    case 305:
                        setPerm_edit_assign(e['usrper_val']);
                        break;

                    case 306:
                        setPerm_add_signature(e['usrper_val']);
                        break;

                    case 307:
                        setPerm_cancel_intervention(e['usrper_val']);
                        break;

                    case 308:
                        setPerm_Report(e['usrper_val']);
                        break;

                    case 400:
                        setPerm_List_User(e['usrper_val']);
                        break;

                    case 401:
                        setPerm_add_user(e['usrper_val']);
                        break;

                    case 402:
                        setPerm_edit_user(e['usrper_val']);
                        break;

                    case 403:
                        setPerm_status_user(e['usrper_val']);
                        break;

                    case 404:
                        setPerm_edit_equipment_user(e['usrper_val']);
                        break;
                    case 240:
                        setPerm_history_equipement(e['usrper_val']);
                        break;
                    case 241:
                        setPerm_status_equipement(e['usrper_val']);
                        break;
                    case 242:
                        setPerm_add_user_equipement(e['usrper_val']);
                        break;

                    default:
                        break;
                }
            })
        }).catch(function (error) {
            console.log("INTERNAL ERROR (handleOnLoad)", error.response.data, moment().format('DD-MM HH:mm'));
        });
    }

    useEffect(() => {
        getAllMateriels();
    }, [refresh]);


    const getAllMateriels = () => {
        axios.post(baseurl + "?p=getAllMateriels", {
        }).then((response) => {
            if (response.data.status) {
                setListMaterials(response.data.list);
            }
        }).catch(function (error) {
            console.log("INTERNAL ERROR (getAllMateriels)", error.response.data, moment().format('DD-MM HH:mm'));
        });
    }

    const Pagination = ({ postsPerPage, totalPosts, paginate }) => {
        var pageNumbers = [];
        var pageslink = 6;
        var startlink = 1;

        var totalPages = Math.ceil(totalPosts / postsPerPage);

        if (currentPage - 2 > 1 && currentPage + 2 <= totalPages) {
            startlink = currentPage - 2;
            pageNumbers = [];
            for (let i = startlink; i <= currentPage + 2; i++) {
                pageNumbers.push(i);
            }
        } else if (currentPage - 2 > 1 && currentPage + 1 <= totalPages) {
            startlink = currentPage - 3;
            pageNumbers = [];
            for (let i = startlink; i <= currentPage + 1; i++) {
                pageNumbers.push(i);
            }
        } else if (currentPage == totalPages) {
            if (currentPage - 5 >= 0) startlink = currentPage - 4;
            else if (currentPage - 4 >= 0) startlink = currentPage - 3;
            else if (currentPage - 3 >= 0) startlink = currentPage - 2;
            else if (currentPage - 2 >= 0) startlink = currentPage - 1;
            pageNumbers = [];
            for (let i = startlink; i <= totalPages; i++) {
                pageNumbers.push(i);
            }
        } else {
            startlink = 1;
            pageNumbers = [];
            if (pageslink > totalPages) pageslink = totalPages;
            for (let i = startlink; i <= pageslink; i++) {
                pageNumbers.push(i);
            }
        }

        return (
            <>
                <nav className="d-flex">
                    <ul className="pagination">
                        <li className="page-item">
                            {
                                <a onClick={() => paginate(1)} href="#" className="">
                                    {"«"}
                                </a>
                            }
                        </li>

                        <li className="page-item">
                            {currentPage == 1 ? (
                                <a href="#" className="" disabled>
                                    {"<"}
                                </a>
                            ) : (
                                <a
                                    onClick={() => paginate(currentPage - 1)}
                                    href="#"
                                    className=""
                                >
                                    {"<"}
                                </a>
                            )}
                        </li>

                        {pageNumbers.map((number) => (
                            <li key={number} className="page-item">
                                {currentPage == number ? (
                                    <a
                                        onClick={() => paginate(number)}
                                        href="#"
                                        className="bg-red"
                                    >
                                        {number}
                                    </a>
                                ) : (
                                    <a onClick={() => paginate(number)} href="#" className="">
                                        {number}
                                    </a>
                                )}
                            </li>
                        ))}

                        <li className="page-item">
                            {currentPage == totalPages ? (
                                <a href="#" className="" disabled>
                                    {">"}
                                </a>
                            ) : (
                                <a
                                    onClick={() => paginate(currentPage + 1)}
                                    href="#"
                                    className=""
                                >
                                    {">"}
                                </a>
                            )}
                        </li>

                        <li className="page-item">
                            {
                                <a onClick={() => paginate(totalPages)} href="#" className="">
                                    {"»"}
                                </a>
                            }
                        </li>
                    </ul>
                </nav>
            </>
        );
    };
    const paginate = (pageNumber) => setCurrentPage(pageNumber);
    return (
        <>
            <Header />
            <Header_mobile />
            <div
                className="d-flex flex-column flex-column-fluid "
                style={{ minHeight: "85vh", backgroundColor: "#eef0f8" }}
            >
                <div className=" container mt-20 mb-20  h-100 d-flex align-items-center justify-content-center" >
                    <div className="row mb-5">
                        <div className="col-xl-1"></div>
                        <div className="col-xl-12">
                            <div className="row">
                                <div className="col-xl-12 col-md-12" id="tck1">
                                    <div className="card card-custom gutter-b">
                                        <div className="card-body d-flex flex-column pb-0 ">
                                            <div className="flex-grow-1 pb-0">
                                                <div className="row text-left card-header " style={{ backgroundColor: "white" }}>
                                                    <div className="row text-left">
                                                        <div className="col">
                                                            <label> Matériels</label>
                                                            <i className="flaticon-refresh ml-2"> </i>
                                                        </div>
                                                        <div className="col text-right">
                                                            {windowSize.innerWidth < 768 ?
                                                                <>
                                                                    <FcClearFilters size={40} className="btn btn-light ml-2 cursor-pointer border" />
                                                                    {perm_add_materials === 1 ?
                                                                        <AiOutlinePlusCircle size={40} className="cursor-pointer text-danger border ml-2" />
                                                                        :
                                                                        <></>
                                                                    }

                                                                </>
                                                                :
                                                                <>
                                                                    <button className="btn btn-light">Supprimer le filtre</button>
                                                                    {perm_add_materials === 1 ?
                                                                        <button className="btn btn-danger ml-2">Ajouter un materiel</button>
                                                                        :
                                                                        <></>
                                                                    }

                                                                </>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="card-body">
                                                    <div className="table-responsive">
                                                        <table id="result" className="table data">
                                                            <thead>
                                                                <tr>
                                                                    <th className="text-left w-25px" scope="col"> #</th>
                                                                    <th className="text-left" style={{ width: '10px' }} scope="col"></th>
                                                                    <th className="text-left" style={{ width: '200px' }} scope="col">
                                                                        CODE DU MATERIEL
                                                                        <a href="#" data-toggle="modal" data-target="#fi_ref_modal">
                                                                            <i className="la la-filter"></i>
                                                                        </a>
                                                                    </th>
                                                                    <th className="text-left" style={{ width: '200px' }} scope="col">
                                                                        DESCRIPTION
                                                                        <a href="#" data-toggle="modal" data-target="#fi_ref_modal">
                                                                            <i className="la la-filter"></i>
                                                                        </a>
                                                                    </th>
                                                                    <th className="text-left" style={{ width: '100px' }} scope="col">
                                                                        CRÉE PAR
                                                                        <a href="#" data-toggle="modal" data-target="#fi_ref_modal">
                                                                            <i className="la la-filter"></i>
                                                                        </a>
                                                                    </th>
                                                                    <th className="text-left" style={{ width: '250px' }} scope="col">
                                                                        DATE DE CRÉATION
                                                                        <a href="#" data-toggle="modal" data-target="#fi_ref_modal">
                                                                            <i className="la la-filter"></i>
                                                                        </a>
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody id="resultdata">
                                                                {currentMateriel ?
                                                                    currentMateriel.map((d, index) => (
                                                                        <tr key={index}>
                                                                            <td className="text-left">{d.mt_id}</td>
                                                                            <td className="text-left">
                                                                                <Dropdown className="dropdown">
                                                                                    <Dropdown.Toggle
                                                                                        variant="dropdown-basic"
                                                                                        className="w-60px py-0 text-white p-0 m-0"
                                                                                    >
                                                                                        <div
                                                                                            className="dropdown dropdown-inline bg-light2 py-0 rounded"
                                                                                            data-toggle="dropdown"

                                                                                        >
                                                                                            <a

                                                                                                type="button"
                                                                                                className="btn btn-light2 btn-icon py-0 btn-sm"
                                                                                            >
                                                                                                <i className="ki ki-bold-more-hor"></i>
                                                                                            </a>
                                                                                        </div>
                                                                                    </Dropdown.Toggle>

                                                                                    <Dropdown.Menu className="">
                                                                                        <>
                                                                                            {perm_edit_materials === 1 ?
                                                                                                <Dropdown.Item
                                                                                                    href="#"
                                                                                                    className="dropdown-item border-bottom border-top"
                                                                                                    onClick={() => { }}
                                                                                                >
                                                                                                    <i className="far fa-edit"></i>{" "}
                                                                                                    <span className="pl-3">Modifier</span>
                                                                                                </Dropdown.Item>
                                                                                                :
                                                                                                <></>
                                                                                            }


                                                                                            <Dropdown.Item
                                                                                                href="#"
                                                                                                className="dropdown-item border-bottom border-top"
                                                                                                onClick={() => { }}
                                                                                            >

                                                                                                <i className="fa fa-ban"></i>{" "}
                                                                                                <span className="pl-3">Desactiver</span>
                                                                                            </Dropdown.Item>
                                                                                        </>
                                                                                    </Dropdown.Menu>
                                                                                </Dropdown>
                                                                            </td>
                                                                            <td className="text-left">{d.mt_code}</td>
                                                                            <td className="text-left">{d.mt_description}</td>
                                                                            <td className="text-left">{d.user_name}</td>
                                                                            <td className="text-left">{moment(d.mt_created_date).format('MMMM d, YYYY')}</td>
                                                                        </tr>
                                                                    )) : (<></>)
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="card-footer pt-2 pb-2 col-12 mb-0 bg-white">
                                            <div className=" col-sm-12 d-flex">
                                                <select className="page-range" onChange={(e) => { setlinePerPage(e.target.value); setCurrentPage(1) }}>
                                                    <option value={10}>10</option>
                                                    <option value={25}>25</option>
                                                    <option value={50}>50</option>
                                                    <option value={listMaterial.length}>All</option>
                                                </select>
                                                <div className="mt-6"> </div>
                                                <div className="mt-3"><div id="resultfound">{listMaterial.length} résultat(s)</div> </div>
                                            </div>
                                            <div className=" col-sm-12">
                                                <div className="d-flex align-items-center justify-content-center">

                                                    <Pagination
                                                        postsPerPage={linePerPage}
                                                        totalPosts={listMaterial.length}
                                                        paginate={paginate}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-xl-4">

                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default Materiels;