import axios from "axios";
import { useEffect, useState, useRef } from "react";
import "../Assets/css/bootstrap.min.css";
import Header from "./header";
import Footer from "./footer";
import baseurl from "./globals";
import Header_mobile from "./header_mobile";
import moment from "moment";


function Home() {
    const [totIntervention, setTotIntervention] = useState(0);
    const [totDoneIntervention, setTotDoneIntervention] = useState(0);
    const [totWeekIntervention, setTotWeekIntervention] = useState(0);
    const [totMonthIntervention, setTotMonthIntervention] = useState(0);

    const windowWidth = useRef(window.innerWidth);
    const windowHeight = useRef(window.innerHeight);
    const [windowSize, setWindowSize] = useState(getWindowSize());

    useEffect(() => {
        function handleWindowResize() {
            setWindowSize(getWindowSize());
        }

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);
    function getWindowSize() {
        const { innerWidth, innerHeight } = window;
        return { innerWidth, innerHeight };
    }


    const getTotalIntervention = () => {
        axios.post(baseurl + "?p=getTotalIntervention", {
        }).then((response) => {
            if (response.data.status) {
                setTotIntervention(response.data.result.length);
            } else {
            }
        }).catch(function (error) {
            console.log("INTERNAL ERROR (getTotalIntervention)", error.response.data, moment().format('DD-MM HH:mm'));
        });
    }

    const getTotalDoneIntervention = () => {
        axios.post(baseurl + "?p=getTotalDoneIntervention", {
        }).then((response) => {
            if (response.data.status) {
                setTotDoneIntervention(response.data.result.length)
            } else {
            }
        }).catch(function (error) {
            console.log("INTERNAL ERROR (getTotalDoneIntervention)", error.response.data, moment().format('DD-MM HH:mm'));
        });
    }

    const getTotalWeekIntervention = () => {
        axios.post(baseurl + "?p=getTotalWeekIntervention", {
        }).then((response) => {
            if (response.data.status) {
                setTotWeekIntervention(response.data.result.length);
            } else {
            }
        }).catch(function (error) {
            console.log("INTERNAL ERROR (getTotalWeekIntervention)", error.response.data, moment().format('DD-MM HH:mm'));
        });
    }

    const getTotalMonthIntervention = () => {
        axios.post(baseurl + "?p=getTotalMonthIntervention", {
        }).then((response) => {
            if (response.data.status) {
                setTotMonthIntervention(response.data.result.length);
            } else {
            }
        }).catch(function (error) {
            console.log("INTERNAL ERROR (getTotalMonthIntervention)", error.response.data, moment().format('DD-MM HH:mm'));
        });
    }

    useEffect(() => {
        getTotalIntervention();
        getTotalDoneIntervention();
        getTotalWeekIntervention();
        getTotalMonthIntervention();
    }, []);

    return (
        <>
            <Header />
            <Header_mobile />
            <div
                className="d-flex flex-column flex-column-fluid "
                style={{ minHeight: "85vh", backgroundColor: "#eef0f8" }}
            >
                <div className=" container mt-20 mb-20">
                    <div className="row mb-5">
                        <div className="col-xl-1"></div>
                        <div className="col-xl-10">
                            <div className="row">
                                <div className="col-xl-3 col-md-4" id="tck1">
                                    <div className="card card-custom gutter-b">
                                        <div className="card-body d-flex flex-column pb-0 ">
                                            <div className="flex-grow-1 pb-0">
                                                <div className="pr-2 mb-0 pt-3">
                                                    <h1
                                                        id="totTicket-number"
                                                        className="text-dark-75 text-left"
                                                    >
                                                        {totIntervention}
                                                    </h1>
                                                    <div className="fs-17 text-left h-80px">
                                                        Total interventions
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-3 col-md-4" id="tck1">
                                    <div className="card card-custom gutter-b">
                                        <div className="card-body d-flex flex-column pb-0 ">
                                            <div className="flex-grow-1 pb-0">
                                                <div className="pr-2 mb-0 pt-3">
                                                    <h1
                                                        id="totTicket-number"
                                                        className="text-dark-75 text-left"
                                                    >
                                                        {totDoneIntervention}
                                                    </h1>
                                                    <div className="fs-17 text-left h-80px">
                                                        Intervention Done
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-3 col-md-4" id="tck1">
                                    <div className="card card-custom gutter-b">
                                        <div className="card-body d-flex flex-column pb-0 ">
                                            <div className="flex-grow-1 pb-0">
                                                <div className="pr-2 mb-0 pt-3">
                                                    <h1
                                                        id="totTicket-number"
                                                        className="text-dark-75 text-left"
                                                    >
                                                        {totWeekIntervention}
                                                    </h1>
                                                    <div className="fs-17 text-left h-80px">
                                                        intervention for this week
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-3 col-md-4" id="tck1">
                                    <div className="card card-custom gutter-b">
                                        <div className="card-body d-flex flex-column pb-0 ">
                                            <div className="flex-grow-1 pb-0">
                                                <div className="pr-2 mb-0 pt-3">
                                                    <h1
                                                        id="totTicket-number"
                                                        className="text-dark-75 text-left"
                                                    >
                                                        {totMonthIntervention}
                                                    </h1>
                                                    <div className="fs-17 text-left h-80px">
                                                        Interventions for this month
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>


                    </div>

                    <div className="row">
                        <div className="col-xl-1"></div>
                        <div className="col-xl-10 col-md-12" id="tck1">
                            <div className="card card-custom gutter-b">
                                <div className="card-body d-flex flex-column pb-0">


                                    <div className="row text-left card-header " style={{ backgroundColor: "white" }}>
                                        <div className="col">
                                            <h4> Chronologie des Maintenances</h4>
                                        </div>
                                        <div className="col text-right">
                                            <label htmlFor="" className="ml-2">
                                                delete filter
                                            </label>
                                            <i className="la la-filter fs-22"></i>
                                        </div>
                                    </div>

                                    <div className="card-body">
                                        <div className="pb-8 pt-5 pl-5 pr-5">
                                            <div id="timelinecontent">


                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </>

    );
}
export default Home;