import React, { Component, useContext, useState, useEffect } from "react";
// import { MenuContext } from "react-flexible-sliding-menu";
import Dropdown from "react-bootstrap/Dropdown";

import logo from "../Assets/img/auto-logo.jpg";
// import { useTranslation } from "react-i18next";
// import Language from "./language";
import { ReactSession } from "react-client-session";
import { useCookies } from "react-cookie";
//import { useState } from 'react';
import toast, { Toaster } from "react-hot-toast";
import { useAsyncError, useNavigate } from "react-router-dom";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { MDBCheckbox } from "mdb-react-ui-kit";
import baseurl from "./globals";
import { FcMenu, FcHome, FcSupport, FcMultipleDevices, FcPrint, FcPortraitMode } from 'react-icons/fc';
import { ImLocation } from 'react-icons/im';
import { FaUserAlt } from 'react-icons/fa';
import { BiLogOutCircle, BiRefresh } from 'react-icons/bi';
import moment from "moment";


var bcrypt = require("bcryptjs");
var salt = bcrypt.genSaltSync(10);


var bcrypt = require("bcryptjs");

function Header_mobile() {
  const [displayMenu, setDisplayMenu] = useState("d-none");
  const [displayRightMenu, setDisplayRightMenu] = useState("d-none");
  // const { t } = useTranslation();

  const displayMenuHandle = () => {
    if (displayMenu == "d-none") {
      setDisplayMenu("d-flex");
    } else {
      setDisplayMenu("d-none");
    }
  };
  const [modalViewNotification, setModalViewNotification] = useState(false);
  const navigate = useNavigate();
  const [cookies] = useCookies();
  const [oldpassword, setOldpassword] = useState();
  const [newpassword, setNewpassword] = useState();
  const [confirmpassword, setConfirmpassword] = useState();
  const [showChangePassword, setShowChangePassword] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const [Messageoldpassword, setMessageOldpassword] = useState();
  const [Messagenewpassword, setMessageNewpassword] = useState();
  const [vuepassword, setVuepassword] = useState(false);
  const [Messageconfirmpassword, setMessageConfirmpassword] = useState();

  const [listNotification, setListNotification] = useState([]);
  const [listNotification1, setListNotification1] = useState([]);

  const [perm_view_equipement, setPerm_view_equipement] = useState(-1);
  const [perm_edit_equipement, setPerm_edit_equipement] = useState(-1);
  const [perm_add_equipement, setPerm_add_equipement] = useState(-1);
  const [perm_view_materials, setPerm_view_materials] = useState(-1);
  const [perm_edit_materials, setPerm_edit_materials] = useState(-1);
  const [perm_add_materials, setPerm_add_materials] = useState(-1);
  const [perm_view_affectation_site, setPerm_view_affectation_site] = useState(-1);
  const [perm_edit_affectation_site, setPerm_edit_affectation_site] = useState(-1);
  const [perm_add_affectation_site, setPerm_add_affectation_site] = useState(-1);
  const [perm_create_intervention, setPerm_create_intervention] = useState(-1);
  const [perm_edit_intervention, setPerm_edit_intervention] = useState(-1);
  const [perm_view_intervention, setPerm_view_intervention] = useState(-1);
  const [perm_assign_intervention, setPerm_assign_intervention] = useState(-1);
  const [perm_close_intervention, setPerm_close_intervention] = useState(-1);
  const [perm_edit_assign, setPerm_edit_assign] = useState(-1);
  const [perm_add_signature, setPerm_add_signature] = useState(-1);
  const [perm_cancel_intervention, setPerm_cancel_intervention] = useState(-1);
  const [perm_Report, setPerm_Report] = useState(-1);
  const [perm_List_User, setPerm_List_User] = useState(-1);
  const [perm_add_user, setPerm_add_user] = useState(-1);
  const [perm_edit_user, setPerm_edit_user] = useState(-1);
  const [perm_status_user, setPerm_status_user] = useState(-1);
  const [perm_edit_equipment_user, setPerm_edit_equipment_user] = useState(-1);
  const [perm_history_equipement, setPerm_history_equipement] = useState(-1);
  const [perm_status_equipement, setPerm_status_equipement] = useState(-1);
  const [perm_add_user_equipement, setPerm_add_user_equipement] = useState(-1);

  const handleLogout = () => {
    ReactSession.remove("prev_id");
    ReactSession.remove("prev_username");
    ReactSession.remove("prev_mail");
    navigate("/");
  };

  const check_verif = () => {
    if (vuepassword === false) {
      setVuepassword(true);
    } else {
      setVuepassword(false);
    }
  };

  useEffect(() => {
    checkNotif();
    handleOnLoad();

  }, []);

  const checkNotif = () => {
    axios.post(baseurl + "?p=checkNotif", {
      user_id: ReactSession.get("prev_id")
    }).then((response) => {
      if (response.data.status) {
        setListNotification(response.data.list);
      }
    })
  }


  const handleOnLoad = () => {
    axios.post(baseurl + "?p=getUserPermissions", {
      userId: ReactSession.get('prev_id')
    }).then((response) => {
      (response.data.list).map((e, index) => {
        switch (e['usrper_per_id']) {
          case 214:
            setPerm_view_equipement(e['usrper_val']);
            break;

          case 215:
            setPerm_edit_equipement(e['usrper_val']);
            break;

          case 216:
            setPerm_add_equipement(e['usrper_val']);
            break;

          case 217:
            setPerm_view_materials(e['usrper_val']);
            break;

          case 218:
            setPerm_edit_materials(e['usrper_val']);
            break;

          case 219:
            setPerm_add_materials(e['usrper_val']);
            break;

          case 223:
            setPerm_view_affectation_site(e['usrper_val']);
            break;

          case 224:
            setPerm_edit_affectation_site(e['usrper_val']);
            break;

          case 225:
            setPerm_add_affectation_site(e['usrper_val']);
            break;

          case 300:
            setPerm_create_intervention(e['usrper_val']);
            break;

          case 301:
            setPerm_edit_intervention(e['usrper_val']);
            break;

          case 302:
            setPerm_view_intervention(e['usrper_val']);
            break;

          case 303:
            setPerm_assign_intervention(e['usrper_val']);
            break;

          case 304:
            setPerm_close_intervention(e['usrper_val']);
            break;

          case 305:
            setPerm_edit_assign(e['usrper_val']);
            break;

          case 306:
            setPerm_add_signature(e['usrper_val']);
            break;

          case 307:
            setPerm_cancel_intervention(e['usrper_val']);
            break;

          case 308:
            setPerm_Report(e['usrper_val']);
            break;

          case 400:
            setPerm_List_User(e['usrper_val']);
            break;

          case 401:
            setPerm_add_user(e['usrper_val']);
            break;

          case 402:
            setPerm_edit_user(e['usrper_val']);
            break;

          case 403:
            setPerm_status_user(e['usrper_val']);
            break;

          case 404:
            setPerm_edit_equipment_user(e['usrper_val']);
            break;
          case 240:
            setPerm_history_equipement(e['usrper_val']);
            break;
          case 241:
            setPerm_status_equipement(e['usrper_val']);
            break;
          case 242:
            setPerm_add_user_equipement(e['usrper_val']);
            break;

          default:
            break;
        }
      })
    })
  }

  const change_password = () => {
    let verif = 0;
    if (oldpassword === "") {
      verif = 1;
      setMessageOldpassword("Old password is empty");
    }
    if (newpassword === "") {
      setMessageNewpassword("New password is empty");
      verif = 1;
    }
    if (confirmpassword === "") {
      verif = 1;
      setMessageConfirmpassword("You must confirm the new password");
    }

    if (verif === 0) {
      if (newpassword === confirmpassword) {
        var hash = bcrypt.hashSync(newpassword, salt);
        axios.post(baseurl + "?p=resetPassword", {
          username: ReactSession.get("prev_username"),
          password: hash,
          id: ReactSession.get("prev_id"),
        }).then((response) => {
          if (response.data.status === true) {
            notifySuccess("Password has been changed");
            setShowChangePassword(false);
          }
        })
      } else {
        setMessageConfirmpassword(
          "The first password and the second password do not match"
        );
      }
    }
  };

  const notifySuccess = (msg) =>
    toast.success(msg, {
      style: {
        padding: "16px",
        backgroundColor: "rgba(124, 140, 135, 0.70)",
        color: "#FFF",
        maxWidth: "280px",
        textAlign: "left",
        fontSize: "13px",
      },
    });

  const notifyError = (msg) =>
    toast.error(msg, {
      style: {
        padding: "16px",
        backgroundColor: "rgba(124, 140, 135, 0.70)",
        color: "#FFF",
        maxWidth: "280px",
        textAlign: "left",
        fontSize: "13px",
      },
    });

  const handleModelClosechangePassword = () => {
    setShowChangePassword(false);
    setOldpassword("");
    setNewpassword("");
    setConfirmpassword("");
    setMessageOldpassword("");
    setMessageNewpassword("");
    setMessageConfirmpassword("");
  };

  const handleViewNotification = () => {
    axios.post(baseurl + "?p=viewNotification", {
      not_id: listNotification1[0]['not_id']
    }).then((response) => {
      if (response.data.status) {
        checkNotif()
      }
    })
    setListNotification1([]);
    setModalViewNotification(false)
  }

  const prepareNotif = (id) => {

    const filtered = listNotification.filter(filtre => {
      if (filtre.not_id === id) {
        return filtre;
      }
    });
    setListNotification1(filtered);
  }
  return (
    <>
      <Modal
        show={showChangePassword}
        onHide={handleModelClosechangePassword}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Change password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="form-group col-xl-12 ">
              <label className="font-weight-bold">
                Old password<span className="text-danger pl-3">*</span>
              </label>
              <input
                id="old-password"
                type={vuepassword ? "text" : "password"}
                onChange={(e) => {
                  setOldpassword(e.target.value);
                }}
                name="subject"
                className="form-control rounded-0"
              />
              <div id="cstm-code-error" className="text-danger pt-2">
                {Messageoldpassword}
              </div>
            </div>

            <div className="form-group col-xl-12 ">
              <label className="font-weight-bold">
                New password<span className="text-danger pl-3">*</span>
              </label>
              <input
                id="new-password"
                type={vuepassword ? "text" : "password"}
                onChange={(e) => {
                  setNewpassword(e.target.value);
                }}
                name="subject"
                className="form-control rounded-0"
              />
              <div id="cstm-code-error" className="text-danger pt-2">
                {Messagenewpassword}
              </div>
            </div>

            <div className="form-group col-xl-12 ">
              <label className="font-weight-bold">
                Confirm password<span className="text-danger pl-3">*</span>
              </label>
              <input
                id="old-password"
                type={vuepassword ? "text" : "password"}
                onChange={(e) => {
                  setConfirmpassword(e.target.value);
                }}
                name="subject"
                className="form-control rounded-0"
              />
              <div id="cstm-code-error" className="text-danger pt-2">
                {Messageconfirmpassword}
              </div>
            </div>
            <MDBCheckbox
              className="ml-30"
              name="flexCheck"
              onChange={check_verif}
              value={vuepassword}
              id="shwpssw"
              label="Show password"
            />
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleModelClosechangePassword}>
            Cancel
          </Button>
          <Button variant="primary" onClick={change_password}>
            Change password
          </Button>
        </Modal.Footer>
      </Modal>


      <Modal show={modalViewNotification} onHide={handleViewNotification} centered >
        <Modal.Header closeButton>
          <Modal.Title>NOTIFICATION</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {listNotification1.length > 0 ?
            <div className="modal-body pb-2">
              <div className="form-group mb-3">
                <div className="row">
                  <div className="col-6">
                    <h2 className="fs-14 font-weight-bolder">Title</h2>
                    <p id="not-title" className="fs-13">{listNotification1[0]['not_title']}</p>
                  </div>
                  <div className="col-6">
                    <div>
                      <span className="fs-14 font-weight-bolder mt-3">Date: </span>
                      <span id="not-date">{moment(listNotification1[0]['not_created_date']).format('YYYY-MM-DD HH:mm')}</span>
                    </div>
                    <div>
                      <span className="fs-14 font-weight-bolder pb-0">Sender: </span>
                      <span id="not-sender">{listNotification1[0]['senderName']}</span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="form-group pt-0">
                <h2 className="fs-14 font-weight-bolder">Description</h2>
                <p id="not-description">{listNotification1[0]['not_description']} </p>
              </div>


            </div> :
            <></>}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleViewNotification}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
      <div
        id="kt_header_mobile"
        className="header-mobile bg-white d-print-none"
      >
        <div className="d-flex">
          {/* <Language /> */}
          <div className="w-full dropdown mr-0 pt-2">
            <div className="menu-link">
              <Dropdown>
                <Dropdown.Toggle
                  variant="dropdown-basic"
                  className="w-100 h-40px text-white p-0 m-0 pt-0 pb-0"
                >
                  <div
                    className="btn btn-icon btn-hover-transparent-white btn-dropdown btn-lg mr-0"
                    id="open-notification"
                  >
                    <i className="fas fa-bars text-dark-75 mr-2"></i>
                  </div>
                </Dropdown.Toggle>

                <Dropdown.Menu className="w-full">
                  <Dropdown.Item onClick={() => navigate("/Home")}>
                    <div className="d-flex pt-0 pb-2 px-8 bgi-size-cover bgi-no-repeat rounded-top border-bottom">
                      <div className="text-dark-75 m-0 flex-grow-1 fs-15 font-weight-bolder w-200px">
                        <FcHome size={20} className="mr-5" /> Acceuil
                      </div>
                    </div>
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => navigate("/Pintervention")}>
                    <div className="d-flex pt-0 pb-2 px-8 bgi-size-cover bgi-no-repeat rounded-top border-bottom">
                      <div className="text-dark-75 m-0 flex-grow-1 fs-15 font-weight-bolder w-200px">
                        <FcSupport size={20} className="mr-5" />Preventive intervention
                      </div>
                    </div>
                  </Dropdown.Item>
                  {perm_view_equipement === 1 ?
                    <Dropdown.Item onClick={() => navigate("/Equipements")}>
                      <div className="d-flex pt-0 pb-2 px-8 bgi-size-cover bgi-no-repeat rounded-top border-bottom">
                        <div className="text-dark-75 m-0 flex-grow-1 fs-15 font-weight-bolder w-200px">
                          <FcMultipleDevices size={20} className="mr-5" />Equipements
                        </div>
                      </div>
                    </Dropdown.Item> : <></>}

                  {perm_view_materials === 1 ?
                    <Dropdown.Item onClick={() => navigate("/Materiels")}>
                      <div className="d-flex pt-0 pb-2 px-8 bgi-size-cover bgi-no-repeat rounded-top border-bottom">
                        <div className="text-dark-75 m-0 flex-grow-1 fs-15 font-weight-bolder w-200px">
                          <FcPrint size={20} className="mr-5" />Materiels
                        </div>
                      </div>
                    </Dropdown.Item> : <></>}

                  {perm_view_affectation_site === 1 ?

                    <Dropdown.Item onClick={() => navigate("/AffectationSite")}>
                      <div className="d-flex pt-0 pb-2 px-8 bgi-size-cover bgi-no-repeat rounded-top border-bottom">
                        <div className="text-dark-75 m-0 flex-grow-1 fs-15 font-weight-bolder w-200px">
                          <ImLocation size={20} className="mr-5 text-danger" />Sites d'affectation
                        </div>
                      </div>
                    </Dropdown.Item> : <></>}
                  {perm_List_User === 1 ?

                    <Dropdown.Item onClick={() => navigate("/Users")}>
                      <div className="d-flex pt-0 pb-2 px-8 bgi-size-cover bgi-no-repeat rounded-top border-bottom">
                        <div className="text-dark-75 m-0 flex-grow-1 fs-15 font-weight-bolder w-200px">
                          <FaUserAlt size={20} className="mr-5 text-success" />Users
                        </div>
                      </div>
                    </Dropdown.Item> : <></>}
                  <Dropdown.Item onClick={() => handleLogout()}>
                    <div className="d-flex pt-0 pb-2 px-8 bgi-size-cover bgi-no-repeat rounded-top border-bottom">
                      <div className="text-dark-75 m-0 flex-grow-1 fs-15 font-weight-bolder w-200px">
                        <BiLogOutCircle size={25} className="mr-4 text-danger" />Log out
                      </div>
                    </div>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </div>
        <a href="/home">
          <img alt="Logo" src={logo} className="logo-default max-h-30px" />
        </a>

        <div className="d-flex">
          {/* <Language /> */}
          <div className="w-full dropdown mr-0 pt-2">
            <div className="menu-link">
              <Dropdown>
                <Dropdown.Toggle
                  variant="dropdown-basic"
                  className="w- h-40px text-white p-0 m-0 pt-0 pb-0"
                >
                  <div
                    className="btn btn-icon btn-hover-transparent-white btn-dropdown btn-lg mr-0"
                    id="open-notification"
                  >
                    <i className="far fa-bell text-dark-75 mr-2"></i>
                    <span className="label label-rounded label-danger">
                      <div id="unreadnot">{listNotification.length}</div>
                    </span>
                  </div>
                </Dropdown.Toggle>

                <Dropdown.Menu className="">
                  <Dropdown.Header className="pb-0 font-weight-bold text-dark d-flex">
                    <p className="fs-20">Notifications</p> <span><BiRefresh size={25} className='cursor-pointer text-danger' onClick={checkNotif} /></span>

                  </Dropdown.Header>
                  {listNotification.length === 0 ?
                    <Dropdown.Item>
                      <form>

                        <div className="scroll scroll-push ps" data-scroll="true" data-height="250" data-mobile-height="200" style={{ height: '250px', overflow: 'hidden' }}>

                          <div id="notificationcontent">
                            <div className="border-top">
                              <div className="font-weight-bold text-dark-75 font-size-lg text-hover-primary">
                                {listNotification.length} notification
                              </div>
                              <div className="text-muted mt-1">
                                Vous n'avez aucune notification non lue
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </Dropdown.Item>
                    :
                    <div style={{
                      width: '270px',
                      height: '200px',
                      overflow: 'auto',
                    }}>
                      {listNotification.map((e, index) => {
                        return (
                          <Dropdown.Item key={index} onClick={() => { prepareNotif(e.not_id); setModalViewNotification(true); }}>

                            <div className="scroll scroll-push ps" style={{ height: '100px', overflow: 'hidden' }}>

                              <div className="py-2 px-2 border-top">
                                <div className="">
                                  <p className="font-weight-bold text-dark-75 font-size-lg text-hover-primary" style={{ display: 'inline' }}>{e.not_title}</p>
                                  <p className="fs-10">{e.not_description}</p>
                                </div>
                                <div className="">
                                  <p>De : {e.senderName}</p>
                                  <p>Date : {moment(e.not_created_date).format('YYYY-MM-DD HH:mm')}</p>
                                </div>
                              </div>
                            </div>
                          </Dropdown.Item>
                        );

                      })}
                    </div>


                  }

                  <Dropdown.Item onClick={() => { navigate('/Notifications') }}>
                    <div className="pt-3 pb-3 pl-7 pr-7 border-top">
                      <div className="d-flex align-items-center justify-content-center">
                        <p className="fs-13 text-dark-75 text-hover-primary align-items-center">Voir toutes les notifications</p>
                      </div>
                    </div>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </div>
      </div>
      {/* Left Side */}
      <div
        id="header_mobile_menu"
        align="left"
        className={
          "bg-white flex-column float-left pl-8 d-lg-none d-none" /*+ displayMenu*/
        }
      >
        <div
          id="kt_header_menu"
          className="header-menu header-menu-left header-menu-mobile  header-menu-layout-default "
        >
          <ul className="menu-nav">
            <li className="menu-item menu-item-rel">
              <a href="/home" className="menu-link">
                <span className="text-dark-75 font-weight-bolder">
                  Menu principale
                </span>
                {/* <i className="menu-arrow pb-2"></i> */}
              </a>
            </li>
            <li className="menu-item menu-item-rel">
              <a href="/home" className="menu-link">
                <span className="text-dark-75 font-weight-bolder">
                  Acceuil
                </span>
                {/* <i className="menu-arrow pb-2"></i> */}
              </a>
            </li>
            <li className="menu-item menu-item-rel">
              <a href="/request" className="menu-link">
                <span className="text-dark-75 font-weight-bolder">
                  Preventive intervention
                </span>
                {/* <i className="menu-arrow pb-2"></i>  */}
              </a>
            </li>
            <li className="menu-item menu-item-rel">
              <a href="/Equipements" className="menu-link">
                <span className="text-dark-75 font-weight-bolder">
                  Equipements
                </span>
                {/* <i className="menu-arrow pb-2"></i>  */}
              </a>
            </li>
            <li className="menu-item menu-item-rel">
              <a href="/Materiels" className="menu-link">
                <span className="text-dark-75 font-weight-bolder">
                  Materiels
                </span>
                {/* <i className="menu-arrow pb-2"></i>  */}
              </a>
            </li>
            <li className="menu-item menu-item-rel">
              <a href="/AffectationSite" className="menu-link">
                <span className="text-dark-75 font-weight-bolder">
                  Sites d'affectation
                </span>
                {/* <i className="menu-arrow pb-2"></i>  */}
              </a>
            </li>
          </ul>
        </div>
        <div className="menu-item menu-item-rel pt-3 ml-5">
          <a
            className="menu-link"
            onClick={() => {
              handleLogout();
            }}
          >
            <span className="text-dark-75 font-weight-bolder pl-5">
              Logout
            </span>
            <i className="menu-arrow pb-2"></i>
          </a>
        </div>
      </div>

      {/* Right Side */}
    </>
  );
}

export default Header_mobile;
