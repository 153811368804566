import axios from "axios";
import { useEffect, useState, useRef } from "react";
import { RiMailOpenLine } from "react-icons/ri";
import baseurl from "./globals";
import Header from "./header";
import Header_mobile from "./header_mobile";
import { ReactSession } from "react-client-session";
import Accordion from 'react-bootstrap/Accordion';
import moment from 'moment';
import { HiMail, HiMailOpen } from "react-icons/hi";
import { BiRefresh } from "react-icons/bi";
import { GrFormClose } from "react-icons/gr";
import Dropdown from "react-bootstrap/Dropdown";
import toast, { Toaster } from "react-hot-toast";
import Modal from "react-bootstrap/Modal";




function Logs() {
    const [windowSize, setWindowSize] = useState(getWindowSize());
    const [listLogs, setListLogs] = useState([]);
    const [listLogs1, setListLogs1] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [linePerPage, setlinePerPage] = useState(15);
    const indexOfLastPost = currentPage * linePerPage;
    const indexOfFirstPost = indexOfLastPost - linePerPage;
    const paginate = (pageNumber) => setCurrentPage(pageNumber);
    const [spinView, setSpinView] = useState(false);

    const [spinView1, setSpinView1] = useState(false);


    const currentLogs = listLogs.slice(indexOfFirstPost, indexOfLastPost);


    function getWindowSize() {
        const { innerWidth, innerHeight } = window;
        return { innerWidth, innerHeight };
    }
    useEffect(() => {
        getAllLogs();
        function handleWindowResize() {
            setWindowSize(getWindowSize());
        }

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    const notifySuccess = (msg) =>
        toast.success(msg, {
            style: {
                padding: "16px",
                backgroundColor: "rgba(124, 140, 135, 0.70)",
                color: "#FFF",
                maxWidth: "280px",
                textAlign: "left",
                fontSize: "13px",
            },
        });

    const notifyError = (msg) =>
        toast.error(msg, {
            style: {
                padding: "16px",
                backgroundColor: "rgba(124, 140, 135, 0.70)",
                color: "#FFF",
                maxWidth: "280px",
                textAlign: "left",
                fontSize: "13px",
            },
        });

    const getAllLogs = () => {
        axios.post(baseurl + "?p=getLogs", {
        }).then((response) => {
            setListLogs(response.data.list);
            setListLogs1(response.data.list);
            setSpinView(false)
            setSpinView1(false)
        }).catch(function (error) {
            console.log("INTERNAL ERROR (getAllLogs)", error.response.data, moment().format('DD-MM HH:mm'));
        });
    }

    const Pagination = ({ postsPerPage, totalPosts, paginate }) => {
        var pageNumbers = [];
        var pageslink = 6;
        var startlink = 1;

        var totalPages = Math.ceil(totalPosts / postsPerPage);

        if (currentPage - 2 > 1 && currentPage + 2 <= totalPages) {
            startlink = currentPage - 2;
            pageNumbers = [];
            for (let i = startlink; i <= currentPage + 2; i++) {
                pageNumbers.push(i);
            }
        } else if (currentPage - 2 > 1 && currentPage + 1 <= totalPages) {
            startlink = currentPage - 3;
            pageNumbers = [];
            for (let i = startlink; i <= currentPage + 1; i++) {
                pageNumbers.push(i);
            }
        } else if (currentPage == totalPages) {
            if (currentPage - 5 >= 0) startlink = currentPage - 4;
            else if (currentPage - 4 >= 0) startlink = currentPage - 3;
            else if (currentPage - 3 >= 0) startlink = currentPage - 2;
            else if (currentPage - 2 >= 0) startlink = currentPage - 1;
            pageNumbers = [];
            for (let i = startlink; i <= totalPages; i++) {
                pageNumbers.push(i);
            }
        } else {
            startlink = 1;
            pageNumbers = [];
            if (pageslink > totalPages) pageslink = totalPages;
            for (let i = startlink; i <= pageslink; i++) {
                pageNumbers.push(i);
            }
        }

        return (
            <>
                <nav className="d-flex">
                    <ul className="pagination">
                        <li className="page-item">
                            {
                                <a onClick={() => paginate(1)} href="#" className="">
                                    {"«"}
                                </a>
                            }
                        </li>

                        <li className="page-item">
                            {currentPage == 1 ? (
                                <a href="#" className="" disabled>
                                    {"<"}
                                </a>
                            ) : (
                                <a
                                    onClick={() => paginate(currentPage - 1)}
                                    href="#"
                                    className=""
                                >
                                    {"<"}
                                </a>
                            )}
                        </li>

                        {pageNumbers.map((number) => (
                            <li key={number} className="page-item">
                                {currentPage == number ? (
                                    <a
                                        onClick={() => paginate(number)}
                                        href="#"
                                        className="bg-red"
                                    >
                                        {number}
                                    </a>
                                ) : (
                                    <a onClick={() => paginate(number)} href="#" className="">
                                        {number}
                                    </a>
                                )}
                            </li>
                        ))}

                        <li className="page-item">
                            {currentPage == totalPages ? (
                                <a href="#" className="" disabled>
                                    {">"}
                                </a>
                            ) : (
                                <a
                                    onClick={() => paginate(currentPage + 1)}
                                    href="#"
                                    className=""
                                >
                                    {">"}
                                </a>
                            )}
                        </li>

                        <li className="page-item">
                            {
                                <a onClick={() => paginate(totalPages)} href="#" className="">
                                    {"»"}
                                </a>
                            }
                        </li>
                    </ul>
                </nav>
            </>
        );
    };
    const [listActualFilter, setListActualFilter] = useState([]);

    const [filterDate, setFilterDate] = useState('');
    const [filterTitle, setFilterTitle] = useState('');
    const [filterAuthor, setFilterAuthor] = useState('');
    const [modalFilterLog, setModalFilterLog] = useState(false);
    const [filterType, setFilterType] = useState("");
    const [filterText, setFilterText] = useState("");


    const removeFilter = (type) => {
        const actualFilt = listActualFilter.filter(filtre => {
            return filtre.type.toLowerCase().indexOf(type.toLowerCase()) < 0;
        })

        setListActualFilter([]);

        if (type === 'date') {
            setFilterDate('');


            if (filterAuthor !== '') {
                const filtered = listLogs1.filter(filtre => {
                    return filtre.user_name.toLowerCase().indexOf(filterAuthor.toLowerCase()) >= 0;
                })
                if (filterTitle !== "") {
                    setListLogs(
                        filtered.filter(filtre => {
                            return filtre.pilogs_title.toLowerCase().indexOf(filterTitle.toLowerCase()) >= 0;
                        })
                    )
                } else {
                    setListLogs(filtered);
                }
            } else {
                if (filterTitle !== '') {
                    setListLogs(
                        listLogs1.filter(filtre => {
                            return filtre.pilogs_title.toLowerCase().indexOf(filterTitle.toLowerCase()) >= 0;
                        })
                    )

                } else {
                    setListLogs(listLogs1)
                }
            }

        } else if (type === 'text') {
            setFilterTitle('');
            if (filterDate !== '') {
                const filtered = listLogs1.filter(filtre => {
                    return filtre.pilogs_date.toLowerCase().indexOf(filterDate.toLowerCase()) >= 0;
                })

                if (filterAuthor !== '') {
                    setListLogs(
                        filtered.filter(filtre => {
                            return filtre.user_name.toLowerCase().indexOf(filterAuthor.toLowerCase()) >= 0;
                        })
                    )
                } else {
                    setListLogs(filtered);
                }
            } else {
                if (filterAuthor !== '') {
                    setListLogs(
                        setListLogs.filter(filtre => {
                            return filtre.user_name.toLowerCase().indexOf(filterAuthor.toLowerCase()) >= 0;
                        })
                    )
                } else {
                    setListLogs(listLogs1)
                }
            }
        } else {
            setFilterAuthor('');

            if (filterDate !== '') {
                const filtered = listLogs1.filter(filtre => {
                    return filtre.pilogs_date.toLowerCase().indexOf(filterDate.toLowerCase()) >= 0;
                })

                if (filterTitle !== '') {
                    setListLogs(
                        filtered.filter(filtre => {
                            return filtre.pilogs_title.toLowerCase().indexOf(filterTitle.toLowerCase()) >= 0;
                        })
                    )
                } else {
                    setListLogs(filtered);
                }
            } else {
                if (filterTitle !== '') {
                    setListLogs(
                        listLogs1.filter(filtre => {
                            return filtre.pilogs_title.toLowerCase().indexOf(filterTitle.toLowerCase()) >= 0;
                        })
                    )
                } else {
                    setListLogs(listLogs1)
                }
            }
        }
        setListActualFilter(actualFilt)
    }

    const filtering = () => {
        if (filterType > 3) {
            if (filterType === 4) {
                filterListLog('date');
            } else if (filterType === 5) {
                filterListLog('text');
            } else {
                filterListLog('author');
            }
        }

    };

    const filterListLog = (type) => {

        if (type === 'date') {
            let found = false;

            setListActualFilter(listActualFilter.map((e) => {
                if (e.type === type) {
                    found = true;
                    return { ...e, val: filterDate }
                }
                return { ...e }
            }))

            if (!found) {
                setListActualFilter(current => [...current, { type: 'date', val: filterDate }])
            }

            setListLogs(listLogs1.filter(filtre => {
                return filtre.pilogs_date.toLowerCase().indexOf(filterDate.toLowerCase()) >= 0;
            }))

            const filtered = listLogs1.filter(filtre => {
                return filtre.pilogs_date.toLowerCase().indexOf(filterDate.toLowerCase()) >= 0;
            })

            if (filterTitle !== "") {

                if (filterAuthor !== "") {
                    const filtered1 = filtered.filter(filtre => {
                        return filtre.user_name.toLowerCase().indexOf(filterAuthor.toLowerCase()) >= 0;
                    })

                    setListLogs(
                        filtered1.filter(filtre => {
                            return filtre.pilogs_title.toLowerCase().indexOf(filterTitle.toLowerCase()) >= 0;
                        })
                    )
                } else {
                    setListLogs(
                        filtered.filter(filtre => {
                            return filtre.pilogs_title.toLowerCase().indexOf(filterTitle.toLowerCase()) >= 0;
                        })
                    )
                }

            } else {
                if (filterAuthor !== "") {
                    setListLogs(
                        filtered.filter(filtre => {
                            return filtre.user_name.toLowerCase().indexOf(filterAuthor.toLowerCase()) >= 0;
                        })
                    )
                }
            }

            // if (filterAuthor !== "") {
            //     const filtered1 = listLogs1.filter(filtre => {
            //         return filtre.user_name.toLowerCase().indexOf(filterAuthor.toLowerCase()) >= 0;
            //     })

            //     setListLogs(
            //         filtered1.filter(filtre => {
            //             return filtre.pilogs_title.toLowerCase().indexOf(filterTitle.toLowerCase()) >= 0;
            //         })
            //     )
            // } else {
            //     if (filterTitle !== "") {
            //         setListLogs(
            //             filtered.filter(filtre => {
            //                 return filtre.pilogs_title.toLowerCase().indexOf(filterTitle.toLowerCase()) >= 0;
            //             })
            //         )
            //     }
            // }

            setModalFilterLog(false);

        } else if (type === 'text') {
            console.log(listActualFilter);
            let found = false;
            setListActualFilter(listActualFilter.map((e) => {
                if (e.type === type) {
                    found = true;
                    return { ...e, val: filterTitle }
                }
                return { ...e }
            }))

            if (!found) {
                setListActualFilter(current => [...current, { type: 'text', val: filterTitle }])
            }

            setListLogs(
                listLogs1.filter(filtre => {
                    return filtre.pilogs_title.toLowerCase().indexOf(filterTitle.toLowerCase()) >= 0;
                })
            )

            if (filterDate !== "") {
                const filtered = listLogs1.filter(filtre => {
                    return filtre.pilogs_date.toLowerCase().indexOf(filterDate.toLowerCase()) >= 0;
                })

                if (filterAuthor !== "") {
                    const filtered1 = filtered.filter(filtre => {
                        return filtre.user_name.toLowerCase().indexOf(filterAuthor.toLowerCase()) >= 0;
                    })

                    setListLogs(
                        filtered1.filter(filtre => {
                            return filtre.pilogs_title.toLowerCase().indexOf(filterTitle.toLowerCase()) >= 0;
                        })
                    )
                } else {
                    setListLogs(
                        filtered.filter(filtre => {
                            return filtre.pilogs_title.toLowerCase().indexOf(filterTitle.toLowerCase()) >= 0;
                        })
                    )
                }

            } else {
                if (filterAuthor !== "") {
                    const filtered = listLogs1.filter(filtre => {
                        return filtre.user_name.toLowerCase().indexOf(filterAuthor.toLowerCase()) >= 0;
                    })
                    setListLogs(
                        filtered.filter(filtre => {
                            return filtre.pilogs_title.toLowerCase().indexOf(filterTitle.toLowerCase()) >= 0;
                        })
                    )
                }
            }
            setModalFilterLog(false);
        } else {
            let found = false;
            setListActualFilter(listActualFilter.map((e) => {
                if (e.type === type) {
                    found = true;
                    return { ...e, val: filterAuthor }
                }
                return { ...e }
            }))

            if (!found) {
                setListActualFilter(current => [...current, { type: 'author', val: filterAuthor }])
            }

            setListLogs(
                listLogs1.filter(filtre => {
                    return filtre.user_name.toLowerCase().indexOf(filterAuthor.toLowerCase()) >= 0;
                })
            )

            if (filterDate !== "") {
                const filtered = listLogs1.filter(filtre => {
                    return filtre.pilogs_date.toLowerCase().indexOf(filterDate.toLowerCase()) >= 0;
                })
                const filtered1 = filtered.filter(filtre => {
                    return filtre.user_name.toLowerCase().indexOf(filterAuthor.toLowerCase()) >= 0;
                })

                if (filterTitle !== "") {
                    setListLogs(
                        filtered1.filter(filtre => {
                            return filtre.pilogs_title.toLowerCase().indexOf(filterTitle.toLowerCase()) >= 0;
                        })
                    )
                } else {
                    setListLogs(
                        filtered1
                    )
                }
            } else {
                if (filterTitle !== "") {
                    const filtered = listLogs1.filter(filtre => {
                        return filtre.user_name.toLowerCase().indexOf(filterAuthor.toLowerCase()) >= 0;
                    })

                    setListLogs(
                        filtered.filter(filtre => {
                            return filtre.pilogs_title.toLowerCase().indexOf(filterTitle.toLowerCase()) >= 0;
                        })
                    )
                }
            }
            setModalFilterLog(false);
        }
    }

    return (
        <>
            <Header />
            <Header_mobile />
            <Modal show={modalFilterLog} onHide={() => { setModalFilterLog(false) }} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Filter</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form>
                        <div className="form-group mb-4 mt-3">
                            <label className="font-weight-bold">
                                {
                                    filterType === 4 ? (
                                        "Filter Log by Date"
                                    ) : filterType === 5 ? (
                                        "Filter Log by Title"
                                    ) : "Filter Log by Author"
                                }
                                <span className="text-danger pl-3">*</span>
                            </label>
                            {filterType === 4 ?
                                <input
                                    id="eq-subject"
                                    type='date'
                                    onChange={(e) => {
                                        setFilterDate(e.target.value);
                                    }}
                                    name="subject"
                                    className="form-control rounded-0"
                                />
                                :
                                <input
                                    id="eq-subject"
                                    onChange={(e) => {
                                        if (filterType === 5)
                                            setFilterTitle(e.target.value);
                                        else
                                            setFilterAuthor(e.target.value)
                                    }}
                                    name="subject"
                                    className="form-control rounded-0"
                                />}
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-secondary" onClick={() => setModalFilterLog(false)}>Close</button>
                    <button type="button" className="btn btn-success" onClick={filtering}>Add filter</button>
                </Modal.Footer>
            </Modal>

            <div
                className="d-flex flex-column flex-column-fluid "
                style={{ minHeight: "85vh", backgroundColor: "#eef0f8" }}

            >
                <div className="col-xl-12 center-block " align="center" style={{ backgroundColor: "#eef0f8" }} >
                    <div className="col-xl-6 bg-white mt-50 center-block min-h-650px" align="center" >
                        <div className="row text-left pt-3 pb-3 mt-10 border-bottom shadow" style={{ borderRadius: '10px' }}>
                            <div className=" col">
                                <label className="fs-20 ml-5"> Logs</label>
                                {spinView ?
                                    <span className="spinner-border spinner-border-sm ml-2 fs-20 text-success" role="status" aria-hidden="true"></span>
                                    :
                                    <i className="flaticon-refresh ml-2 cursor-pointer" onClick={() => { getAllLogs(); setSpinView(true); }}> </i>
                                }
                            </div>
                        </div>
                        <div className="row">
                            <div className="mt-1 text-left col-lg-6 col-md-6 col-sm-6 col-6">
                                {listActualFilter.map((e) => {
                                    return (
                                        <>
                                            <div className="btn btn-success p-2 ml-2" >{e.val} <GrFormClose className="thumbnail1 ml-2" onClick={() => { removeFilter(e.type) }} /></div>

                                        </>

                                    )

                                })}
                            </div>

                            <div className="mt-1 text-right col-lg-6 col-md-6 col-sm-6 col-6">
                                <div className="btn-group">
                                    <button type="button" className="btn btn-info pt-0 pb-0" onClick={() => { setListLogs(listLogs1); setListActualFilter([]); setFilterDate(''); setFilterTitle('') }}>Remove filter</button>
                                    <Dropdown>
                                        <Dropdown.Toggle
                                            variant="dropdown-basic"
                                            className=" pt-0 pb-0 "
                                        >
                                            <button type="button" className="btn btn-danger ml-5">Add filter</button>
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu className="w-50px ml-5">
                                            <Dropdown.Item onClick={() => { setModalFilterLog(true); setFilterType(4); }}>
                                                <div className="text-dark-75 m-0 flex-grow-1 mr-3 fs-12 font-weight-bolder w-200px">
                                                    By Date
                                                </div>
                                            </Dropdown.Item>
                                            <Dropdown.Item onClick={() => { setModalFilterLog(true); setFilterType(5); }}>
                                                <div className="text-dark-75 m-0 flex-grow-1 mr-3 fs-12 font-weight-bolder w-200px">
                                                    By Title
                                                </div>
                                            </Dropdown.Item>
                                            <Dropdown.Item onClick={() => { setModalFilterLog(true); setFilterType(6); }}>
                                                <div className="text-dark-75 m-0 flex-grow-1 mr-3 fs-12 font-weight-bolder w-200px">
                                                    By Author
                                                </div>
                                            </Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </div>
                        </div>

                        <div className="h-500px mt-3 overflow-auto">
                            {
                                currentLogs ? (
                                    currentLogs.map((d, index) => (
                                        <Accordion defaultActiveKey="0" className="pb-2" key={index}>
                                            <Accordion.Item eventKey={index} >
                                                <Accordion.Header>
                                                    <div className="">
                                                        <div className="d-flex ">
                                                            <label>{d.pilogs_title}</label>
                                                            <label className="ml-4 text-primary"> ({d.user_name})</label>
                                                        </div>
                                                        <div className="">
                                                            {moment(d.pilogs_date).format('YYYY-MM-DD HH:mm')}
                                                        </div>
                                                    </div>
                                                </Accordion.Header>
                                                <Accordion.Body className="text-left">
                                                    <div>
                                                        de : {d.user_name}
                                                    </div>
                                                    <div>
                                                        {d.pilogs_description}
                                                    </div>
                                                </Accordion.Body>
                                            </Accordion.Item>

                                        </Accordion>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan={11} className="text-center pt-20 fs-16">
                                            No data found
                                        </td>
                                    </tr>
                                )
                            }

                        </div>
                        <div className="row card-footer pt-2 pb-2 col-12 mb-0 bg-white ">
                            <div className="d-flex col-lg-5 col-md-5 col-sm-5 col-12">
                                <select className="page-range" onChange={(e) => { setlinePerPage(e.target.value); setCurrentPage(1) }}>
                                    <option value={10}>10</option>
                                    <option value={25}>25</option>
                                    <option value={50}>50</option>
                                    <option value={listLogs.length}>All</option>
                                </select>
                                <div className="mt-6"> </div>
                                <div className="mt-3"><div id="resultfound">{listLogs.length} résultat(s)</div> </div>
                            </div>
                            <div className="col-lg-3 col-md-3 col-sm-3 col-10 ">
                                <div className="">

                                    <Pagination
                                        postsPerPage={linePerPage}
                                        totalPosts={listLogs.length}
                                        paginate={paginate}
                                    />
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-2 col-sm-4col-xl-4">

                            </div>

                        </div>
                    </div>

                </div>

            </div >

        </>
    );
}


export default Logs;