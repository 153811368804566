import { Navigate, Outlet, useNavigate } from "react-router-dom";
import React, { Component } from "react";
import { ReactSession } from 'react-client-session';

import axios from "axios";

const useAuth = () => {

    const username = ReactSession.get("prev_username");
    var auth = false;

    if (username) {
        auth = true;
    }

    const user = { authenticated: auth };
    return user.authenticated;
}

const Authenticated = () => {
    const navigate = useNavigate();
    const isAuth = useAuth();

    return isAuth ? <Outlet /> : <Navigate to="/" />;
}

export default Authenticated;