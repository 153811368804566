import axios from "axios";
import React, { useEffect, useState } from "react";
import "../Assets/css/bootstrap.min.css";
import Header from "./header";
import Footer from "./footer";
import Dropdown from "react-bootstrap/Dropdown";
import { ReactSession } from "react-client-session";
import toast, { Toaster } from "react-hot-toast";
import Header_mobile from "./header_mobile";
import Modal from "react-bootstrap/Modal";
import baseurl from "./globals";
import moment from "moment";
import Select from 'react-select';
import { FcMinus, FcPlus, FcPrevious, FcNext, FcClearFilters } from 'react-icons/fc';
import { FaHistory, FaEdit } from 'react-icons/fa';
import { AiOutlinePlusCircle, AiOutlineSend } from 'react-icons/ai';
import { TbExchange } from 'react-icons/tb';
import { HiXCircle, HiCheckCircle } from "react-icons/hi";
import { useNavigate } from "react-router-dom";

function Equipements() {
    const [refresh, setRefresh] = useState(false);
    const navigate = useNavigate();

    const [listEquipement, setListEquipement] = useState([]);
    const [listEquipement2, setListEquipement2] = useState([]);

    const [listEquipType, setListEquipType] = useState([]);
    const [listEquip_office, setListEquipOffice] = useState([]);
    const [listUser, setListUser] = useState([]);
    const [listEquipmentChoosen, setListEquipmentChoosen] = useState([]);
    const [listHistory, setListHistory] = useState([]);
    const [listState, setListState] = useState([{ id: 1, text: 'Good' }, { id: 2, text: 'Partly Good' }, { id: 3, text: 'Partly Bad' }, { id: 4, text: 'Bad' }]);
    // const [listUserChoosed, setListUserChoosed] = useState([]);
    const [listSiteAffectation, setListSiteAffectation] = useState([]);
    const [listUserForEquipment, setListUserForEquipment] = useState([]);
    const [listNoUser, setListNoUser] = useState([]);
    const [listUserForUpdate, setListUserForUpdate] = useState([]);

    const [modalAddEquipment, setModalAddEquipment] = useState(false);
    const [modalView, setModalView] = useState(false);
    const [modalFilter, setModalFilter] = useState(false);
    const [modalHistory, setModalHistory] = useState(false);
    const [modalConfirmChangeStatus, setModalConfirmChangeStatus] = useState(false);
    const [filterText, setFilterText] = useState("");


    const [currentPage, setCurrentPage] = useState(1);
    const [linePerPage, setlinePerPage] = useState(10);
    const indexOfLastPost = currentPage * linePerPage;
    const indexOfFirstPost = indexOfLastPost - linePerPage;
    const currentEquipement = listEquipement.slice(indexOfFirstPost, indexOfLastPost)

    const [equipCodeHistory, setEquipCodeHistory] = useState('');
    const [filterType, setFilterType] = useState(0);
    const [equip_code, setEquip_code] = useState('');
    const [equip_design, setEquip_design] = useState('');
    const [equip_mark, setEquip_mark] = useState('');
    const [equip_serialNumber, setEquip_serialNumber] = useState('');
    const [equip_type, setEquip_type] = useState(0);
    const [equip_office, setEquip_office] = useState(0);
    const [equipStatus, setEquipStatus] = useState(1);
    const [equip_acquisitionDate, setEquip_acquisitionDate] = useState('');
    const [equip_siteAffect, setEquip_siteAffect] = useState(0);
    const [equipIsMulti, setEquipIsMulti] = useState(0);
    const [equip_activationKey, setEquip_activationKey] = useState(null);
    const [exist, setExist] = useState(false)
    const [editEq, setEditEq] = useState(false);
    const [equipState, setEquipState] = useState([]);

    const [isMulti, setIsMulti] = useState(0);
    const [isDamage, setIsDamage] = useState(0);
    const [isMultiAction, setIsMultiAction] = useState(false);
    const [isDamageAction, setIsDamageAction] = useState(false);

    const [userAddId, setUserAddId] = useState(0);
    const [userUpdtId, setUserUpdtAddId] = useState(0);


    const [messageCodeEquip, setMessageCodeEquip] = useState('');
    const [messageDesignEquip, setMessageDesignEquip] = useState('');
    const [messageMarkEquip, setMessageMarkEquip] = useState('');
    const [messageSerialNumberEquip, setMessageSerialNumberEquip] = useState('');
    const [messageTypeEquip, setMessageTypeEquip] = useState('');
    const [messageOfficeEquip, setMessageOfficeEquip] = useState('');
    const [messageUserEquip, setMessageUserEquip] = useState('');
    const [messageAcquisitionDateEquip, setMessageAcquisitionDateEquip] = useState('');
    const [messageSiteAffectationEquip, setMessageSiteAffectationEquip] = useState('');
    const [messageChangeMultiUser, setMessageChangeMultiUser] = useState('');


    const [perm_view_equipement, setPerm_view_equipement] = useState(-1);
    const [perm_edit_equipement, setPerm_edit_equipement] = useState(-1);
    const [perm_add_equipement, setPerm_add_equipement] = useState(-1);
    const [perm_view_materials, setPerm_view_materials] = useState(-1);
    const [perm_edit_materials, setPerm_edit_materials] = useState(-1);
    const [perm_add_materials, setPerm_add_materials] = useState(-1);
    const [perm_view_affectation_site, setPerm_view_affectation_site] = useState(-1);
    const [perm_edit_affectation_site, setPerm_edit_affectation_site] = useState(-1);
    const [perm_add_affectation_site, setPerm_add_affectation_site] = useState(-1);
    const [perm_create_intervention, setPerm_create_intervention] = useState(-1);
    const [perm_edit_intervention, setPerm_edit_intervention] = useState(-1);
    const [perm_view_intervention, setPerm_view_intervention] = useState(-1);
    const [perm_assign_intervention, setPerm_assign_intervention] = useState(-1);
    const [perm_close_intervention, setPerm_close_intervention] = useState(-1);
    const [perm_edit_assign, setPerm_edit_assign] = useState(-1);
    const [perm_add_signature, setPerm_add_signature] = useState(-1);
    const [perm_cancel_intervention, setPerm_cancel_intervention] = useState(-1);
    const [perm_Report, setPerm_Report] = useState(-1);
    const [perm_List_User, setPerm_List_User] = useState(-1);
    const [perm_add_user, setPerm_add_user] = useState(-1);
    const [perm_edit_user, setPerm_edit_user] = useState(-1);
    const [perm_status_user, setPerm_status_user] = useState(-1);
    const [perm_edit_equipment_user, setPerm_edit_equipment_user] = useState(-1);
    const [perm_history_equipement, setPerm_history_equipement] = useState(-1);
    const [perm_status_equipement, setPerm_status_equipement] = useState(-1);
    const [perm_add_user_equipement, setPerm_add_user_equipement] = useState(-1);


    const [spinView, setSpinView] = useState(false);
    const [spinView1, setSpinView1] = useState(false);
    const [spinView2, setSpinView2] = useState(false);
    const [spinView3, setSpinView3] = useState(false);


    const [windowSize, setWindowSize] = useState(getWindowSize());

    useEffect(() => {
        handleOnLoad();
        function handleWindowResize() {
            setWindowSize(getWindowSize());
        }

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    const handleOnLoad = () => {
        axios.post(baseurl + "?p=getUserPermissions", {
            userId: ReactSession.get('prev_id')
        }).then((response) => {
            (response.data.list).map((e, index) => {
                switch (e['usrper_per_id']) {
                    case 214:
                        setPerm_view_equipement(e['usrper_val']);
                        if (e['usrper_val'] === 0) {
                            navigate('/home')
                        }
                        break;

                    case 215:
                        setPerm_edit_equipement(e['usrper_val']);
                        break;

                    case 216:
                        setPerm_add_equipement(e['usrper_val']);
                        break;

                    case 217:
                        setPerm_view_materials(e['usrper_val']);
                        break;

                    case 218:
                        setPerm_edit_materials(e['usrper_val']);
                        break;

                    case 219:
                        setPerm_add_materials(e['usrper_val']);
                        break;

                    case 223:
                        setPerm_view_affectation_site(e['usrper_val']);
                        break;

                    case 224:
                        setPerm_edit_affectation_site(e['usrper_val']);
                        break;

                    case 225:
                        setPerm_add_affectation_site(e['usrper_val']);
                        break;

                    case 300:
                        setPerm_create_intervention(e['usrper_val']);
                        break;

                    case 301:
                        setPerm_edit_intervention(e['usrper_val']);
                        break;

                    case 302:
                        setPerm_view_intervention(e['usrper_val']);
                        break;

                    case 303:
                        setPerm_assign_intervention(e['usrper_val']);
                        break;

                    case 304:
                        setPerm_close_intervention(e['usrper_val']);
                        break;

                    case 305:
                        setPerm_edit_assign(e['usrper_val']);
                        break;

                    case 306:
                        setPerm_add_signature(e['usrper_val']);
                        break;

                    case 307:
                        setPerm_cancel_intervention(e['usrper_val']);
                        break;

                    case 308:
                        setPerm_Report(e['usrper_val']);
                        break;

                    case 400:
                        setPerm_List_User(e['usrper_val']);
                        break;

                    case 401:
                        setPerm_add_user(e['usrper_val']);
                        break;

                    case 402:
                        setPerm_edit_user(e['usrper_val']);
                        break;

                    case 403:
                        setPerm_status_user(e['usrper_val']);
                        break;

                    case 404:
                        setPerm_edit_equipment_user(e['usrper_val']);
                        break;
                    case 240:
                        setPerm_history_equipement(e['usrper_val']);
                        break;
                    case 241:
                        setPerm_status_equipement(e['usrper_val']);
                        break;
                    case 242:
                        setPerm_add_user_equipement(e['usrper_val']);
                        break;

                    default:
                        break;
                }
            })
        }).catch(function (error) {
            console.log("INTERNAL ERROR (handleOnLoad)", error.response.data, moment().format('DD-MM HH:mm'));
        });
    }
    function getWindowSize() {
        const { innerWidth, innerHeight } = window;
        return { innerWidth, innerHeight };
    }

    useEffect(() => {
        getAllEquipements();
        getListType();
        getListOffice();
        getListUser();
        getListAffectationSite();
    }, [refresh]);

    const notifySuccess = (msg) =>
        toast.success(msg, {
            style: {
                padding: "16px",
                backgroundColor: "rgba(124, 140, 135, 0.70)",
                color: "#FFF",
                maxWidth: "280px",
                textAlign: "left",
                fontSize: "13px",
            },
        });

    const notifyError = (msg) =>
        toast.error(msg, {
            style: {
                padding: "16px",
                backgroundColor: "rgba(124, 140, 135, 0.70)",
                color: "#FFF",
                maxWidth: "280px",
                textAlign: "left",
                fontSize: "13px",
            },
        });

    const handleTypeClose = () => {
        setModalAddEquipment(false);
        setModalView(false);
        setModalFilter(false);
        setModalHistory(false);
        setSpinView(false);
        setSpinView1(false);
        setIsMultiAction(false)
        setEditEq(false);
        getAllEquipements();

        setEquip_code('');
        setEquip_design('');
        setEquip_mark('');
        setEquip_serialNumber('');
        setEquip_type(0);
        setEquip_office(0);
        setListUserChoosed([]);
        setListUserForEquipment([]);
        setEquip_siteAffect(0);
        setEquip_acquisitionDate('');
        setUserUpdtAddId(0);
        setUserAddId(0);
        setEquipIsMulti(0);
        setIsMulti(0);
        setEquip_activationKey(null)
        setEquipCodeHistory("");

        setMessageCodeEquip("");
        setMessageDesignEquip("");
        setMessageMarkEquip("");
        setMessageSerialNumberEquip("");
        setMessageTypeEquip("");
        setMessageOfficeEquip("");
        setMessageUserEquip("");
        setMessageAcquisitionDateEquip("");
        setMessageSiteAffectationEquip("");
        setMessageChangeMultiUser("");
    }
    const getAllEquipements = () => {

        axios.post(baseurl + "?p=getAllEquipements", {
        }).then((response) => {
            if (response.data.status) {
                setListEquipement(response.data.list);
                setListEquipement2(response.data.list);
                setModalConfirmChangeStatus(false);
                setSpinView(false);
            }
        }).catch(function (error) {
            console.log("INTERNAL ERROR (getAllEquipements)", error.response.data, moment().format('DD-MM HH:mm'));
        });
    }

    const getListType = () => {
        axios.post(baseurl + "?p=getListType", {
        }).then((response) => {
            if (response.data.status) {
                setListEquipType(response.data.list);
            }
        }).catch(function (error) {
            console.log("INTERNAL ERROR (getListType)", error.response.data, moment().format('DD-MM HH:mm'));
        });
    }


    const getListOffice = () => {
        axios.post(baseurl + "?p=getListOffice", {
        }).then((response) => {
            if (response.data.status) {
                setListEquipOffice(response.data.list);
            }
        }).catch(function (error) {
            console.log("INTERNAL ERROR (getListOffice)", error.response.data, moment().format('DD-MM HH:mm'));
        });
    }

    const getListUser = () => {
        axios.post(baseurl + "?p=getListUser", {
        }).then((response) => {
            if (response.data.status) {
                setListUser(response.data.list);
            }
        }).catch(function (error) {
            console.log("INTERNAL ERROR (getListUser)", error.response.data, moment().format('DD-MM HH:mm'));
        });
    }

    const getListAffectationSite = () => {
        axios.post(baseurl + "?p=getListAffectationSite", {
        }).then((response) => {
            if (response.data.status) {
                setListSiteAffectation(response.data.list);
            }
        }).catch(function (error) {
            console.log("INTERNAL ERROR (getListAffectationSite)", error.response.data, moment().format('DD-MM HH:mm'));
        });
    }

    const addEquipment = () => {
        console.log(equip_type);
        setSpinView(true);
        var add = true;
        var err = "Complete this field";
        if (equip_code == "" || exist) { setMessageCodeEquip(err); add = false; }
        else { setMessageCodeEquip(""); }

        if (equip_design === "") { setMessageDesignEquip(err); add = false }
        else { setMessageDesignEquip("") }

        if (equip_mark === "") { setMessageMarkEquip(err); add = false }
        else { setMessageMarkEquip("") }

        if (equip_serialNumber === "") { setMessageSerialNumberEquip(err); add = false }
        else { setMessageSerialNumberEquip("") }

        if (equip_type === 0) { setMessageTypeEquip("Please choose a type"); add = false }
        else { setMessageTypeEquip("") }

        if (equip_office === 0) { setMessageOfficeEquip("Please choose an office"); add = false }
        else { setMessageOfficeEquip("") }

        if (listUserChoosed.length === 0) { setMessageUserEquip("Please choose user"); add = false }
        else { setMessageUserEquip("") }

        if (equip_acquisitionDate === "") { setMessageAcquisitionDateEquip(err); add = false }
        else { setMessageAcquisitionDateEquip("") }

        if (equip_siteAffect === 0) { setMessageSiteAffectationEquip("Please choose a site"); add = false }
        else { setMessageSiteAffectationEquip("") }


        if (add && !exist) {
            axios.post(baseurl + "?p=addEquipment", {
                userId: ReactSession.get("prev_id"),
                userName: ReactSession.get("prev_username"),
                equip_code,
                equip_design,
                equip_mark,
                equip_serialNumber,
                equip_type,
                equip_office,
                equipStatus,
                equip_acquisitionDate,
                equip_siteAffect,
                equipIsMulti,
                equip_activationKey,
                listUserChoosed
            }).then((response) => {
                if (response.data.status) {
                    notifySuccess("Equipment added successfully")

                    handleTypeClose()
                } else {
                    notifyError("Error when we add the new equipment ")
                }
            }).catch(function (error) {
                console.log("INTERNAL ERROR (addEquipment)", error.response.data, moment().format('DD-MM HH:mm'));
            });
        } else {
            setSpinView(false)
        }
    }

    const checkIfEquipmentExist = (value, col) => {
        setExist(false);
        if (col === 1) {
            setMessageCodeEquip("");

            listEquipement.map((e) => {
                if ((e.eq_code).toLowerCase() === value.toLowerCase()) {
                    setExist(true);
                    setMessageCodeEquip("Equipment already exist");
                    return
                }
            })
        } else {
            setMessageSerialNumberEquip("")
            if (value !== '-') {
                listEquipement.map((e) => {
                    if ((e.eq_serial_number).toLowerCase() === value.toLowerCase()) {
                        setExist(true);
                        setMessageSerialNumberEquip("Serial number already exist");
                        return
                    }
                })
            }

        }

    }

    const Pagination = ({ postsPerPage, totalPosts, paginate }) => {
        var pageNumbers = [];
        var pageslink = 6;
        var startlink = 1;

        var totalPages = Math.ceil(totalPosts / postsPerPage);

        if (currentPage - 2 > 1 && currentPage + 2 <= totalPages) {
            startlink = currentPage - 2;
            pageNumbers = [];
            for (let i = startlink; i <= currentPage + 2; i++) {
                pageNumbers.push(i);
            }
        } else if (currentPage - 2 > 1 && currentPage + 1 <= totalPages) {
            startlink = currentPage - 3;
            pageNumbers = [];
            for (let i = startlink; i <= currentPage + 1; i++) {
                pageNumbers.push(i);
            }
        } else if (currentPage == totalPages) {
            if (currentPage - 5 >= 0) startlink = currentPage - 4;
            else if (currentPage - 4 >= 0) startlink = currentPage - 3;
            else if (currentPage - 3 >= 0) startlink = currentPage - 2;
            else if (currentPage - 2 >= 0) startlink = currentPage - 1;
            pageNumbers = [];
            for (let i = startlink; i <= totalPages; i++) {
                pageNumbers.push(i);
            }
        } else {
            startlink = 1;
            pageNumbers = [];
            if (pageslink > totalPages) pageslink = totalPages;
            for (let i = startlink; i <= pageslink; i++) {
                pageNumbers.push(i);
            }
        }

        return (
            <>
                <nav className="d-flex">
                    <ul className="pagination">
                        <li className="page-item">
                            {
                                <a onClick={() => paginate(1)} href="#" className="">
                                    {"«"}
                                </a>
                            }
                        </li>

                        <li className="page-item">
                            {currentPage == 1 ? (
                                <a href="#" className="" disabled>
                                    {"<"}
                                </a>
                            ) : (
                                <a
                                    onClick={() => paginate(currentPage - 1)}
                                    href="#"
                                    className=""
                                >
                                    {"<"}
                                </a>
                            )}
                        </li>

                        {pageNumbers.map((number) => (
                            <li key={number} className="page-item">
                                {currentPage == number ? (
                                    <a
                                        onClick={() => paginate(number)}
                                        href="#"
                                        className="bg-red"
                                    >
                                        {number}
                                    </a>
                                ) : (
                                    <a onClick={() => paginate(number)} href="#" className="">
                                        {number}
                                    </a>
                                )}
                            </li>
                        ))}

                        <li className="page-item">
                            {currentPage == totalPages ? (
                                <a href="#" className="" disabled>
                                    {">"}
                                </a>
                            ) : (
                                <a
                                    onClick={() => paginate(currentPage + 1)}
                                    href="#"
                                    className=""
                                >
                                    {">"}
                                </a>
                            )}
                        </li>

                        <li className="page-item">
                            {
                                <a onClick={() => paginate(totalPages)} href="#" className="">
                                    {"»"}
                                </a>
                            }
                        </li>
                    </ul>
                </nav>
            </>
        );
    };

    const getAllDetails = (eqCode) => {
        listEquipement.map((e) => {
            if (e.eq_code === eqCode) {
                setListEquipmentChoosen(e);
            }
        })
    }

    const getUserForEquipment = (eqCode) => {
        axios.post(baseurl + "?p=getUserForEquipment", {
            eqCode
        }).then((response) => {
            if (response.data.status) {
                setListUserForEquipment(response.data.list);
                var list = response.data.list;

                axios.post(baseurl + "?p=getListAllUser", {
                }).then((response) => {

                    setListNoUser(response.data.list);
                    var list1 = response.data.list;

                    if (response.data.status) {
                        for (let index = 0; index < list.length; index++) {
                            for (let index1 = 0; index1 < list1.length; index1++) {
                                if (list[index]['user_id'] === list1[index1]['user_id']) {
                                    setListNoUser((current) =>
                                        current.filter((obj) => obj.user_id !== list[index]['user_id'])
                                    );
                                }
                            }
                        }
                        setSpinView(false);
                        setSpinView1(false);
                    }
                })

            }
        }).catch(function (error) {
            console.log("INTERNAL ERROR (getUserForEquipment)", error.response.data, moment().format('DD-MM HH:mm'));
        });
    }

    const updateStatus = (id, stat) => {
        axios.post(baseurl + "?p=updateStatusEquipment", {
            userId: ReactSession.get('prev_id'),
            status: stat,
            id: id
        }).then((response) => {
            if (response.data.status) {
                notifySuccess("the equipment has been modified successfuly");
                handleTypeClose();
                setSpinView2(false);
            } else {
                setSpinView2(false);

                notifyError("Error when we tried to modify the equipment");
                setRefresh(true);
                handleTypeClose();
            }

        }).catch(function (error) {
            console.log("INTERNAL ERROR (updateStatus)", error.response.data, moment().format('DD-MM HH:mm'));
        });
    }

    const actionToList = (task) => {
        setMessageChangeMultiUser('');
        if (task === 'add') {
            axios.post(baseurl + "?p=addUserToEquipment", {
                creator: ReactSession.get('prev_id'),
                eqId: equip_code,
                userId: userAddId
            }).then((response) => {
                if (!response.data.status) {
                    setSpinView(false)
                } else {
                    setUserAddId(0);
                    setListNoUser([])
                    getUserForEquipment(equip_code);
                    notifySuccess("Added successfully")
                }
            }).catch(function (error) {
                console.log("INTERNAL ERROR (actionToList-addUsersToEqipment)", error.response.data, moment().format('DD-MM HH:mm'));
            });
        } else {
            setSpinView1(true);
            axios.post(baseurl + "?p=removeUserToEquipment", {
                creator: ReactSession.get('prev_id'),
                eqId: equip_code,
                userId: userUpdtId
            }).then((response) => {
                if (!response.data.status) {
                    setSpinView1(false);
                } else {
                    setUserUpdtAddId(0);
                    setListUserForEquipment([])
                    getUserForEquipment(equip_code);
                    notifySuccess("removed successfully")

                }
            }).catch(function (error) {
                console.log("INTERNAL ERROR (actionToList-removeUserToEquipment)", error.response.data, moment().format('DD-MM HH:mm'));
            });
        }

    }

    const filtering = () => {
        if (filterType === 1) {
            if (filterText !== "") {
                const filtered = listEquipement.filter(filtre => {
                    return filtre.eq_code.toLowerCase().indexOf(filterText.toLowerCase()) >= 0;
                });
                setListEquipement(filtered);
                setModalFilter(false);
                paginate(1);
            } else {
                setModalFilter(true);
            }
        } else if (filterType === 2) {
            if (filterText !== "") {
                const filtered = listEquipement.filter(filtre => {
                    return filtre.eq_serial_number.toLowerCase().indexOf(filterText.toLowerCase()) >= 0;
                });
                setListEquipement(filtered);
                setModalFilter(false);
                paginate(1);
            }
        } else if (filterType === 3) {
            if (filterText !== "") {
                const filtered = listEquipement.filter(filtre => {
                    return filtre.eq_designation.toLowerCase().indexOf(filterText.toLowerCase()) >= 0;
                });
                setListEquipement(filtered);
                setModalFilter(false);
                paginate(1);
            }
        } else {
            if (filterText !== "") {
                const filtered = listEquipement.filter(filtre => {
                    return filtre.user_name.toLowerCase().indexOf(filterText.toLowerCase()) >= 0;
                });
                setListEquipement(filtered);
                setModalFilter(false);
                paginate(1);
            }
        }

    };

    const getHistory = (code) => {
        axios.post(baseurl + "?p=getHistory", {
            eq_code: code
        }).then((response) => {
            if (response.data.status)
                setListHistory(response.data.list);
        }).catch(function (error) {
            console.log("INTERNAL ERROR (getHistory)", error.response.data, moment().format('DD-MM HH:mm'));
        });
    }

    const confirmChangeStatus = () => {
        setSpinView2(true);
        if (equip_Status === 0) {
            updateStatus(equipCodeHistory, 1)
        } else {
            updateStatus(equipCodeHistory, 0)
        }
    }

    const autoCompleteField = (type) => {
        let code = "";
        let num = 0;
        // if (equip_code === "") {
        if (type === "1" || type === "2") {
            code = "PC"
        } else if (type === "3") {
            code = "SCR"
        } else if (type === "4") {
            code = "PRT"
        } else if (type === "5") {
            code = "SCA"
        } else {
            code = "UPS"
        }
        listEquipement.map((e) => {
            if (type === "1" || type === "2") {
                if (e.eq_code.substring(0, 2) === code) {
                    if (parseInt(e.eq_code.substring(2)) > num) {
                        num = parseInt(e.eq_code.substring(2))
                    }
                }
            } else {
                if (e.eq_code.substring(0, 3) === code) {
                    if (parseInt(e.eq_code.substring(3)) > num) {

                        num = parseInt(e.eq_code.substring(3))
                    }
                }
            }
        })
        if ((num + 1) < 100 && (num + 1) > 9) {
            code += "0";
        } else if ((num + 1) < 10) {
            code += "00";
        }
        setEquip_code(code + (parseInt(num) + 1))
    }

    const changeMultiUser = () => {
        setSpinView2(true);
        if (isMulti === 1) {
            if (listUserForEquipment.length > 1) {
                setMessageChangeMultiUser("it have more than 1 user assigned")
                setSpinView2(false)
            } else {
                axios.post(baseurl + "?p=changeMultiUser", {
                    userId: ReactSession.get('prev_id'),
                    equip_code,
                    stat: 0,
                }).then((response) => {
                    if (response.data.status) {
                        setListEquipmentChoosen(current => {
                            setSpinView2(false);

                            return ({ ...current, eq_isMulti: 0 });
                        })
                        setMessageChangeMultiUser('')
                        setIsMulti(false)
                    } else { }
                }).catch(function (error) {
                    console.log("INTERNAL ERROR (changeMultiUser-1)", error.response.data, moment().format('DD-MM HH:mm'));
                });
            }
        } else {
            setMessageChangeMultiUser('')
            axios.post(baseurl + "?p=changeMultiUser", {
                userId: ReactSession.get('prev_id'),
                equip_code,
                stat: 1,
            }).then((response) => {
                if (response.data.status) {
                    setListEquipmentChoosen(current => {
                        setSpinView2(false);
                        return ({ ...current, eq_isMulti: 1 });
                    })
                    setIsMulti(false)
                }
            }).catch(function (error) {
                console.log("INTERNAL ERROR (changeMultiUser-2)", error.response.data, moment().format('DD-MM HH:mm'));
            });
        }
        setModalConfirmChangeStatus(false);
    }

    const changeDamage = () => {
        setSpinView2(true);
        if (isDamage === 1) {

            axios.post(baseurl + "?p=changeDamage", {
                equip_code,
                stat: 0,
            }).then((response) => {
                if (response.data.status) {
                    setListEquipmentChoosen(current => {
                        return ({ ...current, eq_isMulti: 0 });
                    })
                    setMessageChangeMultiUser('')
                    setSpinView2(false);
                    setIsMulti(false)
                }
            }).catch(function (error) {
                console.log("INTERNAL ERROR (changeDamage-1)", error.response.data, moment().format('DD-MM HH:mm'));
            });
        } else {
            setMessageChangeMultiUser('')
            axios.post(baseurl + "?p=changeMultiUser", {
                equip_code,
                stat: 1,
            }).then((response) => {
                if (response.data.status) {
                    setListEquipmentChoosen(current => {
                        return ({ ...current, eq_isMulti: 1 });
                    })
                    setSpinView2(false);
                    setIsMulti(false)
                }
            }).catch(function (error) {
                console.log("INTERNAL ERROR (changeDamage-2)", error.response.data, moment().format('DD-MM HH:mm'));
            });
        }
        setModalConfirmChangeStatus(false);
    }


    const editEquipment = (col, value) => {
        setListEquipmentChoosen(current => {
            if (col == 'eq_designation') {
                return ({ ...current, eq_designation: value });
            } else if (col === 'eq_marque') {
                return ({ ...current, eq_marque: value });
            } else if (col === 'eq_serial_number') {
                return ({ ...current, eq_serial_number: value });
            } else if (col == 'eq_activation_key') {
                return ({ ...current, eq_activation_key: value });
            } else if (col === 'eq_acquisition_date') {
                return ({ ...current, eq_acquisition_date: value });
            } else if (col == 'eq_etat') {
                return ({ ...current, eq_etat: value });
            } else if (col == 'eq_office_assign') {
                return ({ ...current, eq_office_assign: value });
            } else if (col == 'site_name') {
                return ({ ...current, eq_affectation_site: value });
            }
        })
    }

    const prepareEditing = () => {
        var filtered, filtered1, filtered2, filtered3, filtered4, filtered5 = [];


        filtered = listEquip_office.filter(filtre => {
            if (filtre.office_designation == listEquipmentChoosen['office_designation']) {
                return filtre.office_designation.toLowerCase().indexOf(listEquipmentChoosen['office_designation'].toLowerCase()) >= 0;
            }

        });
        filtered1 = listEquip_office.filter(filtre => {
            return filtre.office_designation.toLowerCase().indexOf(listEquipmentChoosen['office_designation'].toLowerCase()) < 0;
        });



        setListEquipOffice([...filtered, ...filtered1]);

        filtered2 = listState.filter(filtre => {
            return filtre.text.toLowerCase().indexOf(listEquipmentChoosen['eq_etat'].toLowerCase()) >= 0;
        })
        filtered3 = listState.filter(filtre => {
            return filtre.text.toLowerCase().indexOf(listEquipmentChoosen['eq_etat'].toLowerCase()) < 0;
        })
        setListState([...filtered2, ...filtered3]);



        filtered4 = listSiteAffectation.filter(filtre => {
            return filtre.site_name.toLowerCase().indexOf(listEquipmentChoosen['site_name'].toLowerCase()) >= 0;
        })
        filtered5 = listSiteAffectation.filter(filtre => {
            setSpinView3(false)
            return filtre.site_name.toLowerCase().indexOf(listEquipmentChoosen['site_name'].toLowerCase()) < 0;
        })
        setListSiteAffectation([...filtered4, ...filtered5]);

    }

    const changeValueOfSelect = () => {
        listEquip_office.map((e) => {
            if (e.id_office == listEquipmentChoosen['eq_office_assign']) {
                setListEquipmentChoosen(current => {
                    return ({ ...current, office_designation: e.office_designation });
                })
            }
        })


        listSiteAffectation.map((e) => {
            if (e.site_id + '' === listEquipmentChoosen['eq_affectation_site']) {
                setListEquipmentChoosen(current => {
                    return ({ ...current, site_name: e.site_name });
                })
            }
        })

        prepareEditing();

    }

    const updateEq = () => {
        axios.post(baseurl + "?p=updateEq", {
            userId: ReactSession.get('prev_id'),
            listEquipmentChoosen,
        }).then((response) => {
            if (response.data.status) {
                setEditEq(false);
                changeValueOfSelect();
                notifySuccess('Equipment update succesfully')
            } else {
                notifyError('problem when we update')
            }
        }).catch(function (error) {
            console.log("INTERNAL ERROR (updateEq)", error.response.data, moment().format('DD-MM HH:mm'));
        });
    }


    const paginate = (pageNumber) => setCurrentPage(pageNumber);
    const [listUserChoosed, setListUserChoosed] = useState(null);
    const [equip_Status, setEquip_Status] = useState(-1);
    return (
        <>
            <Header />
            <Header_mobile />
            <Modal show={modalAddEquipment} onHide={handleTypeClose} centered>
                <Modal.Header closeButton>

                    <Modal.Title>
                        Ajouter un équipement
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form>
                        <div className="form-group mb-1 mt-1 row g-2 ">
                            <div className="col-md-6 col-lg-6 col-xl-6">
                                <label className="font-weight-bold">
                                    TYPE <span className="text-danger pl-3">*</span>
                                </label>
                                <select
                                    id="type"
                                    onChange={(e) => {
                                        setEquip_type(e.target.value)
                                        autoCompleteField(e.target.value)
                                    }}
                                    name="plan-month"
                                    className="form-control rounded-0 cursor-pointer"
                                >
                                    <option value="" key="-1"></option>
                                    {!exist ? listEquipType ? (listEquipType.map((d, index) => (
                                        <option key={index} value={d.id_eq_type}>
                                            {d.eq_type_name}
                                        </option>
                                    ))
                                    ) : (
                                        <>

                                        </>
                                    ) : ""}
                                </select>

                                <div id="ticketprio-error" className="text-danger pt-2">
                                    {messageTypeEquip}
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-6 col-xl-6">
                                <label className="font-weight-bold">
                                    CODE DE L'ÉQUIPEMENT<span className="text-danger pl-3">*</span>
                                </label>
                                <input
                                    id="ticket-subject"
                                    name="subject"
                                    className="form-control rounded-0"
                                    onChange={(e) => {
                                        checkIfEquipmentExist(e.target.value, 1);
                                        setEquip_code((e.target.value).toUpperCase());
                                    }}
                                    value={equip_code}
                                />
                                <div id="ticketprio-error" className="text-danger pt-2">
                                    {messageCodeEquip}
                                </div>
                            </div>
                        </div>

                        <div className="form-group mb-1 mt-1 row g-2">
                            <div className="col-md-6 col-lg-6 col-xl-6">
                                <label className="font-weight-bold">
                                    DESIGNATION<span className="text-danger pl-3">*</span>
                                </label>
                                <input
                                    id="ticket-subject"
                                    name="subject"
                                    className="form-control rounded-0"
                                    value={equip_design}
                                    onChange={(e) => setEquip_design(e.target.value)}
                                    readOnly={exist}
                                />
                                <div id="ticketprio-error" className="text-danger pt-2">
                                    {messageDesignEquip}
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-6 col-xl-6">
                                <label className="font-weight-bold">
                                    MARK<span className="text-danger pl-3">*</span>
                                </label>
                                <input
                                    id="ticket-subject"
                                    name="subject"
                                    className="form-control rounded-0"
                                    value={equip_mark}
                                    onChange={(e) => { setEquip_mark(e.target.value) }}
                                    readOnly={exist}
                                />
                                <div id="ticketprio-error" className="text-danger pt-2">
                                    {messageMarkEquip}
                                </div>
                            </div>

                            {equip_type === 1 + '' || equip_type === 2 + '' ?
                                <div className="col-md-12 col-lg-12 col-xl-12">
                                    <label className="font-weight-bold">
                                        ACTIVATION KEY
                                    </label>
                                    <input
                                        id="ticket-subject"
                                        name="subject"
                                        className="form-control rounded-0"
                                        value={equip_activationKey}
                                        onChange={(e) => { setEquip_activationKey(e.target.value) }}
                                        readOnly={exist}
                                    />
                                </div>

                                :
                                <></>
                            }



                        </div>

                        <div className="form-group mb-1 mt-1 row g-2">
                            <div className="col-md-6 col-lg-6 col-xl-6">
                                <label className="font-weight-bold">
                                    SERIAL NUMBER <span className="text-danger pl-3">*</span>
                                </label>
                                <input
                                    id="ticket-subject"
                                    name="subject"
                                    className="form-control rounded-0"
                                    value={equip_serialNumber}
                                    onChange={(e) => { setEquip_serialNumber(e.target.value); checkIfEquipmentExist(e.target.value, 2); }}
                                />

                                <div id="ticketprio-error" className="text-danger pt-2">
                                    {messageSerialNumberEquip}
                                </div>
                            </div>


                            <div className="col-md-6 col-lg-6 col-xl-6">
                                <label className="font-weight-bold">
                                    OFFICE <span className="text-danger pl-3">*</span>
                                </label>
                                <select
                                    id="type"
                                    onChange={(e) => {
                                        setEquip_office(e.target.value)
                                    }}
                                    name="plan-month"
                                    className="form-control rounded-0 cursor-pointer"
                                >
                                    <option value="" key="-1"></option>
                                    {!exist ?
                                        listEquip_office ? (listEquip_office.map((d, index) => (
                                            <option key={index} value={d.id_office}>
                                                {d.office_designation}
                                            </option>
                                        ))
                                        ) : (
                                            <>

                                            </>
                                        )
                                        : ""
                                    }
                                </select>

                                <div id="ticketprio-error" className="text-danger pt-2">
                                    {messageOfficeEquip}
                                </div>
                            </div>

                        </div>

                        <div className="form-group mb-1 mt-1 row g-2">
                            <div className="col-md-6 col-lg-6 col-xl-6">
                                <label className="font-weight-bold">
                                    USER <span className="text-danger pl-3">*</span>
                                </label>
                                <Select
                                    isSearchable
                                    isMulti
                                    closeMenuOnSelect={false}
                                    hideSelectedOptions={false}
                                    defaultValue={listUserChoosed}
                                    onChange={setListUserChoosed}
                                    options={!exist ? listUser : []}
                                />


                                <div id="ticketprio-error" className="text-danger pt-2">
                                    {messageUserEquip}
                                </div>
                            </div>

                            <div className="col-md-3 col-lg-3 col-xl-3">
                                <label className="font-weight-bolder">ACTIVE</label>
                                <div className="radio-inline p-2">
                                    <div className="form-check form-switch">
                                        <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" onChange={(e) => { e.target.checked ? setEquipStatus(1) : setEquipStatus(0) }} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3 col-lg-3 col-xl-3">
                                <label className="font-weight-bolder">Multiple user</label>
                                <div className="radio-inline p-2">
                                    <div className="form-check form-switch">
                                        <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" onChange={(e) => { e.target.checked ? setEquipIsMulti(1) : setEquipIsMulti(0) }} />
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className="form-group mb-1 mt-1 row g-2 ">
                            <div className="col-md-6">
                                <label className="font-weight-bold">
                                    ACQUISITION DATE<span className="text-danger pl-3">*</span>
                                </label>
                                <input
                                    type="datetime-local"
                                    name="subject"
                                    className="form-control rounded-0"
                                    onChange={(e) => {
                                        setEquip_acquisitionDate(e.target.value);
                                    }}
                                    readOnly={exist}
                                />
                                <div id="ticketprio-error" className="text-danger pt-2">
                                    {messageAcquisitionDateEquip}
                                </div>
                            </div>

                            <div className="col-md-6">
                                <label className="font-weight-bold">
                                    SITE D'AFFECTATION <span className="text-danger pl-3">*</span>
                                </label>
                                <select
                                    id="type"
                                    onChange={(e) => {
                                        setEquip_siteAffect(e.target.value);
                                    }}
                                    className="form-control rounded-0 cursor-pointer"
                                >
                                    <option value="" key="-1" disabled={exist}></option>
                                    {!exist ?
                                        listSiteAffectation ? (listSiteAffectation.map((d, index) => (
                                            <option key={index} value={d.site_id}>
                                                {d.site_name}
                                            </option>
                                        ))
                                        ) : (
                                            <>

                                            </>
                                        )
                                        : ""
                                    }
                                </select>
                                <div id="ticketprio-error" className="text-danger pt-2">
                                    {messageSiteAffectationEquip}
                                </div>

                            </div>
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-secondary" onClick={handleTypeClose}>Annuler</button>

                    {spinView ?
                        <button className="btn btn-primary" type="button" disabled>
                            <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                            Loading ...
                        </button>
                        :
                        editEq ?
                            <button type="button" className="btn btn-success" onClick={editEquipment}>Modifier</button>
                            :
                            <button type="button" className="btn btn-success" onClick={addEquipment}>Envoyer</button>


                    }
                </Modal.Footer>
            </Modal>

            <Modal show={modalView} onHide={handleTypeClose} size="xl" centered>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {equip_code}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-lg-4 col-md-12 border-right">
                            <div className="border-bottom fs-14 font-weight-bolder mb-3 pb-2 d-flex justify-content-between" id="print-btn">
                                <div className="pt-2">
                                    DETAILS
                                </div>
                            </div>

                            <div className="form-group mb-2 d-flex bg-light2  pt-2">
                                {editEq ?
                                    <>
                                        <div className="col-lg-6 d-flex" >
                                            <label className="font-weight-bolder">DESIGNATION :</label>
                                        </div>
                                        <div className="col-lg-6">
                                            <input value={listEquipmentChoosen['eq_designation']} onChange={(e) => { editEquipment('eq_designation', e.target.value) }} />
                                        </div>
                                    </>
                                    :
                                    <div className="col-lg-11 d-flex" >
                                        <label className="font-weight-bolder">DESIGNATION : </label>
                                        <p id="pi_ref-v" className="pl-2">{listEquipmentChoosen['eq_designation']}</p>
                                    </div>
                                }
                            </div>

                            <div className="form-group mb-3 d-flex bg-light2  pt-2">
                                {editEq ?
                                    <>
                                        <div className="col-lg-6 d-flex" >
                                            <label className="font-weight-bolder">MARK : </label>
                                        </div>
                                        <div className="col-lg-6">
                                            <input value={listEquipmentChoosen['eq_marque']} onChange={(e) => { editEquipment('eq_marque', e.target.value) }} />
                                        </div>
                                    </>
                                    :
                                    <div className="col-lg-11 d-flex" >
                                        <label className="font-weight-bolder">MARK : </label>
                                        <p id="pi_ref-v" className="pl-2">{listEquipmentChoosen['eq_marque']}</p>
                                    </div>
                                }

                            </div>
                            <div className="form-group mb-3 d-flex bg-light2  pt-2">
                                {editEq ?
                                    <>
                                        <div className="col-lg-6 d-flex" >
                                            <label className="font-weight-bolder">SERIAL NUMBER: </label>
                                        </div>
                                        <div className="col-lg-6">
                                            <input value={listEquipmentChoosen['eq_serial_number']} onChange={(e) => { editEquipment('eq_serial_number', e.target.value) }} />
                                        </div>
                                    </>
                                    :
                                    <div className="col-lg-11 d-flex" >
                                        <label className="font-weight-bolder">SERIAL NUMBER: </label>
                                        <p id="pi_ref-v" className="pl-2">{listEquipmentChoosen['eq_serial_number']}</p>
                                    </div>
                                }

                            </div>
                            <div className="form-group mb-3 d-flex bg-light2  pt-2">
                                {editEq ?
                                    <>
                                        <div className="col-lg-6 d-flex" >
                                            <label className="font-weight-bolder">ACTIVATION KEY: </label>
                                        </div>
                                        <div className="col-lg-6">
                                            <input value={listEquipmentChoosen['eq_activation_key']} onChange={(e) => { editEquipment('eq_activation_key', e.target.value) }} />
                                        </div>
                                    </>
                                    :
                                    <div className="col-lg-11 d-flex" >
                                        <label className="font-weight-bolder">ACTIVATION KEY: </label>
                                        <p id="pi_ref-v" className="pl-2">{listEquipmentChoosen['eq_activation_key']}</p>
                                    </div>
                                }

                            </div>
                            <div className="form-group mb-3 d-flex bg-light2  pt-2">
                                {editEq ?
                                    <>
                                        <div className="col-lg-6 d-flex" >
                                            <label className="font-weight-bolder">TYPE : </label>
                                        </div>
                                        <div className="col-lg-6">
                                            <input value={listEquipmentChoosen['eq_type_name']} onChange={(e) => { }} readOnly />
                                        </div>
                                    </>
                                    :
                                    <div className="col-lg-11 d-flex" >
                                        <label className="font-weight-bolder">TYPE : </label>
                                        <p id="pi_ref-v" className="pl-2">{listEquipmentChoosen['eq_type_name']}</p>
                                    </div>
                                }
                            </div>
                            <div className="form-group mb-3 d-flex bg-light2  pt-2">
                                {editEq ?
                                    <>
                                        <div className="col-lg-6 d-flex" >
                                            <label className="font-weight-bolder">OFFICE : </label>
                                        </div>
                                        <div className="col-lg-6">
                                            <select
                                                onChange={(e) => {
                                                    editEquipment('eq_office_assign', e.target.value);

                                                }}>
                                                {listEquip_office.map((d, index) => (
                                                    <option key={index} value={d.id_office}>
                                                        {d.office_designation}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </>
                                    :
                                    <div className="col-lg-11 d-flex" >
                                        <label className="font-weight-bolder">OFFICE : </label>
                                        <p id="pi_ref-v" className="pl-2">{listEquipmentChoosen['office_designation']}</p>
                                    </div>
                                }
                            </div>
                            <div className="form-group mb-3 d-flex bg-light2  pt-2">
                                {editEq ?
                                    <>
                                        <div className="col-lg-6 d-flex " >
                                            <label className="font-weight-bolder">STATE : </label>
                                        </div>
                                        <div className="col-lg-6">
                                            <select
                                                onChange={(e) => {
                                                    editEquipment('eq_etat', e.target.value);
                                                }}>
                                                {listState.map((d, index) => (
                                                    <option key={index} value={d.text}>
                                                        {d.text}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </>
                                    :
                                    <div className="col-lg-11 d-flex" >
                                        <label className="font-weight-bolder">STATE : </label>
                                        <p id="pi_ref-v" className="pl-2">{listEquipmentChoosen['eq_etat']}</p>
                                    </div>
                                }

                            </div>


                            <div className="form-group mb-3 d-flex bg-light2  pt-2">
                                {editEq ?
                                    <>
                                        <div className="col-lg-6 d-flex" >
                                            <label className="font-weight-bolder">MULTI USER : </label>
                                        </div>
                                        <div className="col-lg-6">
                                            {listEquipmentChoosen['eq_isMulti'] === 0 ? <HiXCircle size={25} className="text-danger" /> : <HiCheckCircle size={25} className="text-success" />}
                                            {spinView2 ?
                                                <span className="spinner-border spinner-border-sm mr-2 bg-warning" role="status" aria-hidden="true"></span>
                                                :
                                                perm_edit_equipement === 1 ?
                                                    <TbExchange className="cursor-pointer text-warning ml-5" size={20} onClick={() => { setIsMulti(listEquipmentChoosen['eq_isMulti']); setIsMultiAction(true); setModalConfirmChangeStatus(true); }} />
                                                    : null
                                            }
                                        </div>
                                    </>
                                    :
                                    <>
                                        <div className="col-lg-10 d-flex" >
                                            <label className="font-weight-bolder">MULTI USER : </label>
                                            <p id="Created-date-v" className="pl-2">{listEquipmentChoosen['eq_isMulti'] === 0 ? <HiXCircle size={25} className="text-danger" /> : <HiCheckCircle size={25} className="text-success" />}</p>
                                        </div>
                                        <div className="col-lg-2">
                                            {spinView2 ?
                                                <span className="spinner-border spinner-border-sm mr-2 bg-warning" role="status" aria-hidden="true"></span>
                                                :
                                                perm_edit_equipement === 1 ?
                                                    <TbExchange className="cursor-pointer text-warning" size={20} onClick={() => { setIsMulti(listEquipmentChoosen['eq_isMulti']); setIsMultiAction(true); setModalConfirmChangeStatus(true); }} />
                                                    : null}
                                        </div>
                                    </>
                                }

                            </div><div id="ticketprio-error " className="text-danger">
                                {messageChangeMultiUser}
                            </div>
                            <div className="form-group mb-3 d-flex bg-light2  pt-2">
                                {editEq ?
                                    <>
                                        <div className="col-lg-6 d-flex" >
                                            <label className="font-weight-bolder">ACQUISITION DATE</label>
                                        </div>
                                        <div className="col-lg-6">
                                            <input type="date" className="w-150px" value={moment(listEquipmentChoosen['eq_acquisition_date']).format('yyyy-MM-DD')} onChange={(e) => { editEquipment('eq_acquisition_date', e.target.value) }} />
                                        </div>
                                    </>
                                    :
                                    <div className="col-lg-11 d-flex" >
                                        <label className="font-weight-bolder">ACQUISITION DATE : </label>
                                        <p id="pi_ref-v" className="pl-2">{moment(listEquipmentChoosen['eq_acquisition_date']).format('MMMM DD, YYYY')}</p>
                                    </div>
                                }
                            </div>
                            <div className="form-group mb-3 d-flex bg-light2  pt-2">
                                {editEq ?
                                    <>
                                        <div className="col-lg-6 d-flex" >
                                            <label className="font-weight-bolder">AFFECTATION SITE : </label>
                                        </div>
                                        <div className="col-lg-6">
                                            <select
                                                className="w-100"
                                                onChange={(e) => {
                                                    editEquipment('site_name', e.target.value);
                                                    setEquip_siteAffect(e.target.value)
                                                }}>
                                                {listSiteAffectation.map((d, index) => (
                                                    <option key={index} value={d.site_id}>
                                                        {d.site_name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </>
                                    :
                                    <div className="col-lg-11 d-flex" >
                                        <label className="font-weight-bolder">AFFECTATION SITE : </label>
                                        <p id="pi_ref-v" className="pl-2">{listEquipmentChoosen['site_name']}</p>
                                    </div>
                                }
                            </div>
                        </div>

                        <div className="col-lg-8 col-md-12 d-flex">
                            <div className="col-lg-5 col-md-5 col-sm-5">
                                <div className="border-bottom fs-14 font-weight-bolder mb-5 pb-4 pt-2 text-center">ACTUAL USERS ({listUserForEquipment.length})</div>
                                <select id="materials-list" className="form-control rounded-0" onChange={(e) => { setUserUpdtAddId(e.target.value) }} size="15">
                                    {listUserForEquipment.length == 0 ?
                                        <option key={-1}>No user For this Equipment</option>
                                        :
                                        listUserForEquipment ? listUserForEquipment.map((d, index) => (
                                            <option value={d.user_id} key={index}>{d.user_name}</option>
                                        )) : (
                                            <></>
                                        )
                                    }
                                </select>
                            </div>

                            {perm_add_user_equipement === 1 ?

                                <div className="col-lg-1 col-md-1 col-sm-1 pt-30 ">
                                    <div className="col-xl-1 mt-5">
                                        <p id="add" onClick={() => {
                                            setSpinView(true);

                                            if (userAddId > 0) {
                                                actionToList('add');
                                            } else {
                                                setSpinView(false);
                                            }
                                        }

                                        } className="btn btn-light border fs-13 pl-3 pr-2 pt-3 pb-3 mt-3" title="add a new user" >
                                            {spinView ?
                                                <span className="spinner-border spinner-border-sm mr-2 text-success" role="status" aria-hidden="true" disabled></span>
                                                :
                                                <FcPrevious size={20} />}

                                        </p>

                                        <p onClick={() => {
                                            if (userUpdtId > 0) {
                                                actionToList('update');
                                            }
                                        }} id="empty-tab" className="btn btn-light border fs-13 pl-3 pr-2 pt-3 pb-3 mt-3" title="remove a user" >
                                            {spinView1 ?
                                                <span className="spinner-border spinner-border-sm mr-2 text-success" role="status" aria-hidden="true" disabled></span>
                                                :
                                                <FcNext size={20} />
                                            }
                                        </p>
                                    </div>
                                </div>
                                :
                                <></>
                            }


                            <div className="col-lg-6 col-md-6 col-sm-5 ml-5 ">
                                <div className="border-bottom fs-14 font-weight-bolder mb-5 pb-4 pt-2 text-center">NEW USERS ({listNoUser.length})</div>
                                <select id="materials-list" className="form-control rounded-0" onChange={(e) => { setUserAddId(e.target.value) }} size="15">
                                    {listNoUser.length == 0 ?
                                        <option key={-1}>No user For this Equipment</option>
                                        :
                                        listNoUser ? listNoUser.map((d, index) => (
                                            <option value={d.user_id} key={index} id={d.user_id + "@"} disabled={listEquipmentChoosen['eq_isMulti'] === 0 && listUserForEquipment.length === 1 ? true : false}>{d.user_name}</option>
                                        )) : (
                                            <></>
                                        )
                                    }
                                </select>
                            </div>
                        </div>
                    </div>

                </Modal.Body>
                <Modal.Footer className="text-start">
                    {editEq ?
                        <>
                            <div className="col-lg-3 col-md-4 col-sm-4 col-6">
                                <button type="button" className="btn btn-danger ml-0 " onClick={() => { setEditEq(false) }}> CANCEL</button>
                                {spinView3 ?
                                    <button className="btn btn-success" type="button" disabled>
                                        <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                                        Updating ...
                                    </button>
                                    :
                                    <button type="button" className="btn btn-success ml-2 " onClick={() => { updateEq(); setSpinView3(true) }}> Update</button>
                                }
                            </div>
                            <div className="col-lg-7 col-md-5 col-sm-5 col-3"></div>
                        </>

                        :
                        <>
                            {perm_edit_equipement === 1 ?
                                <button type="button" className="btn btn-info col-md-2 col-lg-1 col-sm-2 col-2 ml-0" onClick={() => { setEditEq(true); prepareEditing() }}>Edit fields</button>
                                :
                                <></>
                            }

                            <div className="col-lg-9 col-md-7 col-sm-7 col-7"></div>
                        </>
                    }

                    <button type="button" className="btn btn-danger col-lg-1 col-md-2 col-sm-2 col-2" onClick={handleTypeClose}>Close</button>
                </Modal.Footer>
            </Modal>

            <Modal show={modalFilter} onHide={handleTypeClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Filter</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form>
                        <div className="form-group mb-4 mt-3">
                            <label className="font-weight-bold">
                                {
                                    filterType === 1 ? (
                                        "CODE DE L'ÉQUIPEMENT"
                                    ) : filterType === 2 ? (
                                        "SERIAL NUMBER"
                                    ) : filterType === 3 ? (
                                        "DESIGNATION"
                                    ) : filterType === 4 ? (
                                        "SITE D'AFFECTATION"
                                    ) : filterType === 5 ? (
                                        "CRÉE PAR"
                                    ) : ""
                                }
                                <span className="text-danger pl-3">*</span>
                            </label>
                            <input
                                id="eq-subject"
                                onChange={(e) => {
                                    setFilterText(e.target.value);
                                }}
                                name="subject"
                                className="form-control rounded-0"
                            />
                            <div id="ticketprio-error" className="text-danger pt-2">

                            </div>
                        </div>

                        <button ref={null} hidden className="btn">
                            submit
                        </button>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-secondary" onClick={handleTypeClose}>Close</button>
                    <button type="button" className="btn btn-success" onClick={filtering}>Add filter</button>
                </Modal.Footer>
            </Modal>

            <Modal show={modalHistory} onHide={handleTypeClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>HISTORY OF {equipCodeHistory}</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{

                    height: '400px',
                    overflow: 'auto',

                }}>
                    <table className="table ">
                        <thead >
                            <tr >
                                <th>#</th>
                                <th>Action</th>
                                <th>Date</th>
                            </tr>
                        </thead>
                        <tbody >


                            {listHistory.map((e, index) => {
                                return (
                                    <tr key={index} >
                                        <td>{index + 1}</td>
                                        <td className='cursor-pointer'>
                                            {
                                                e.eq_status_from === null && e.eq_status_to === null ? (
                                                    e.eq_user_from === null ? (
                                                        <label>User {e.user_to_name} was added to the equipment</label>
                                                    ) : <label>User {e.user_from_name} was removed to the equipment</label>
                                                ) : (
                                                    e.eq_status_from === 0 ? (
                                                        <label>The status of {e.eq_code} was changed to ACTIVE</label>
                                                    ) : <label>The status of {e.eq_code} was changed to INACTIVE</label>
                                                )
                                            }
                                        </td>
                                        <td>{moment(e.hist_date).format('MMMM DD, YYYY')}</td>
                                    </tr>
                                );

                            })}
                        </tbody>
                    </table>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" onClick={handleTypeClose} className="btn btn-secondary">Close</button>
                </Modal.Footer>
            </Modal>

            <Modal show={modalConfirmChangeStatus} size="sm" onHide={() => { isMultiAction ? setModalConfirmChangeStatus(false) : handleTypeClose() }} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Confirmation</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure ?
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-secondary" onClick={() => { isMultiAction ? setModalConfirmChangeStatus(false) : handleTypeClose() }}>Close</button>
                    {spinView2 ?
                        <button className="btn btn-success" type="button" disabled>
                            <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                            Loading  ...
                        </button>
                        :
                        <button type="button" className="btn btn-success" onClick={() => { isMultiAction ? changeMultiUser() : confirmChangeStatus(); }}>Confirm</button>
                    }

                </Modal.Footer>
            </Modal>

            <Modal show={isDamageAction} size="sm" onHide={() => { setIsDamageAction(false) }} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Confirmation</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure ?
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-secondary" onClick={() => { setIsDamageAction(false) }}>Close</button>
                    {spinView2 ?
                        <button className="btn btn-success" type="button" disabled>
                            <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                            Loading  ...
                        </button>
                        :
                        <button type="button" className="btn btn-success" onClick={() => { changeDamage() }}>Confirm</button>
                    }

                </Modal.Footer>
            </Modal>





            <div
                className="d-flex flex-column flex-column-fluid "
                style={{ minHeight: "85vh", backgroundColor: "#eef0f8" }}
            >
                <div className=" container mt-20 mb-20">
                    <div className="row mb-5">
                        <div className="col-xl-1"></div>
                        <div className="col-xl-12 col-md-12">
                            <div className="row">
                                <div className="col-xl-12 col-md-12" id="tck1">
                                    <div className="card card-custom gutter-b">
                                        <div className="card-body d-flex flex-column pb-0 ">
                                            <div className="flex-grow-1 pb-0">
                                                <div className="row text-left card-header " style={{ backgroundColor: "white" }}>
                                                    <div className="row text-left">
                                                        <div className="col">
                                                            <label> Equipements</label>
                                                            {spinView ?
                                                                <span className="spinner-border spinner-border-sm ml-2 fs-20 text-success" role="status" aria-hidden="true"></span>
                                                                :
                                                                <i className="flaticon-refresh ml-2 cursor-pointer" onClick={() => { getAllEquipements(); setSpinView(true); }}> </i>
                                                            }

                                                        </div>
                                                        <div className="col text-right">
                                                            {windowSize.innerWidth < 768 ?
                                                                <>
                                                                    <FcClearFilters size={40} className="btn btn-light ml-2 cursor-pointer border" onClick={getAllEquipements} />
                                                                    {perm_add_equipement === 1 ?
                                                                        <AiOutlinePlusCircle size={40} className="cursor-pointer text-danger border ml-2" onClick={() => setModalAddEquipment(true)} />
                                                                        :
                                                                        <></>
                                                                    }

                                                                </>
                                                                :
                                                                <>
                                                                    <button className="btn btn-light" onClick={getAllEquipements}>Supprimer le filtre</button>
                                                                    {perm_add_equipement === 1 ?
                                                                        <button className="btn btn-danger ml-2" onClick={() => setModalAddEquipment(true)}>Ajouter un equipement</button>
                                                                        :
                                                                        <></>
                                                                    }

                                                                </>
                                                            } </div>
                                                    </div>
                                                </div>

                                                <div className="card-body">
                                                    <div className="table-responsive">
                                                        <table id="result" className="table data">
                                                            <thead>
                                                                <tr>
                                                                    <th className="text-left w-25px" scope="col" > #</th>
                                                                    <th className="text-left" scope="col"></th>
                                                                    <th className="text-left" scope="col">
                                                                        CODE DE L'ÉQUIPEMENT
                                                                        <a href="#" data-toggle="modal" data-target="#fi_ref_modal">
                                                                            <i className="la la-filter" onClick={() => { setModalFilter(true); setFilterType(1); setListEquipement(listEquipement2) }}></i>
                                                                        </a>
                                                                    </th>
                                                                    <th className="text-left" scope="col">
                                                                        SERIAL NUMBER
                                                                        <a href="#" data-toggle="modal" data-target="#fi_ref_modal">
                                                                            <i className="la la-filter" onClick={() => { setModalFilter(true); setFilterType(2); setListEquipement(listEquipement2) }}></i>
                                                                        </a>
                                                                    </th>
                                                                    <th className="text-left" scope="col">
                                                                        DESIGNATION
                                                                        <a href="#" data-toggle="modal" data-target="#fi_ref_modal">
                                                                            <i className="la la-filter" onClick={() => { setModalFilter(true); setFilterType(3); setListEquipement(listEquipement2) }}></i>
                                                                        </a>
                                                                    </th>

                                                                    <th className="text-left" scope="col">
                                                                        SITE D'AFFECTATION
                                                                    </th>
                                                                    <th className="text-left" scope="col">
                                                                        CRÉE PAR
                                                                        <a href="#" data-toggle="modal" data-target="#fi_ref_modal">
                                                                            <i className="la la-filter" onClick={() => { setModalFilter(true); setFilterType(5); setListEquipement(listEquipement2) }}></i>
                                                                        </a>
                                                                    </th>
                                                                    <th className="text-left" scope="col">
                                                                        ACTIVE
                                                                    </th>
                                                                    <th className="text-left" scope="col">
                                                                        DATE DE CRÉATION
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody id="resultdata">
                                                                {currentEquipement ?
                                                                    currentEquipement.map((d, index) => (
                                                                        <tr key={index}>
                                                                            <td className="text-left">{d.eq_id}</td>
                                                                            <td className="text-left">
                                                                                <Dropdown className="dropdown">
                                                                                    <Dropdown.Toggle
                                                                                        variant="dropdown-basic"
                                                                                        className="w-60px py-0 text-white p-0 m-0"
                                                                                    >
                                                                                        <div
                                                                                            className="dropdown dropdown-inline bg-light2 py-0 rounded"
                                                                                            data-toggle="dropdown"

                                                                                        >
                                                                                            <a

                                                                                                type="button"
                                                                                                className="btn btn-light2 btn-icon py-0 btn-sm"
                                                                                            >
                                                                                                <i className="ki ki-bold-more-hor"></i>
                                                                                            </a>
                                                                                        </div>
                                                                                    </Dropdown.Toggle>

                                                                                    <Dropdown.Menu className="">
                                                                                        <>
                                                                                            {perm_history_equipement === 1 ?


                                                                                                <Dropdown.Item
                                                                                                    href="#"
                                                                                                    className="dropdown-item border-bottom border-top"
                                                                                                    onClick={() => { setEquipCodeHistory(d.eq_code); setModalHistory(true); getHistory(d.eq_code) }}
                                                                                                >
                                                                                                    <FaHistory size={20} className='text-warning' />
                                                                                                    {/* <i className="far fa-edit"></i>{" "} */}
                                                                                                    <span className="pl-3">Historique</span>
                                                                                                </Dropdown.Item>
                                                                                                :
                                                                                                <></>
                                                                                            }
                                                                                            {perm_status_equipement === 1 ?
                                                                                                <Dropdown.Item
                                                                                                    href="#"
                                                                                                    className="dropdown-item border-bottom border-top"
                                                                                                    onClick={() => { setEquipCodeHistory(d.eq_code); setEquip_Status(d.eq_active); setModalConfirmChangeStatus(true);/* if (d.eq_active === 0) { updateStatus(d.eq_code, 1) } else { updateStatus(d.eq_code, 0) } */ }}
                                                                                                >

                                                                                                    {/* <i className="fa fa-ban"></i>{" "} */}
                                                                                                    {/* <span className="pl-3">{d.eq_active === 1 ? "Active" : "Desactive"}</span> */}
                                                                                                    {d.eq_active === 0 ? <><FcPlus size={20} /> <span className="pl-3">Active</span></> : <><FcMinus size={20} /> <span className="pl-3">Desactive</span></>}

                                                                                                </Dropdown.Item>
                                                                                                :
                                                                                                <></>
                                                                                            }

                                                                                        </>
                                                                                    </Dropdown.Menu>
                                                                                </Dropdown>
                                                                            </td>
                                                                            <td className="text-left hover_underlined cursor-pointer" onClick={() => { getUserForEquipment(d.eq_code); setModalView(true); getAllDetails(d.eq_code); setEquip_code(d.eq_code) }}>{d.eq_code}</td>

                                                                            <td className="text-left">{d.eq_serial_number}</td>
                                                                            <td className="text-left">{d.eq_designation}</td>
                                                                            {/* <td className="text-left">{d.eq_user}</td> */}
                                                                            <td className="text-left">{d.site_name}</td>
                                                                            <td className="text-left">{d.user_name}</td>
                                                                            <td className="text-left">{d.eq_active === 1 ? <HiCheckCircle size={25} className="text-success" /> : <HiXCircle size={25} className="text-danger" />}</td>

                                                                            <td className="text-left">{moment(d.eq_created_date).format('MMMM DD, YYYY')}</td>
                                                                        </tr>
                                                                    )) : (<></>)
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>



                                        <div className="card-footer pt-2 pb-2 col-12 mb-0 bg-white">
                                            <div className=" col-sm-12 d-flex">
                                                <select className="page-range" onChange={(e) => { setlinePerPage(e.target.value); setCurrentPage(1) }}>
                                                    <option value={10}>10</option>
                                                    <option value={25}>25</option>
                                                    <option value={50}>50</option>
                                                    <option value={listEquipement.length}>All</option>
                                                </select>
                                                <div className="mt-6"> </div>
                                                <div className="mt-3"><div id="resultfound">{listEquipement.length} résultat(s)</div> </div>
                                            </div>
                                            <div className=" col-sm-12">
                                                <div className="d-flex align-items-center justify-content-center">

                                                    <Pagination
                                                        postsPerPage={linePerPage}
                                                        totalPosts={listEquipement.length}
                                                        paginate={paginate}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-xl-4">

                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default Equipements;