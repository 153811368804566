import axios from "axios";
import { useEffect, useState, useRef } from "react";
import { RiMailOpenLine } from "react-icons/ri";
import baseurl from "./globals";
import Header from "./header";
import Header_mobile from "./header_mobile";
import { ReactSession } from "react-client-session";
import Accordion from 'react-bootstrap/Accordion';
import moment from 'moment';
import { HiMail, HiMailOpen } from "react-icons/hi";
import { BiRefresh } from "react-icons/bi";
import toast, { Toaster } from "react-hot-toast";


function Notifications() {
    const [windowSize, setWindowSize] = useState(getWindowSize());
    const [listNotification, setListNotification] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [linePerPage, setlinePerPage] = useState(15);
    const indexOfLastPost = currentPage * linePerPage;
    const indexOfFirstPost = indexOfLastPost - linePerPage;
    const paginate = (pageNumber) => setCurrentPage(pageNumber);
    const [spinView, setSpinView] = useState(false);

    const [spinView1, setSpinView1] = useState(false);


    const currentNotifications = listNotification.slice(indexOfFirstPost, indexOfLastPost);


    function getWindowSize() {
        const { innerWidth, innerHeight } = window;
        return { innerWidth, innerHeight };
    }
    useEffect(() => {
        getAllMyNotification();
        function handleWindowResize() {
            setWindowSize(getWindowSize());
        }

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    const notifySuccess = (msg) =>
        toast.success(msg, {
            style: {
                padding: "16px",
                backgroundColor: "rgba(124, 140, 135, 0.70)",
                color: "#FFF",
                maxWidth: "280px",
                textAlign: "left",
                fontSize: "13px",
            },
        });

    const notifyError = (msg) =>
        toast.error(msg, {
            style: {
                padding: "16px",
                backgroundColor: "rgba(124, 140, 135, 0.70)",
                color: "#FFF",
                maxWidth: "280px",
                textAlign: "left",
                fontSize: "13px",
            },
        });

    const getAllMyNotification = () => {
        axios.post(baseurl + "?p=getAllMyNotification", {
            user_id: ReactSession.get("prev_id")
        }).then((response) => {
            setListNotification(response.data.list);
            setSpinView(false)
            setSpinView1(false)
        }).catch(function (error) {
            console.log("INTERNAL ERROR (getAllMyNotification)", error.response.data, moment().format('DD-MM HH:mm'));
        });
    }

    const Pagination = ({ postsPerPage, totalPosts, paginate }) => {
        var pageNumbers = [];
        var pageslink = 6;
        var startlink = 1;

        var totalPages = Math.ceil(totalPosts / postsPerPage);

        if (currentPage - 2 > 1 && currentPage + 2 <= totalPages) {
            startlink = currentPage - 2;
            pageNumbers = [];
            for (let i = startlink; i <= currentPage + 2; i++) {
                pageNumbers.push(i);
            }
        } else if (currentPage - 2 > 1 && currentPage + 1 <= totalPages) {
            startlink = currentPage - 3;
            pageNumbers = [];
            for (let i = startlink; i <= currentPage + 1; i++) {
                pageNumbers.push(i);
            }
        } else if (currentPage == totalPages) {
            if (currentPage - 5 >= 0) startlink = currentPage - 4;
            else if (currentPage - 4 >= 0) startlink = currentPage - 3;
            else if (currentPage - 3 >= 0) startlink = currentPage - 2;
            else if (currentPage - 2 >= 0) startlink = currentPage - 1;
            pageNumbers = [];
            for (let i = startlink; i <= totalPages; i++) {
                pageNumbers.push(i);
            }
        } else {
            startlink = 1;
            pageNumbers = [];
            if (pageslink > totalPages) pageslink = totalPages;
            for (let i = startlink; i <= pageslink; i++) {
                pageNumbers.push(i);
            }
        }

        return (
            <>
                <nav className="d-flex">
                    <ul className="pagination">
                        <li className="page-item">
                            {
                                <a onClick={() => paginate(1)} href="#" className="">
                                    {"«"}
                                </a>
                            }
                        </li>

                        <li className="page-item">
                            {currentPage == 1 ? (
                                <a href="#" className="" disabled>
                                    {"<"}
                                </a>
                            ) : (
                                <a
                                    onClick={() => paginate(currentPage - 1)}
                                    href="#"
                                    className=""
                                >
                                    {"<"}
                                </a>
                            )}
                        </li>

                        {pageNumbers.map((number) => (
                            <li key={number} className="page-item">
                                {currentPage == number ? (
                                    <a
                                        onClick={() => paginate(number)}
                                        href="#"
                                        className="bg-red"
                                    >
                                        {number}
                                    </a>
                                ) : (
                                    <a onClick={() => paginate(number)} href="#" className="">
                                        {number}
                                    </a>
                                )}
                            </li>
                        ))}

                        <li className="page-item">
                            {currentPage == totalPages ? (
                                <a href="#" className="" disabled>
                                    {">"}
                                </a>
                            ) : (
                                <a
                                    onClick={() => paginate(currentPage + 1)}
                                    href="#"
                                    className=""
                                >
                                    {">"}
                                </a>
                            )}
                        </li>

                        <li className="page-item">
                            {
                                <a onClick={() => paginate(totalPages)} href="#" className="">
                                    {"»"}
                                </a>
                            }
                        </li>
                    </ul>
                </nav>
            </>
        );
    };

    const markAsRead = (id) => {
        setListNotification(listNotification.map(x => {
            if (x.not_id === id)
                return { ...x, not_read: 1 };
            return x
        }))
        axios.post(baseurl + "?p=viewNotification", {
            creator: ReactSession.get('prev_id'),
            not_id: id
        }).then((response) => {
            if (!response.data.status) {
                notifyError('Error when we read this message')
            }
        }).catch(function (error) {
            console.log("INTERNAL ERROR (markAsRead)", error.response.data, moment().format('DD-MM HH:mm'));
        });
    }

    const markAll = () => {

        const filtered = listNotification.filter(filtre => {
            return filtre.not_read + "".indexOf(0 + "") < 0;
        });
        if (filtered.length > 0) {
            setSpinView1(true)
            axios.post(baseurl + "?p=markAll", {
                creator: ReactSession.get('prev_id'),
                filtered
            }).then((response) => {
                if (response.data.status) {
                    notifySuccess('All notification is marked read')
                    getAllMyNotification();
                } else {
                    setSpinView(false);
                    setSpinView1(false);
                }
            }).catch(function (error) {
                console.log("INTERNAL ERROR (markAll)", error.response.data, moment().format('DD-MM HH:mm'));
            });
        }
    }


    return (
        <>
            <Header />
            <Header_mobile />
            <div
                className="d-flex flex-column flex-column-fluid "
                style={{ minHeight: "85vh", backgroundColor: "#eef0f8" }}

            >
                <div className="col-xl-12 center-block " align="center" style={{ backgroundColor: "#eef0f8" }} >
                    <div className="col-xl-6 bg-white mt-50 center-block min-h-650px" align="center" >
                        <div className="row text-left pt-3 pb-3 mt-10 border-bottom shadow" style={{ borderRadius: '10px' }}>
                            <div className=" col">
                                <label className="fs-20 ml-5"> Notifications</label>
                                {spinView ?
                                    <span className="spinner-border spinner-border-sm ml-2 fs-20 text-success" role="status" aria-hidden="true"></span>
                                    :
                                    <i className="flaticon-refresh ml-2 cursor-pointer" onClick={() => { getAllMyNotification(); setSpinView(true); }}> </i>
                                }
                            </div>
                            <div className="col text-right">
                                {windowSize.innerWidth < 768 ?
                                    <>
                                        <RiMailOpenLine size={40} className="btn btn-light ml-2 cursor-pointer border " />

                                    </>
                                    :
                                    <>
                                        <button className="btn btn-light ml-2" onClick={() => { markAll() }}>
                                            Mark all as read  {spinView1 ? <span className="spinner-border spinner-border-sm ml-2 fs-20 text-success" role="status" aria-hidden="true"></span> : ""}
                                        </button>
                                    </>
                                }
                            </div>
                        </div>

                        <div className="h-500px mt-3 overflow-auto">
                            {
                                currentNotifications ? (
                                    currentNotifications.map((d, index) => (
                                        <Accordion defaultActiveKey="0" className="pb-2" key={index}>
                                            <Accordion.Item eventKey={index} onClick={() => d.not_read === 0 ? markAsRead(d.not_id) : null}>
                                                <Accordion.Header>
                                                    <div className="">
                                                        <div className="d-flex ">
                                                            {d.not_read === 1 ? <HiMailOpen size={20} className="text-success" /> : <HiMail size={20} className="text-warning" />}
                                                            <label>{d.not_title}</label>
                                                        </div>
                                                        <div className="">
                                                            {moment(d.not_created_date).format('YYYY-MM-DD')}
                                                        </div>
                                                    </div>
                                                </Accordion.Header>
                                                <Accordion.Body className="text-left">
                                                    <div>
                                                        de : {d.senderName}
                                                    </div>
                                                    <div>
                                                        {d.not_description}
                                                    </div>
                                                </Accordion.Body>
                                            </Accordion.Item>

                                        </Accordion>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan={11} className="text-center pt-20 fs-16">
                                            No data found
                                        </td>
                                    </tr>
                                )
                            }

                        </div>
                        <div className="row card-footer pt-2 pb-2 col-12 mb-0 bg-white ">
                            <div className="d-flex col-lg-5 col-md-5 col-sm-5 col-12">
                                <select className="page-range" onChange={(e) => { setlinePerPage(e.target.value); setCurrentPage(1) }}>
                                    <option value={10}>10</option>
                                    <option value={25}>25</option>
                                    <option value={50}>50</option>
                                    <option value={listNotification.length}>All</option>
                                </select>
                                <div className="mt-6"> </div>
                                <div className="mt-3"><div id="resultfound">{listNotification.length} résultat(s)</div> </div>
                            </div>
                            <div className="col-lg-3 col-md-3 col-sm-3 col-10 ">
                                <div className="">

                                    <Pagination
                                        postsPerPage={linePerPage}
                                        totalPosts={listNotification.length}
                                        paginate={paginate}
                                    />
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-2 col-sm-4col-xl-4">

                            </div>

                        </div>
                    </div>

                </div>

            </div >

        </>
    );
}


export default Notifications;