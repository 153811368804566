import axios from "axios";
import { useEffect, useState, useRef } from "react";
import "../Assets/css/bootstrap.min.css";
import Header from "./header";
import Header_mobile from "./header_mobile";
import Footer from "./footer";
import { ReactSession } from "react-client-session";
import baseurl from "./globals";
import { useAsyncError, useNavigate } from "react-router-dom";
// import { BiRefresh } from "react-icons/bi";
import Accordion from 'react-bootstrap/Accordion';
import { FcMinus, FcPlus, FcPrevious, FcNext, FcPrint } from 'react-icons/fc';
import logo from "../Assets/img/auto-logo.jpg";
import { useReactToPrint } from "react-to-print";
import moment from 'moment';
import { AiOutlinePlusCircle } from 'react-icons/ai';
import { FcClearFilters, FcSupport } from 'react-icons/fc';
import { BsCardChecklist } from 'react-icons/bs';
import { FiUserPlus, FiUserMinus } from "react-icons/fi";
import { HiXCircle, HiCheckCircle } from "react-icons/hi";
import { FaHistory } from "react-icons/fa";
import { GrFormClose } from "react-icons/gr";


import Dropdown from "react-bootstrap/Dropdown";
import Modal from "react-bootstrap/Modal";
import toast, { Toaster } from "react-hot-toast";
import { BiEdit, BiSend } from "react-icons/bi";
var bcrypt = require("bcryptjs");
var salt = bcrypt.genSaltSync(10);

function Users() {
    const navigate = useNavigate();

    const [showInactive, setShowInactive] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [modalFilter, setModalFilter] = useState(false);
    const [modalView, setModalView] = useState(false);
    const [modalConfirmChangeStatus, setModalConfirmChangeStatus] = useState(false);
    const [permissionView, setPermissionView] = useState(false);
    const [modalLogs, setModalLogs] = useState(false)
    const [modalFilterLog, setModalFilterLog] = useState(false);


    const [spinView, setSpinView] = useState(false);
    const [spinView1, setSpinView1] = useState(false);
    const [spinView2, setSpinView2] = useState(false);
    const [indexSpin, setIndexSpin] = useState(0);



    const [listUser, setListUser] = useState([]);
    const [listUser1, setListUser1] = useState([]);
    const [listUserChoosed, setListUserChoosed] = useState([]);
    const [listEquipmentForUser, setListEquipmentForUser] = useState([]);
    const [listNoEquipment, setListNoEquipment] = useState([]);
    const [listNoEquipment1, setListNoEquipment1] = useState([]);
    const [listDetailIntervention, setListDetailIntervention] = useState([]);
    const [listDetailRequiredMaterial, setListDetailRequiredMaterial] = useState([]);
    const [listPermissionOfUser, setListPermissionOfUser] = useState([]);
    const [listLogs, setListLogs] = useState([]);
    const [listLogs1, setListLogs1] = useState([]);
    const [listActualFilter, setListActualFilter] = useState([]);


    const [filterDate, setFilterDate] = useState('');
    const [filterTitle, setFilterTitle] = useState('');

    const [limitPerm1, setLimitPerm1] = useState(300);
    const [limitPerm2, setLimitPerm2] = useState(399);
    const [actualPage, setActualPage] = useState('PREVENTIVE INTERVENTION')

    const [listSecour, setListSecour] = useState([]);

    const [filterType, setFilterType] = useState("");
    const [modalFilterText, setModalFilterText] = useState("");

    const [userCode, setUserCode] = useState("");
    const [userStatus, setUserStatus] = useState(-1);



    const [currentPage, setCurrentPage] = useState(1);
    const [linePerPage, setlinePerPage] = useState(15);
    const indexOfLastPost = currentPage * linePerPage;
    const indexOfFirstPost = indexOfLastPost - linePerPage;
    const currentPosts = listUser.slice(indexOfFirstPost, indexOfLastPost);
    const [filterText, setFilterText] = useState("");

    const [equipAdd, setEquipAdd] = useState("");
    const [equipRemove, setEquipRemove] = useState("");


    const [supportForm, setSupportForm] = useState("");
    const [supportForm1, setSupportForm1] = useState("");

    const [perm_view_equipement, setPerm_view_equipement] = useState(-1);
    const [perm_edit_equipement, setPerm_edit_equipement] = useState(-1);
    const [perm_add_equipement, setPerm_add_equipement] = useState(-1);
    const [perm_view_materials, setPerm_view_materials] = useState(-1);
    const [perm_edit_materials, setPerm_edit_materials] = useState(-1);
    const [perm_add_materials, setPerm_add_materials] = useState(-1);
    const [perm_view_affectation_site, setPerm_view_affectation_site] = useState(-1);
    const [perm_edit_affectation_site, setPerm_edit_affectation_site] = useState(-1);
    const [perm_add_affectation_site, setPerm_add_affectation_site] = useState(-1);
    const [perm_create_intervention, setPerm_create_intervention] = useState(-1);
    const [perm_edit_intervention, setPerm_edit_intervention] = useState(-1);
    const [perm_view_intervention, setPerm_view_intervention] = useState(-1);
    const [perm_assign_intervention, setPerm_assign_intervention] = useState(-1);
    const [perm_close_intervention, setPerm_close_intervention] = useState(-1);
    const [perm_edit_assign, setPerm_edit_assign] = useState(-1);
    const [perm_add_signature, setPerm_add_signature] = useState(-1);
    const [perm_cancel_intervention, setPerm_cancel_intervention] = useState(-1);
    const [perm_Report, setPerm_Report] = useState(-1);
    const [perm_List_User, setPerm_List_User] = useState(-1);
    const [perm_add_user, setPerm_add_user] = useState(-1);
    const [perm_edit_user, setPerm_edit_user] = useState(-1);
    const [perm_status_user, setPerm_status_user] = useState(-1);
    const [perm_edit_equipment_user, setPerm_edit_equipment_user] = useState(-1);
    const [perm_history_equipement, setPerm_history_equipement] = useState(-1);
    const [perm_status_equipement, setPerm_status_equipement] = useState(-1);
    const [perm_add_user_equipement, setPerm_add_user_equipement] = useState(-1);
    const [perm_Edit_permissions, setPerm_Edit_permissions] = useState(-1);
    const [perm_view_logs, setPerm_view_logs] = useState(-1);

    const [domainConfirmation, setDomainConfirmation] = useState(false);
    const [domainName, setDomainName] = useState('');
    const [message_domainName, setMessage_DomainName] = useState('');
    const [domainAdd, setDomainAdd] = useState(false);

    const [editDomainName, setEditDomainName] = useState(false);


    const [windowSize, setWindowSize] = useState(getWindowSize());

    useEffect(() => {
        handleOnLoad();

        setSupportForm("print-Container");
        setSupportForm1("print-Container");
        function handleWindowResize() {
            setWindowSize(getWindowSize());
        }

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);


    const handleOnLoad = () => {
        axios.post(baseurl + "?p=getUserPermissions", {
            userId: ReactSession.get('prev_id')
        }).then((response) => {
            (response.data.list).map((e, index) => {
                switch (e['usrper_per_id']) {
                    case 214:
                        setPerm_view_equipement(e['usrper_val']);
                        break;

                    case 215:
                        setPerm_edit_equipement(e['usrper_val']);
                        break;

                    case 216:
                        setPerm_add_equipement(e['usrper_val']);
                        break;

                    case 217:
                        setPerm_view_materials(e['usrper_val']);
                        break;

                    case 218:
                        setPerm_edit_materials(e['usrper_val']);
                        break;

                    case 219:
                        setPerm_add_materials(e['usrper_val']);
                        break;

                    case 223:
                        setPerm_view_affectation_site(e['usrper_val']);
                        break;

                    case 224:
                        setPerm_edit_affectation_site(e['usrper_val']);
                        break;

                    case 225:
                        setPerm_add_affectation_site(e['usrper_val']);
                        break;

                    case 300:
                        setPerm_create_intervention(e['usrper_val']);
                        break;

                    case 301:
                        setPerm_edit_intervention(e['usrper_val']);
                        break;

                    case 302:
                        setPerm_view_intervention(e['usrper_val']);
                        break;

                    case 303:
                        setPerm_assign_intervention(e['usrper_val']);
                        break;

                    case 304:
                        setPerm_close_intervention(e['usrper_val']);
                        break;

                    case 305:
                        setPerm_edit_assign(e['usrper_val']);
                        break;

                    case 306:
                        setPerm_add_signature(e['usrper_val']);
                        break;

                    case 307:
                        setPerm_cancel_intervention(e['usrper_val']);
                        break;

                    case 308:
                        setPerm_Report(e['usrper_val']);
                        break;

                    case 400:
                        setPerm_List_User(e['usrper_val']);
                        if (e['usrper_val'] === 0) {
                            navigate('/home')
                        }
                        break;

                    case 401:
                        setPerm_add_user(e['usrper_val']);
                        break;

                    case 402:
                        setPerm_edit_user(e['usrper_val']);
                        break;

                    case 403:
                        setPerm_status_user(e['usrper_val']);
                        break;

                    case 404:
                        setPerm_edit_equipment_user(e['usrper_val']);
                        break;
                    case 405:
                        setPerm_Edit_permissions(e['usrper_val']);
                        break;
                    case 406:
                        setPerm_view_logs(e['usrper_val']);
                        break;
                    case 240:
                        setPerm_history_equipement(e['usrper_val']);
                        break;
                    case 241:
                        setPerm_status_equipement(e['usrper_val']);
                        break;
                    case 242:
                        setPerm_add_user_equipement(e['usrper_val']);
                        break;

                    default:
                        break;
                }
            })
        }).catch(function (error) {
            console.log("INTERNAL ERROR (handleOnLoad)", error.response.data, moment().format('DD-MM HH:mm'));
        });
    }

    function getWindowSize() {
        const { innerWidth, innerHeight } = window;
        return { innerWidth, innerHeight };
    }

    function getUserPermissions(userId) {
        axios.post(baseurl + "?p=getUserPermissions", {
            userId
        }).then((response) => {
            if (response.data.status) {
                setListPermissionOfUser(response.data.list);
            }
        }).catch(function (error) {
            console.log("INTERNAL ERROR (getUserPermissions)", error.response.data, moment().format('DD-MM HH:mm'));
        });
        setSpinView(false);

    }


    useEffect(() => {
        getListUser();
        getListEquipment();

    }, [refresh]);

    const getListEquipment = () => {
        axios.post(baseurl + "?p=getListEquip", {
        }).then((response) => {
            if (response.data.status) {
                setListNoEquipment1(response.data.list);
            }
        }).catch(function (error) {
            console.log("INTERNAL ERROR (getListEquipment)", error.response.data, moment().format('DD-MM HH:mm'));
        });
    }

    const getListUser = () => {
        axios.post(baseurl + "?p=getListAllUser", {
        }).then((response) => {
            if (response.data.status) {
                const filtered = response.data.list.filter(filtre => {
                    return filtre.user_status + "".indexOf(1 + "") >= 0;
                });
                setListUser(filtered);
                setListUser1(response.data.list);
            }
        }).catch(function (error) {
            console.log("INTERNAL ERROR (getListUser)", error.response.data, moment().format('DD-MM HH:mm'));
        });

    }

    const filterActive = () => {
        const filtered = listUser.filter(filtre => {
            return filtre.user_status + "".indexOf(1 + "") >= 0;
        });
        setListUser(filtered);
    }



    const Pagination = ({ postsPerPage, totalPosts, paginate }) => {
        var pageNumbers = [];
        var pageslink = 6;
        var startlink = 1;

        var totalPages = Math.ceil(totalPosts / postsPerPage);

        if (currentPage - 2 > 1 && currentPage + 2 <= totalPages) {
            startlink = currentPage - 2;
            pageNumbers = [];
            for (let i = startlink; i <= currentPage + 2; i++) {
                pageNumbers.push(i);
            }
        } else if (currentPage - 2 > 1 && currentPage + 1 <= totalPages) {
            startlink = currentPage - 3;
            pageNumbers = [];
            for (let i = startlink; i <= currentPage + 1; i++) {
                pageNumbers.push(i);
            }
        } else if (currentPage == totalPages) {
            if (currentPage - 5 >= 0) startlink = currentPage - 4;
            else if (currentPage - 4 >= 0) startlink = currentPage - 3;
            else if (currentPage - 3 >= 0) startlink = currentPage - 2;
            else if (currentPage - 2 >= 0) startlink = currentPage - 1;
            pageNumbers = [];
            for (let i = startlink; i <= totalPages; i++) {
                pageNumbers.push(i);
            }
        } else {
            startlink = 1;
            pageNumbers = [];
            if (pageslink > totalPages) pageslink = totalPages;
            for (let i = startlink; i <= pageslink; i++) {
                pageNumbers.push(i);
            }
        }

        return (
            <>
                <nav className="d-flex">
                    <ul className="pagination">
                        <li className="page-item">
                            {
                                <a onClick={() => paginate(1)} href="#" className="">
                                    {"«"}
                                </a>
                            }
                        </li>

                        <li className="page-item">
                            {currentPage == 1 ? (
                                <a href="#" className="" disabled>
                                    {"<"}
                                </a>
                            ) : (
                                <a
                                    onClick={() => paginate(currentPage - 1)}
                                    href="#"
                                    className=""
                                >
                                    {"<"}
                                </a>
                            )}
                        </li>

                        {pageNumbers.map((number) => (
                            <li key={number} className="page-item">
                                {currentPage == number ? (
                                    <a
                                        onClick={() => paginate(number)}
                                        href="#"
                                        className="bg-red"
                                    >
                                        {number}
                                    </a>
                                ) : (
                                    <a onClick={() => paginate(number)} href="#" className="">
                                        {number}
                                    </a>
                                )}
                            </li>
                        ))}

                        <li className="page-item">
                            {currentPage == totalPages ? (
                                <a href="#" className="" disabled>
                                    {">"}
                                </a>
                            ) : (
                                <a
                                    onClick={() => paginate(currentPage + 1)}
                                    href="#"
                                    className=""
                                >
                                    {">"}
                                </a>
                            )}
                        </li>

                        <li className="page-item">
                            {
                                <a onClick={() => paginate(totalPages)} href="#" className="">
                                    {"»"}
                                </a>
                            }
                        </li>
                    </ul>
                </nav>
            </>
        );
    };

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const notifySuccess = (msg) =>
        toast.success(msg, {
            style: {
                padding: "16px",
                backgroundColor: "rgba(124, 140, 135, 0.70)",
                color: "#FFF",
                maxWidth: "280px",
                textAlign: "left",
                fontSize: "13px",
            },
        });

    const notifyError = (msg) =>
        toast.error(msg, {
            style: {
                padding: "16px",
                backgroundColor: "rgba(124, 140, 135, 0.70)",
                color: "#FFF",
                maxWidth: "280px",
                textAlign: "left",
                fontSize: "13px",
            },
        });

    const filtering = () => {

        if (filterType === 1) {
            if (filterText !== "") {
                const filtered = listUser.filter(filtre => {
                    return filtre.user_full_name.toLowerCase().indexOf(filterText.toLowerCase()) >= 0;
                });
                setListUser(filtered);
                setModalFilter(false);
                paginate(1);
            } else {
                setModalFilter(true);
            }
        } else if (filterType === 2) {
            if (modalFilter !== "") {
                const filtered = listUser.filter(filtre => {
                    return filtre.user_name.toLowerCase().indexOf(filterText.toLowerCase()) >= 0;
                });
                setListUser(filtered);
                setModalFilter(false);
                paginate(1);
            }
        } else if (filterType > 3) {
            if (filterType === 4) {
                filterListLog('date');
            } else {
                filterListLog('text');
            }
        } else {
            if (filterText !== "") {
                const filtered = listUser.filter(filtre => {
                    return filtre.user_email.toLowerCase().indexOf(filterText.toLowerCase()) >= 0;
                });
                setListUser(filtered);
                setModalFilter(false);
                paginate(1);
            }
        }

    };

    const filterListLog = (type) => {

        if (type === 'date') {
            let found = false;

            setListActualFilter(listActualFilter.map((e) => {
                if (e.type === type) {
                    found = true;
                    return { ...e, val: filterDate }
                }
                return { ...e }
            }))

            if (!found) {
                setListActualFilter(current => [...current, { type: 'date', val: filterDate }])
            }

            setListLogs(listLogs1.filter(filtre => {
                return filtre.pilogs_date.toLowerCase().indexOf(filterDate.toLowerCase()) >= 0;
            }))

            if (filterTitle !== "") {
                const filtered = listLogs1.filter(filtre => {
                    return filtre.pilogs_date.toLowerCase().indexOf(filterDate.toLowerCase()) >= 0;
                })

                setListLogs(
                    filtered.filter(filtre => {
                        return filtre.pilogs_title.toLowerCase().indexOf(filterTitle.toLowerCase()) >= 0;
                    })
                )
            }

            setModalFilterLog(false);

        } else {
            let found = false;
            setListActualFilter(listActualFilter.map((e) => {
                if (e.type === type) {
                    found = true;
                    return { ...e, val: filterTitle }
                }
                return { ...e }
            }))

            if (!found) {
                setListActualFilter(current => [...current, { type: 'text', val: filterTitle }])
            }

            setListLogs(
                listLogs1.filter(filtre => {
                    return filtre.pilogs_title.toLowerCase().indexOf(filterTitle.toLowerCase()) >= 0;
                })
            )

            if (filterDate !== "") {
                const filtered = listLogs1.filter(filtre => {
                    return filtre.pilogs_date.toLowerCase().indexOf(filterDate.toLowerCase()) >= 0;
                })

                setListLogs(
                    filtered.filter(filtre => {
                        return filtre.pilogs_title.toLowerCase().indexOf(filterTitle.toLowerCase()) >= 0;
                    })
                )
            }
            setModalFilterLog(false);
        }
    }

    const handleTypeClose = () => {
        setModalConfirmChangeStatus(false)
        setModalFilter(false);
        setModalView(false);
        setShowInactive(false);
        setPermissionView(false);
        setDomainConfirmation(false);
        setEditDomainName(false);
        setModalLogs(false);
        setDomainName('')
        setFilterDate('');
        setFilterTitle('');
        setMessage_DomainName("")

        setLimitPerm1(300);
        setLimitPerm2(399);
        setActualPage('PREVENTIVE INTERVENTION');

        setListActualFilter([]);

        if (refresh) {
            setRefresh(false);
        } else {
            setRefresh(true);
        }
    }

    const getAllDetails = (userCode) => {
        listUser.map((e) => {
            if (e.user_id === userCode) {
                setListUserChoosed(e);
            }
        })
    }

    const getEquipmentForUser = async (userId) => {
        await axios.post(baseurl + "?p=getEquipmentForUser", {
            userId
        }).then((response) => {
            if (response.data.status) {
                setListEquipmentForUser(response.data.list);
                var list = response.data.list;//eq_code

                axios.post(baseurl + "?p=getListEquip", {
                }).then((response) => {
                    setListNoEquipment(response.data.list);
                    setListSecour(response.data.list);
                    var list1 = response.data.list; // value

                    for (let i = 0; i < list.length; i++) {
                        for (let j = 0; j < list1.length; j++) {
                            if (list[i]['eq_code'] === list1[j]['value']) {
                                setListNoEquipment((current) =>
                                    current.filter((obj) => obj.value !== list[i]['eq_code'])
                                )
                                setListSecour((current) =>
                                    current.filter((obj) => obj.value !== list[i]['eq_code'])
                                )
                            }

                        }
                    }
                    setSpinView(false);
                    setSpinView1(false);
                })
                // filterSecondList(response.data.list);
            }
        }).catch(function (error) {
            console.log("INTERNAL ERROR (getEquipmentForUser)", error.response.data, moment().format('DD-MM HH:mm'));
        });
    }

    const filterNewEquipment = (text) => {
        setListNoEquipment(listSecour.filter((eq) => {
            return eq.value.toLowerCase().includes(text.toLowerCase());
        }));
    }

    const getDetailIntervention = (userId) => {
        axios.post(baseurl + "?p=getDetailIntervention", {
            userId
        }).then((response) => {
            if (response.data.status) {
                setListDetailIntervention(response.data.listIntervention);
                setListDetailRequiredMaterial(response.data.listRequiredMaterials);
            }
        }).catch(function (error) {
            console.log("INTERNAL ERROR (getDetailIntervention)", error.response.data, moment().format('DD-MM HH:mm'));
        });
    }

    async function handleViews(userId) {
        await getAllDetails(userId);
        await getDetailIntervention(userId);
        await getEquipmentForUser(userId);
        await getUserPermissions(userId)
        await setUserCode(userId);
        await setModalView(true)

    }

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: "Check list",
        onAfterPrint: () => console.log("Print success")
    })

    const componentRef1 = useRef();
    const handlePrint1 = useReactToPrint({
        content: () => componentRef1.current,
        documentTitle: "Maintenance",
        onAfterPrint: () => console.log("Print success")
    })

    const actionToList = (task) => {
        if (task === 'add') {
            axios.post(baseurl + "?p=addUserToEquipment", {
                creator: ReactSession.get('prev_id'),
                eqId: equipAdd,
                userId: listUserChoosed['user_id']
            }).then((response) => {
                if (!response.data.status) {
                    setSpinView(false)
                } else {
                    setEquipAdd("");
                    setListNoEquipment([])
                    getEquipmentForUser(listUserChoosed['user_id']);
                    notifySuccess("Added successfully")
                }
            }).catch(function (error) {
                console.log("INTERNAL ERROR (actionToList-1)", error.response.data, moment().format('DD-MM HH:mm'));
            });
        } else {
            setSpinView1(true);
            if (equipRemove !== "") {
                axios.post(baseurl + "?p=removeUserToEquipment", {
                    creator: ReactSession.get('prev_id'),
                    eqId: equipRemove,
                    userId: listUserChoosed['user_id']
                }).then((response) => {
                    if (!response.data.status) {
                        setSpinView1(false)
                    } else {
                        setEquipRemove("");
                        setListEquipmentForUser([]);
                        getEquipmentForUser(listUserChoosed['user_id']);
                        notifySuccess("removed successfully")
                    }
                }).catch(function (error) {
                    console.log("INTERNAL ERROR (actionToList-2)", error.response.data, moment().format('DD-MM HH:mm'));
                });
            }
        }
    }

    const confirmChangeStatus = () => {
        if (userStatus === 0) {
            updateStatus(userCode, 1)
        } else {
            updateStatus(userCode, 0)
        }
    }

    const updateStatus = (id, stat) => {

        axios.post(baseurl + "?p=updateUserStatus", {
            author: ReactSession.get('prev_id'),
            status: stat,
            id: id
        }).then((response) => {
            if (response.data.status) {
                notifySuccess("the user has been modified successfuly");
            } else {
                notifyError("Error when we tried to modify the user");
            }
            setRefresh(true);
            handleTypeClose();
        }).catch(function (error) {
            console.log("INTERNAL ERROR (updateStatus)", error.response.data, moment().format('DD-MM HH:mm'));
        });
    }

    const filterListPermission = (page) => {
        if (page === 'PREVENTIVE INTERVENTION') {
            setLimitPerm1(300);
            setLimitPerm2(399);
        } else if (page === 'EQUIPMENTS') {
            setLimitPerm1(214);
            setLimitPerm2(216);
        } else if (page === 'MATERIALS') {
            setLimitPerm1(217);
            setLimitPerm2(219);
        } else if (page === 'AFFECTATION SITES') {
            setLimitPerm1(223);
            setLimitPerm2(225);
        } else if (page === 'USERS') {
            setLimitPerm1(400);
            setLimitPerm2(499);
        }
        setActualPage(page)
    }

    const modifyListPermission = (id, value) => {
        setListPermissionOfUser(listPermissionOfUser.map(x => {
            if (x.usrper_id === id)
                return { ...x, usrper_val: value ? 1 : 0 };
            return x
        }))
    }

    const updatePermissions = (id) => {
        axios.post(baseurl + "?p=updatePermissions", {
            author: ReactSession.get('prev_id'),
            user_id: id,
            listPermissionOfUser
        }).then((response) => {
            if (response.data.status) {
                notifySuccess('Permissions update succesfully')
                setSpinView(false)
            } else {
                notifyError('Error when we update');
                setSpinView(false)
            }
        }).catch(function (error) {
            console.log("INTERNAL ERROR (updatePermissions)", error.response.data, moment().format('DD-MM HH:mm'));
        });
    }

    const domainAction = () => {
        if (domainAdd) {
            if (domainName === "") {
                setMessage_DomainName('This field is required');
            } else {
                axios.post(baseurl + "?p=domainAction", {
                    author: ReactSession.get('prev_id'),
                    action: 'add',
                    domainName,
                    userCode
                }).then((response) => {
                    if (response.data.status) {
                        notifySuccess('Operation success')
                        handleTypeClose()
                    }
                }).catch(function (error) {
                    console.log("INTERNAL ERROR (domainAction-1)", error.response.data, moment().format('DD-MM HH:mm'));
                });
            }

        } else {
            axios.post(baseurl + "?p=domainAction", {
                author: ReactSession.get('prev_id'),
                action: 'remove',
                userCode
            }).then((response) => {
                if (response.data.status) {
                    notifySuccess('Operation success')
                    handleTypeClose()

                }
            }).catch(function (error) {
                console.log("INTERNAL ERROR (domainAction-2)", error.response.data, moment().format('DD-MM HH:mm'));
            });
        }
    }

    const modifyListDomainName = (val) => {
        setListUserChoosed(x => {
            return { ...x, user_domain_name: val };
        })
    }

    const updateDomainName = () => {
        setSpinView2(true)
        axios.post(baseurl + "?p=modifyDomainName", {
            author: ReactSession.get('prev_id'),
            domainName,
            userCode
        }).then((response) => {
            if (response.data.status) {
                notifySuccess("Domain name update successfully")
                setEditDomainName(false);
                setSpinView2(false)

            } else {
                setSpinView2(false)
            }
        }).catch(function (error) {
            console.log("INTERNAL ERROR (updateDomainName)", error.response.data, moment().format('DD-MM HH:mm'));
        });
    }

    const handlePrepareLogs = (userId) => {
        axios.post(baseurl + '?p=prepareLogs', {
            userId
        }).then((response) => {
            setListLogs(response.data.list);
            setListLogs1(response.data.list);
        }).catch(function (error) {
            console.log("INTERNAL ERROR (handlePrepareLogs)", error.response.data, moment().format('DD-MM HH:mm'));
        });
    }

    const removeFilter = (type) => {
        setListActualFilter((current) =>
            current.filter((obj) => obj.type !== type)
        );

        if (type === 'date') {
            setFilterDate('');
            setFilterTitle('');
            if (filterDate === '') {
                setListLogs(listLogs1)
            } else {
                setListLogs(
                    listLogs1.filter(filtre => {
                        return filtre.pilogs_title.toLowerCase().indexOf(filterTitle.toLowerCase()) >= 0;
                    })
                )
            }
        } else {
            setFilterTitle('');
            if (filterDate === '') {
                setListLogs(listLogs1)
            } else {
                setListLogs(
                    listLogs1.filter(filtre => {
                        return filtre.pilogs_date.toLowerCase().indexOf(filterDate.toLowerCase()) >= 0;
                    })
                )
            }
        }
    }

    return (
        <>
            <Header />
            <Header_mobile />

            <Modal show={modalView} onHide={() => { setModalView(false) }} size="xl" centered>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {listUserChoosed['user_preventive_intervention_access'] === 1 && perm_Edit_permissions === 1 && listUserChoosed['user_id'] !== 1 ?
                            <>
                                <div className={!permissionView ? "thumbnail border-left border-top border-right border-danger d-inline p-2 cursor-pointer choosen" : "thumbnail border-left border-top border-right border-danger d-inline p-2 cursor-pointer"} onClick={() => setPermissionView(false)}>{listUserChoosed['user_name']}</div>
                                <div className={permissionView ? "thumbnail border-left border-top border-right border-danger d-inline p-2 cursor-pointer choosen" : "thumbnail border-left border-top border-right border-danger d-inline p-2 cursor-pointer"} onClick={() => setPermissionView(true)}>Permissions</div>
                            </> :
                            listUserChoosed['user_name']}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {!permissionView ?
                        <div className="row">
                            <div className="col-lg-4 border-right">
                                <div className="border-bottom fs-14 font-weight-bolder mb-3 pb-2 d-flex justify-content-between" id="print-btn">
                                    <div className="pt-2">
                                        DETAILS
                                    </div>
                                </div>

                                <div className="form-group mb-2 d-flex bg-light2 pl-3 pr-3 pt-2">
                                    <label className="font-weight-bolder">FULLNAME : </label>
                                    <p id="pi_ref-v" className="pl-2">{listUserChoosed['user_full_name']}</p>
                                </div>
                                <div className="form-group mb-3 d-flex bg-light2 pl-3 pr-3 pt-2">
                                    <label className="font-weight-bolder">USERNAME : </label>
                                    <p id="pi_status-v" className="pl-2">{listUserChoosed['user_name']}</p>
                                </div>
                                <div className="form-group mb-3 d-flex bg-light2 pl-3 pr-3 pt-2">
                                    <label className="font-weight-bolder">EMAIL: </label>
                                    <p id="Created-by-v" className="pl-2">{listUserChoosed['user_email']}</p>
                                </div>
                                <div className="form-group mb-3 d-flex bg-light2 pl-3 pr-3 pt-2">
                                    <label className="font-weight-bolder">CITY : </label>
                                    <p id="Created-date-v" className="pl-2">{listUserChoosed['user_city']}</p>
                                </div>
                                <div className="form-group mb-3 d-flex bg-light2 pl-3 pr-3 pt-2">
                                    <label className="font-weight-bolder">ON DOMAIN : </label>
                                    <p id="Created-date-v" className="pl-2">{listUserChoosed['user_OnDomain'] === 1 ? <HiCheckCircle size={25} className="text-success" /> : <HiXCircle size={25} className="text-danger" />}</p>
                                </div>
                                {
                                    listUserChoosed['user_OnDomain'] === 1 ?
                                        <div className="form-group mb-3 d-flex bg-light2 pl-3 pr-3 pt-2">
                                            <label className="font-weight-bolder">DOMAIN NAME : </label>
                                            {!editDomainName ?
                                                <>
                                                    <p id="Created-date-v" className="pl-2">{listUserChoosed['user_domain_name']}</p>
                                                    {perm_edit_user === 1 ?
                                                        <BiEdit size={20} className="text-success cursor-pointer float-right ml-2" onClick={() => { setEditDomainName(true); setDomainName(listUserChoosed['user_domain_name']) }} />
                                                        :
                                                        <></>}
                                                </>
                                                :
                                                <>
                                                    <input value={listUserChoosed['user_domain_name']} onChange={(e) => { setDomainName(e.target.value); modifyListDomainName(e.target.value) }} size={10} className="ml-2" />
                                                    {spinView2 ?
                                                        <span className="spinner-border spinner-border-sm mr-2 text-success" role="status" aria-hidden="true" disabled></span>
                                                        :
                                                        <BiSend size={20} className="text-warning cursor-pointer ml-2" onClick={() => { updateDomainName() }} />
                                                    }

                                                </>
                                            }
                                        </div>
                                        : <></>

                                }
                            </div>

                            <div className="col-lg-8 col-md-12 d-flex">
                                <div className="col-lg-5 col-md-5 col-sm-5">
                                    <div className="border-bottom fs-14 font-weight-bolder mb-5 pb-4 pt-2 text-center">ACTUAL EQUIPMENTS ({listEquipmentForUser.length})</div>

                                    <select id="materials-list" className="form-control rounded-0" onChange={(e) => { setEquipRemove(e.target.value) /*setUserUpdtAddId(e.target.value)*/ }} size="15">
                                        {listEquipmentForUser.length == 0 ?
                                            <option key={-1} value="">No user For this Equipment</option>
                                            :
                                            listEquipmentForUser ? listEquipmentForUser.map((d, index) => (
                                                <option value={d.eq_code} key={index}>{d.eq_code}</option>
                                            )) : (
                                                <></>
                                            )
                                        }
                                    </select>
                                </div>
                                {perm_edit_equipment_user === 1 ?
                                    <div className="col-lg-1 col-md-1 col-sm-1 pt-30 ">
                                        <div className="col-xl-1 mt-5">
                                            <p id="add" onClick={() => {
                                                setSpinView(true);
                                                if (equipAdd !== "") {
                                                    actionToList('add');
                                                } else {
                                                    setSpinView(false);
                                                }
                                            }
                                            } className="btn btn-light border fs-13 pl-3 pr-2 pt-3 pb-3 mt-3" title="add a new user" >
                                                {spinView ?
                                                    <span className="spinner-border spinner-border-sm mr-2 text-success" role="status" aria-hidden="true" disabled></span>
                                                    :
                                                    <FcPrevious size={20} />}
                                            </p>

                                            <p onClick={() => {
                                                if (equipRemove !== "") {
                                                    actionToList('update');
                                                }
                                            }} id="empty-tab" className="btn btn-light border fs-13 pl-3 pr-2 pt-3 pb-3 mt-3" title="remove a user" >

                                                {spinView1 ?
                                                    <span className="spinner-border spinner-border-sm mr-2 text-success" role="status" aria-hidden="true" disabled></span>
                                                    :
                                                    <FcNext size={20} />
                                                }
                                            </p>
                                        </div>
                                    </div>
                                    : <></>
                                }
                                <div className="col-lg-6 col-md-6 col-sm-5 ml-5 ">
                                    <div className="border-bottom fs-14 font-weight-bolder mb-5 pb-4 pt-2 text-center">NEW EQUIPMENTS ({listNoEquipment.length})</div>
                                    <div className="font-weight-bolder mb-2  ">
                                        <input type="text" className="form-control form-control-solid  rounded-2 border " onChange={(e) => { filterNewEquipment(e.target.value) /*filterList(e.target.value)*/ }} placeholder="Filter equipment" />
                                    </div>
                                    <select id="materials-list" className="form-control rounded-0" onChange={(e) => { setEquipAdd(e.target.value) /*setUserAddId(e.target.value)*/ }} size="12">
                                        {listNoEquipment.length == 0 ?
                                            <option key={-1} value="">No user For this Equipment</option>
                                            :
                                            listNoEquipment ? listNoEquipment.map((d, index) => (
                                                <option value={d.value} key={index}>{d.value}</option>
                                            )) : (
                                                <></>
                                            )
                                        }
                                    </select>
                                </div>
                            </div>
                        </div>
                        :
                        <div className="row">
                            <div className="col-lg-5 border-right">
                                <div className="border-bottom fs-14 font-weight-bolder mb-3 pb-2 d-flex text-center">
                                    <div className="pt-2 fs-20 text-center">
                                        ACCESS TO PAGE
                                    </div>
                                </div>

                                <div className={actualPage === 'PREVENTIVE INTERVENTION' ? "form-group mb-3 d-flex bg-light2 pl-3 pr-3 pt-2 thumbnail1 choosen1" : "form-group mb-3 d-flex bg-light2 pl-3 pr-3 pt-2 thumbnail1"} onClick={() => { filterListPermission('PREVENTIVE INTERVENTION') }}>
                                    <label className="font-weight-bolder col-lg-10 col-md-10 col-sm-10 col-10 cursor-pointer">PREVENTIVE INTERVENTION  </label>

                                    <FcNext size={20} className='col-lg-2 col-md-2 col-sm-2 col-2 cursor-pointer' />
                                </div>
                                <div className={actualPage === 'EQUIPMENTS' ? "form-group mb-3 d-flex bg-light2 pl-3 pr-3 pt-2 thumbnail1 choosen1" : "form-group mb-3 d-flex bg-light2 pl-3 pr-3 pt-2 thumbnail1"}>
                                    <label className="font-weight-bolder col-lg-10 col-md-10 col-sm-10 col-10 cursor-pointer" onClick={() => { filterListPermission('EQUIPMENTS') }} >EQUIPMENTS  </label>

                                    <FcNext size={20} className='col-lg-2 col-md-2 col-sm-2 col-2 cursor-pointer' onClick={() => { filterListPermission('EQUIPMENTS') }} />
                                </div>
                                <div className={actualPage === 'MATERIALS' ? "form-group mb-3 d-flex bg-light2 pl-3 pr-3 pt-2 thumbnail1 choosen1" : "form-group mb-3 d-flex bg-light2 pl-3 pr-3 pt-2 thumbnail1"}>
                                    <label className="font-weight-bolder col-lg-10 col-md-10 col-sm-10 col-10 cursor-pointer" onClick={() => { filterListPermission('MATERIALS') }} >MATERIALS  </label>

                                    <FcNext size={20} className='col-lg-2 col-md-2 col-sm-2 col-2 cursor-pointer' onClick={() => { filterListPermission('MATERIALS') }} />
                                </div>
                                <div className={actualPage === 'AFFECTATION SITES' ? "form-group mb-3 d-flex bg-light2 pl-3 pr-3 pt-2 thumbnail1 choosen1" : "form-group mb-3 d-flex bg-light2 pl-3 pr-3 pt-2 thumbnail1"}>
                                    <label className="font-weight-bolder col-lg-10 col-md-10 col-sm-10 col-10 cursor-pointer" onClick={() => { filterListPermission('AFFECTATION SITES') }} >AFFECTATION AFFECTATION SITES </label>

                                    <FcNext size={20} className='col-lg-2 col-md-2 col-sm-2 col-2 cursor-pointer' onClick={() => { filterListPermission('sites') }} />
                                </div>
                                <div className={actualPage === 'USERS' ? "form-group mb-3 d-flex bg-light2 pl-3 pr-3 pt-2 thumbnail1 choosen1" : "form-group mb-3 d-flex bg-light2 pl-3 pr-3 pt-2 thumbnail1"}>
                                    <label className="font-weight-bolder col-lg-10 col-md-10 col-sm-10 col-10 cursor-pointer" onClick={() => { filterListPermission('USERS') }}>USERS </label>

                                    <FcNext size={20} className='col-lg-2 col-md-2 col-sm-2 col-2 cursor-pointer' onClick={() => { filterListPermission('USERS') }} />
                                </div>
                            </div>

                            <div className="col-lg-7" >
                                <div className="border-bottom fs-14 font-weight-bolder pb-4 text-center mb-3 ">PERMISSIONS FOR PAGE {actualPage}</div>
                                <div style={{

                                    height: '300px',
                                    overflow: 'auto',

                                }}>
                                    {listPermissionOfUser.map((d, index) => {
                                        if (limitPerm1 === 214) {
                                            if (d.usrper_per_id >= limitPerm1 && d.usrper_per_id <= limitPerm2 || d.usrper_per_id >= 240 && d.usrper_per_id <= 242) {
                                                return (
                                                    <div className="form-group mb-3 d-flex bg-light2 pl-3 pr-3 pt-2" key={index}>
                                                        <label className="font-weight-bolder col-lg-9 col-md-9 col-sm-9 col-9" htmlFor={d.usrper_per_id}>{d.per_name} </label>
                                                        <input type='checkbox' id={d.usrper_per_id} className="col-lg-1 col-md-1 col-sm-1 col-1" checked={d.usrper_val} onChange={(e) => { modifyListPermission(d.usrper_id, e.target.checked) }} />
                                                    </div>
                                                );
                                            }
                                        } else {
                                            if (d.usrper_per_id >= limitPerm1 && d.usrper_per_id <= limitPerm2) {
                                                return (
                                                    <div className="form-group mb-3 d-flex bg-light2 pl-3 pr-3 pt-2" key={index}>
                                                        <label className="font-weight-bolder col-lg-9 col-md-9 col-sm-9 col-9" htmlFor={d.usrper_per_id}>{d.per_name} </label>
                                                        <input type='checkbox' id={d.usrper_per_id} className="col-lg-1 col-md-1 col-sm-1 col-1" checked={d.usrper_val} onChange={(e) => { modifyListPermission(d.usrper_id, e.target.checked) }} />
                                                    </div>
                                                );
                                            }
                                        }


                                    })}
                                </div>

                            </div>
                        </div>
                    }


                </Modal.Body>
                <Modal.Footer>
                    {
                        listEquipmentForUser.length > 0 && !permissionView ?
                            <Dropdown>
                                <Dropdown.Toggle
                                    variant="dropdown-basic"
                                    className="h-40px pt-0 pb-0 "
                                >
                                    <span className="text-black text-dark-75 opacity-90 font-weight-bolder font-size-base d-none d-md-inline mr-4">
                                        <FcPrint size={20} /> PRINT
                                    </span>
                                </Dropdown.Toggle>

                                <Dropdown.Menu className="w-150px">

                                    <Dropdown.Item onClick={() => { setSupportForm("print-container"); handlePrint(); setSupportForm("") }} >
                                        <div className="text-dark-75 m-0 flex-grow-1 mr-3 fs-12 font-weight-bolder w-200px">
                                            <BsCardChecklist className="text-success" size={20} /> CHECK LIST
                                        </div>
                                    </Dropdown.Item>
                                    {listDetailIntervention.length > 0 ?
                                        <Dropdown.Item onClick={() => { setSupportForm1("print-container"); handlePrint1(); setSupportForm1("") }} >
                                            <div className="text-dark-75 ml-2 m-0 flex-grow-1 mr-3 fs-12 font-weight-bolder w-200px">
                                                <FcSupport size={20} /> INTERVENTIONS({listDetailIntervention.length})
                                            </div>
                                        </Dropdown.Item>
                                        : ""}
                                </Dropdown.Menu>
                            </Dropdown>
                            : null
                    }
                    {permissionView ?
                        spinView ?
                            <button className="btn btn-info" type="button" disabled>
                                <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                                Updating  ...
                            </button>
                            :
                            <button type="button" className="btn btn-info" onClick={() => { updatePermissions(listUserChoosed['user_id']); setSpinView(true); }}>Update</button>

                        :
                        <></>
                    }
                    <button type="button" className="btn btn-danger" onClick={handleTypeClose}>Close</button>
                </Modal.Footer>
            </Modal>

            <Modal show={modalFilter} onHide={() => { setModalFilter(false) }} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Filter</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form>
                        <div className="form-group mb-4 mt-3">
                            <label className="font-weight-bold">
                                {
                                    filterType === 1 ? (
                                        "Full name"
                                    ) : filterType === 2 ? (
                                        "User name"
                                    ) : filterType === 3 ? (
                                        "Email"
                                    ) : ""
                                }
                                <span className="text-danger pl-3">*</span>
                            </label>
                            <input
                                id="eq-subject"
                                onChange={(e) => {
                                    setFilterText(e.target.value);
                                }}
                                name="subject"
                                className="form-control rounded-0"
                            />
                            <div id="ticketprio-error" className="text-danger pt-2">

                            </div>
                        </div>

                        <button ref={null} hidden className="btn">
                            submit
                        </button>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-secondary" onClick={handleTypeClose}>Close</button>
                    <button type="button" className="btn btn-success" onClick={filtering}>Add filter</button>
                </Modal.Footer>
            </Modal>

            <Modal show={modalFilterLog} onHide={() => { setModalFilterLog(false) }} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Filter</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form>
                        <div className="form-group mb-4 mt-3">
                            <label className="font-weight-bold">
                                {
                                    filterType === 4 ? (
                                        "Filter Log by Date"
                                    ) : filterType === 5 ? (
                                        "Filter Log by Title"
                                    ) : ""
                                }
                                <span className="text-danger pl-3">*</span>
                            </label>
                            {filterType === 4 ?
                                <input
                                    id="eq-subject"
                                    type='date'
                                    onChange={(e) => {
                                        setFilterDate(e.target.value);
                                    }}
                                    name="subject"
                                    className="form-control rounded-0"
                                />
                                :
                                <input
                                    id="eq-subject"
                                    onChange={(e) => {
                                        setFilterTitle(e.target.value);
                                    }}
                                    name="subject"
                                    className="form-control rounded-0"
                                />}
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-secondary" onClick={() => setModalFilterLog(false)}>Close</button>
                    <button type="button" className="btn btn-success" onClick={filtering}>Add filter</button>
                </Modal.Footer>
            </Modal>

            <Modal show={modalConfirmChangeStatus} onHide={() => { setModalConfirmChangeStatus(false) }} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Confirmation</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure ?
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-secondary" onClick={handleTypeClose}>Close</button>
                    <button type="button" className="btn btn-success" onClick={confirmChangeStatus}>Confirm</button>
                </Modal.Footer>
            </Modal>

            <Modal show={domainConfirmation} onHide={() => { setDomainConfirmation(false) }} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Confirmation</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {domainAdd ?
                        <div className="col-md-6 col-lg-6 col-xl-6">
                            <label className="font-weight-bold">
                                Name on the domain<span className="text-danger pl-3">*</span>
                            </label>
                            <input
                                id="ticket-subject"
                                name="subject"
                                className="form-control rounded-0"
                                onChange={(e) => {
                                    setDomainName(e.target.value);
                                }}
                            />
                            <div id="ticketprio-error" className="text-danger pt-2">
                                {message_domainName}
                            </div>
                        </div>
                        :
                        "Are you suress ?"
                    }

                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-secondary" onClick={handleTypeClose}>Close</button>
                    <button type="button" className="btn btn-success" onClick={() => { domainAction() }}>UPDATE</button>
                </Modal.Footer>
            </Modal>

            <Modal show={modalLogs} onHide={() => { setModalLogs(false) }} size={"lg"} centered>
                <Modal.Header closeButton>
                    <Modal.Title className="row">
                        <div className="">
                            Logs ({listLogs.length} résultat(s))
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div
                        className="d-flex flex-column flex-column-fluid "
                        style={{ minHeight: "85vh", backgroundColor: "#eef0f8" }}
                    >
                        <div className="row">
                            <div className="mt-1 text-left col-lg-6 col-md-6 col-sm-6 col-6">
                                {listActualFilter.map((e) => {
                                    return (
                                        <>
                                            <div className="btn btn-success p-2 ml-2" >{e.val} <GrFormClose className="thumbnail1 ml-2" onClick={() => { removeFilter(e.type) }} /></div>

                                        </>

                                    )

                                })}
                            </div>

                            <div className="mt-1 text-right col-lg-6 col-md-6 col-sm-6 col-6">
                                <div className="btn-group">
                                    <button type="button" className="btn btn-info pt-0 pb-0" onClick={() => { setListLogs(listLogs1); setListActualFilter([]); setFilterDate(''); setFilterTitle('') }}>Remove filter</button>
                                    <Dropdown>
                                        <Dropdown.Toggle
                                            variant="dropdown-basic"
                                            className=" pt-0 pb-0 "
                                        >
                                            <button type="button" className="btn btn-danger ml-5">Add filter</button>
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu className="w-50px ml-5">
                                            <Dropdown.Item onClick={() => { setModalFilterLog(true); setFilterType(4); }}>
                                                <div className="text-dark-75 m-0 flex-grow-1 mr-3 fs-12 font-weight-bolder w-200px">
                                                    By Date
                                                </div>
                                            </Dropdown.Item>
                                            <Dropdown.Item onClick={() => { setModalFilterLog(true); setFilterType(5); }}>
                                                <div className="text-dark-75 m-0 flex-grow-1 mr-3 fs-12 font-weight-bolder w-200px">
                                                    By Title
                                                </div>
                                            </Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </div>
                        </div>
                        <div className="h-1000px mt-2 overflow-auto">
                            {
                                listLogs ? (
                                    listLogs.map((d, index) => (
                                        <Accordion defaultActiveKey="0" className="pb-2" key={index}>
                                            <Accordion.Item eventKey={index}>
                                                <Accordion.Header>
                                                    <div className="">
                                                        <div className="d-flex ">

                                                            <label>{d.pilogs_title}</label>
                                                        </div>
                                                        <div className="">
                                                            {moment(d.pilogs_date).format('YYYY-MM-DD HH:mm')}
                                                        </div>
                                                    </div>
                                                </Accordion.Header>
                                                <Accordion.Body className="text-left">
                                                    <div>
                                                        {d.pilogs_description}
                                                    </div>
                                                </Accordion.Body>
                                            </Accordion.Item>

                                        </Accordion>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan={11} className="text-center pt-20 fs-16">
                                            No data found
                                        </td>
                                    </tr>
                                )
                            }

                        </div>
                    </div >
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-secondary" onClick={() => { setModalLogs(false) }}>Close</button>
                </Modal.Footer>
            </Modal>

            <div
                className="d-flex flex-column flex-column-fluid "
                style={{ minHeight: "85vh", backgroundColor: "#eef0f8" }}

            >
                <div className="col-xl-12 center-block " align="center" style={{ backgroundColor: "#eef0f8" }} >
                    <div className="col-xl-6 bg-white mt-50 center-block min-h-650px" align="center" >
                        <div className="pt-10 w-100 h-100px" style={{ marginLeft: "0.8rem" }}>
                            <div className=" ml-10 float-left pt-2 pr-3">
                                <label className="fs-20 "> List of user</label>
                            </div>
                            <div className="col text-right">
                                {windowSize.innerWidth < 768 ?
                                    <>
                                        {showInactive ?
                                            <FiUserPlus size={40} className="btn btn-light ml-2 cursor-pointer border" onClick={() => { setShowInactive(false); filterActive(); }} />
                                            :
                                            <FiUserMinus size={40} className="btn btn-light ml-2 cursor-pointer border" onClick={() => { setShowInactive(true); setListUser(listUser1) }} />
                                        }

                                        <FcClearFilters size={40} className="btn btn-light ml-2 cursor-pointer border" onClick={() => { setShowInactive(false); filterActive() }} />
                                        {perm_add_user === 1 ?

                                            <AiOutlinePlusCircle size={40} className="cursor-pointer text-danger border ml-2" />
                                            : <></>}
                                    </>
                                    :
                                    <>

                                        {showInactive ?
                                            <button className="btn btn-success mr-1" onClick={() => { setShowInactive(false); filterActive() }}>Show active</button>
                                            :
                                            <button className="btn btn-success" onClick={() => { setShowInactive(true); setListUser(listUser1) }}>Show inactive</button>
                                        }
                                        <button className="btn btn-light" onClick={() => { setShowInactive(false); filterActive(); setListUser(listUser1) }}>Supprimer le filtre</button>
                                        {perm_add_user === 1 ?
                                            <button className="btn btn-danger ml-2">Ajouter un user</button>
                                            :
                                            <></>
                                        }
                                    </>
                                }
                            </div>
                        </div>

                        <div className="h-450px overflow-auto">
                            <table id="customers max-h-300px" className="table">
                                <thead className="position-fixe" >
                                    <tr >
                                        <th scope="col" className="w-25px"></th>
                                        <th scope="col" className="w-25px">
                                            #
                                        </th>

                                        <th scope="col" className="text-left">
                                            Username
                                            <a
                                                href="#"
                                                data-toggle="modal"
                                                data-target="#ticketidModal"
                                            >
                                                <i className="la la-filter" onClick={() => { setModalFilter(true); setFilterType(2); setListUser(listUser1); setFilterText(""); }}></i>
                                            </a>
                                        </th>

                                        <th scope="col" className="text-left">
                                            Full name
                                            <a
                                                href="#"
                                                data-toggle="modal"
                                                data-target="#ticketidModal"
                                            >
                                                <i className="la la-filter" onClick={() => { setModalFilter(true); setFilterType(1); setListUser(listUser1); setFilterText(""); }}></i>
                                            </a>
                                        </th>



                                        <th scope="col" className="text-left">
                                            User email
                                            <a
                                                href="#"
                                                data-toggle="modal"
                                                data-target="#priorityModal"
                                            >
                                                <i className="la la-filter" onClick={() => { setModalFilter(true); setFilterType(3); setListUser(listUser1); setFilterText(""); }}></i>
                                            </a>
                                        </th>

                                        <th scope="col" className="text-left">
                                            Active
                                        </th>
                                    </tr>
                                </thead>
                                <tbody id="resultdata" >
                                    {
                                        currentPosts ? (
                                            currentPosts.map((d, index) => (
                                                <tr
                                                    className=""
                                                    key={d.user_id}
                                                >
                                                    <td>
                                                        <Dropdown>
                                                            <Dropdown.Toggle
                                                                variant="dropdown-basic"
                                                                className="w-60px py-0 text-white p-0 m-0"
                                                            >
                                                                <div
                                                                    className="dropdown dropdown-inline bg-light2 py-0 rounded"
                                                                    data-toggle="dropdown"

                                                                >
                                                                    <a

                                                                        type="button"
                                                                        className="btn btn-light2 btn-icon py-0 btn-sm"
                                                                    >
                                                                        <i className="ki ki-bold-more-hor"></i>
                                                                    </a>
                                                                </div>
                                                            </Dropdown.Toggle>

                                                            <Dropdown.Menu className="">

                                                                {d.user_id !== 1 && perm_status_user === 1 ?
                                                                    <Dropdown.Item
                                                                        href="#"
                                                                        className="dropdown-item border-bottom border-top"
                                                                        onClick={() => { setUserCode(d.user_id); setUserStatus(d.user_status); setModalConfirmChangeStatus(true) }}
                                                                    >
                                                                        {d.user_status === 0 ? <><FcPlus size={20} /> <span className="pl-3">Active</span></> : <><FcMinus size={20} /> <span className="pl-3">Desactive</span></>}
                                                                    </Dropdown.Item> : ""
                                                                }

                                                                {d.user_id !== 1 && perm_edit_user === 1 ?
                                                                    d.user_OnDomain === 1 ?
                                                                        <Dropdown.Item
                                                                            href="#"
                                                                            className="dropdown-item border-bottom border-top"
                                                                            onClick={() => { setDomainAdd(false); setDomainConfirmation(true); setUserCode(d.user_id); }}
                                                                        >
                                                                            <FcMinus size={20} />
                                                                            <span className="pl-3">Remove from domain</span>
                                                                        </Dropdown.Item>

                                                                        :
                                                                        <Dropdown.Item
                                                                            href="#"
                                                                            className="dropdown-item border-bottom border-top"
                                                                            onClick={() => { setDomainAdd(true); setDomainConfirmation(true); setUserCode(d.user_id); }}
                                                                        >
                                                                            <FcPlus size={20} />
                                                                            <span className="pl-3">Add to domain</span>
                                                                        </Dropdown.Item>
                                                                    : null
                                                                }
                                                                {perm_view_logs === 1 ?
                                                                    <Dropdown.Item
                                                                        href="#"
                                                                        className="dropdown-item border-bottom border-top"
                                                                        onClick={() => { handlePrepareLogs(d.user_id); setModalLogs(true); }}
                                                                    >
                                                                        <FaHistory size={20} />
                                                                        <span className="pl-3">Logs </span>
                                                                    </Dropdown.Item>
                                                                    : <></>
                                                                }

                                                            </Dropdown.Menu>


                                                        </Dropdown>
                                                    </td>


                                                    <td id="tb-assigned-to" className="text-left">
                                                        {d.user_id}
                                                    </td>
                                                    <td id="tb-created-date" className="text-left hover_underlined cursor-pointer " onClick={() => { setIndexSpin(index); setSpinView(true); handleViews(d.user_id) }}>
                                                        {d.user_name} <span className="fs-10">({d.nbre_equip})</span>

                                                        {indexSpin === index && spinView ?
                                                            <span className="spinner-border spinner-border-sm mr-2 text-success" role="status" aria-hidden="true" disabled></span>
                                                            :
                                                            <></>}
                                                    </td>
                                                    <td id="tb-assigned-to" className="text-left">
                                                        {d.user_full_name}
                                                    </td>

                                                    <td id="tb-created-date" className="text-left">
                                                        {d.user_email}
                                                    </td>

                                                    <td className="text-center">
                                                        {d.user_status === 1 ? <HiCheckCircle size={25} className="text-success" /> : <HiXCircle size={25} className="text-danger" />}
                                                    </td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan={11} className="text-center pt-20 fs-16">
                                                    No data found
                                                </td>
                                            </tr>
                                        )
                                    }

                                </tbody>
                            </table>
                        </div>
                        <div className="d-flex-lg card-footer pt-2 pb-2 col-12 mb-0 bg-white ">
                            <div className=" d-flex col-sm-12">
                                <select className="page-range" onChange={(e) => { setlinePerPage(e.target.value); setCurrentPage(1) }}>
                                    <option value={10}>10</option>
                                    <option value={25}>25</option>
                                    <option value={50}>50</option>
                                    <option value={listUser.length}>All</option>
                                </select>
                                <div className="mt-6"> </div>
                                <div className="mt-3"><div id="resultfound">{listUser.length} résultat(s)</div> </div>
                            </div>
                            <div className=" col-sm-12">
                                <div className="d-flex align-items-center justify-content-center">

                                    <Pagination
                                        postsPerPage={linePerPage}
                                        totalPosts={listUser.length}
                                        paginate={paginate}
                                    />
                                </div>
                            </div>
                            <div className="col-xl-4">

                            </div>

                        </div>
                    </div>

                </div>

            </div >

            <div hidden>
                <div className={supportForm} ref={componentRef} id="checkList" style={{ width: '90%', height: window.innerHeight, marginLeft: '5%', marginTop: '7%' }}>
                    <div className="card-body">
                        <div className="">
                            <table id="" className="table  ">
                                <tbody id="">
                                    <tr className="border">
                                        <td rowSpan="2" colSpan="5" className="border border-top">
                                            <div className="d-flex">
                                                <div className="w-150px col-md-2">
                                                    <img
                                                        alt="Logo"
                                                        src={logo}
                                                        className="logo-default max-h-80px"
                                                    />
                                                </div>
                                                <div className="col-md-10 text-center font-weight-bolder fs-24 pt-6 border-left-0 ">
                                                    CHECK LIST
                                                </div>
                                            </div>
                                        </td>
                                        <td className="text-center fs-16 w-200px">Code : FR23-S3 </td>
                                    </tr>
                                    <tr>
                                        <td className="text-center border fs-16">Date de mise en application: 01-03-2023</td>
                                    </tr>
                                    <tr>
                                        <td colSpan="" className="border-left w-10"></td>
                                        <td colSpan="4" className="fs-16 text-right">
                                            Référence de la fiche<span></span><br />
                                        </td>
                                        <td colSpan="1" id="pi-ref" className="border border-left-0  fs-16" >EQ-IT-{listUserChoosed['user_id']}</td>
                                    </tr>

                                    <tr className="text-center bg-light border">
                                        <td colSpan="6" className="table-active text-center font-weight-bolder border fs-16">Détails</td>
                                    </tr>

                                    <tr>
                                        <td className=" text-left border font-weight-bolder fs-16 w-100px ">Utilisateur</td>
                                        <td colSpan="5" id="eq-user" className="text-left border fs-16 ">{listUserChoosed['user_full_name'] == "" ? listUserChoosed['user_name'] : listUserChoosed['user_full_name']}</td>
                                    </tr>
                                    <tr>
                                        <td className=" text-left border font-weight-bolder fs-16 w-200px">Email</td>
                                        <td colSpan="5" id="eq-user" className="text-left border fs-16 ">{listUserChoosed['user_email']}</td>
                                    </tr>
                                    <tr>
                                        <td className="text-left border font-weight-bolder fs-16">CITY  </td>
                                        <td colSpan="5" id="pi-support" className="text-left border fs-16">{listUserChoosed['user_city']}</td>
                                    </tr>
                                    {listUserChoosed['user_OnDomain'] === 1 ?
                                        <tr>
                                            <td className="text-left border font-weight-bolder fs-16">ON DOMAIN  </td>
                                            <td colSpan="5" id="pi-support" className="text-left border fs-16">YES - {listUserChoosed['user_domain_name']}</td>
                                        </tr>
                                        :
                                        <tr>
                                            <td className="text-left border font-weight-bolder fs-16">ON DOMAIN  </td>
                                            <td colSpan="5" id="pi-support" className="text-left border fs-16">NO</td>
                                        </tr>
                                    }
                                    <tr>
                                        <td className=" text-left border font-weight-bolder fs-16">Date et heure de l'impression </td>
                                        <td colSpan="5" id="pi-date" className="text-left border fs-16">{moment().format('MMMM Do YYYY, h:mm:ss a')}</td>
                                    </tr>


                                    <tr className="text-center bg-light border ">
                                        <td colSpan="6" className="table-active text-center font-weight-bolder border fs-16">EQUIPMENT</td>
                                    </tr>


                                    <tr className="text-left ">
                                        <td colSpan="1" className=" border font-weight-bolder fs-16 h-20px" >Equipment code </td>
                                        <td colSpan="1" className="border font-weight-bolder fs-16">Designation</td>
                                        <td colSpan="1" className="border font-weight-bolder fs-16">Affectation site</td>
                                        <td colSpan="1" className=" border font-weight-bolder fs-16 h-20px text-center"> Sticker </td>
                                        <td colSpan="1" className="border font-weight-bolder fs-16 text-center">Check</td>
                                        <td colSpan="1" className="border font-weight-bolder fs-16 w-300px">Remarque</td>
                                    </tr>

                                    {listEquipmentForUser.map((e, index) => {
                                        return (
                                            <tr className="text-left" key={index}>
                                                {/* <td colSpan="1" className="border fs-16 h-20px text-center" ><input type="checkbox" className="w-20px h-30px" />  </td> */}
                                                <td colSpan="1" className="align-middle border fs-16 h-20px" >{e.eq_code} </td>
                                                <td colSpan="1" className="align-middle border fs-16">{e.eq_designation}</td>
                                                <td colSpan="1" className="align-middle border fs-16">{e.site_name}</td>
                                                <td colSpan="1" className="align-middle border fs-16 text-center"><input type="checkbox" className="w-20px h-30px" /> </td>
                                                <td colSpan="1" className="align-middle border fs-16 text-center"><input type="checkbox" className="w-20px h-30px" /> </td>
                                                <td colSpan="1" className="align-middle border fs-16 h-80px"></td>
                                            </tr>
                                        );

                                    })}

                                    <tr>
                                        <td colSpan="1" className="border font-weight-bolder fs-16 h-110px" >Observations</td>
                                        <td colSpan="5" id="pi-remark" className="text-left border fs-16"></td>
                                    </tr>

                                    <tr>
                                        <td colSpan="1" rowSpan="2" className="border font-weight-bolder fs-16" >Réalisateur/Technicien</td>
                                        <td colSpan="5" className="text-center border font-weight-bolder fs-16">Nom et Signature :</td>
                                    </tr>
                                    <tr>
                                        <td colSpan="5" className="h-80px border fs-16"></td>
                                    </tr>
                                    <tr className="border">
                                        <td rowSpan="2" className="border font-weight-bolder fs-16" >Utilisateur</td>
                                        <td colSpan="6" className=" text-center border font-weight-bolder fs-16">Nom et Signature :</td>
                                    </tr>
                                    <tr >
                                        <td colSpan="6" className="h-80px border fs-16"></td>
                                    </tr>

                                    <tr className="  border">
                                        <td rowSpan="3" className="border font-weight-bolder fs-16" >Responsable IT</td>
                                        <td colSpan="5" className=" text-center border font-weight-bolder fs-16">Nom et Signature :</td>
                                    </tr>
                                    <tr >
                                        <td colSpan="5" className="h-80px border fs-16"></td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>


            <div hidden>

                <div className={supportForm1} ref={componentRef1} id="detail-int" style={listDetailIntervention.length > 1 ? { pageBreakAfter: 'always' } : {}}>
                    {listDetailIntervention.map((d, index) => {
                        return (
                            <div className="card-body" key={index} style={{ width: '90%', height: window.innerHeight, marginLeft: '5%', marginTop: '5%', pageBreakAfter: 'always' }}>
                                <div className="">
                                    <table id="" className="table">
                                        <tbody id="">
                                            <tr className>
                                                <td rowSpan="3" colSpan="2" className="border">
                                                    <div className="d-flex">
                                                        <div className="w-150px">
                                                            <img
                                                                alt="Logo"
                                                                src={logo}
                                                                className="logo-default max-h-80px"
                                                            />
                                                        </div>
                                                        <div className="text-center font-weight-bolder fs-24 pt-6 border-left-0 pl-10">
                                                            FICHE D'INTERVENTION PREVENTIVE
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="text-center border fs-16">Code : FR23-S3 </td>
                                            </tr>
                                            <tr>
                                                <td className="text-center border fs-16">Version : 001</td>
                                            </tr>
                                            <tr>
                                                <td className="text-center border fs-16">Date de mise en application: 12-01-2022</td>
                                            </tr>
                                            <tr>
                                                <td className="border border-right-0"></td>
                                                <td className="border border-left-0 fs-16 text-right">
                                                    Référence de la fiche : <span></span><br />
                                                </td>
                                                <td id="pi-ref" className="border border-left-0  fs-16" >{d.pi_reference}</td>
                                            </tr>

                                            <tr className="text-center bg-light border">
                                                <td colSpan="3" className="text-center font-weight-bolder border fs-16">Détails</td>
                                            </tr>
                                            <tr>
                                                <td className=" border font-weight-bolder fs-16">Code de l'infrastructure </td>
                                                <td colSpan="2" id="pi-code-inf" className=" border fs-16 ">{d.eq_code}</td>
                                            </tr>
                                            <tr>
                                                <td className=" border font-weight-bolder fs-16">Désignation de l'infrastructure </td>
                                                <td colSpan="2" id="eq-designation" className=" border fs-16">{d.eq_designation}</td>
                                            </tr>
                                            <tr>
                                                <td className=" border font-weight-bolder fs-16">Etat/Age de l'infrastructure </td>
                                                <td colSpan="2" id="eq-age" className=" border fs-16 ">{d.pi_material_age === "NaN jours" ? "" : d.pi_material_age} </td>
                                            </tr>
                                            <tr>
                                                <td className=" border font-weight-bolder fs-16">Utilisateur</td>
                                                <td colSpan="2" id="eq-user" className=" border fs-16 ">{d.eqUser}</td>
                                            </tr>
                                            <tr>
                                                <td className=" border font-weight-bolder fs-16">Intervenant/Technicien </td>
                                                <td colSpan="2" id="pi-support" className="border fs-16">{d.user_name}</td>
                                            </tr>
                                            <tr>
                                                <td className=" border font-weight-bolder fs-16">Référence des modes opératoires</td>
                                                <td colSpan="2" id="operatoire-mode" className="border fs-16 "></td>
                                            </tr>
                                            <tr>
                                                <td className=" border font-weight-bolder fs-16">Lieu d'intervention </td>
                                                <td colSpan="2" id="pi-site" className=" border fs-16 ">{d.site_name}</td>
                                            </tr>
                                            <tr>
                                                <td className=" border font-weight-bolder fs-16">Date et heure du debut l'intervention </td>
                                                <td colSpan="2" id="pi-date" className="border fs-16">{moment(d.pi_start_time).format("YYYY-MM-DD HH:mm")}</td>
                                            </tr>


                                            <tr className="text-center bg-light border ">
                                                <td colSpan="3" className=" text-center font-weight-bolder border fs-16">Travaux effectué</td>
                                            </tr>


                                            <tr>
                                                <td className=" border font-weight-bolder fs-16 h-60px" >Description des travaux effectués </td>
                                                <td colSpan="2" id="pi-work_done" className="border fs-16">{listDetailIntervention[0].pi_description_work_done}</td>
                                            </tr>


                                            <tr className=" border ">
                                                <td className="font-weight-bolder  border fs-16" rowSpan={listDetailRequiredMaterial.length + 1} >Pieces et Matériels requis  </td>
                                                <td className="text-center font-weight-bolder bg-light border fs-16">Référence </td>
                                                <td className="text-center font-weight-bolder bg-light border fs-16">Quantité </td>
                                            </tr>

                                            {listDetailRequiredMaterial.map((f, index) => {
                                                return (
                                                    d.pi_reference === f.pirm_intervention_id ?
                                                        <tr className=" border " key={index}>
                                                            <td id="rm0" className="border fs-16">{f.pirm_ref}</td>
                                                            <td id="rmv0" className="border fs-16 text-center">{f.pirm_quantity}</td>
                                                        </tr>
                                                        : <tr></tr>
                                                )
                                            }

                                            )}

                                            <tr className=" border ">
                                                <td className="font-weight-bolder  border fs-16" rowSpan={listDetailRequiredMaterial.length + 1} >Pieces et Matériels utilisés  </td>
                                                <td className="text-center font-weight-bolder bg-light border fs-16">Référence </td>
                                                <td className="text-center font-weight-bolder bg-light border fs-16">Quantité </td>
                                            </tr>

                                            {listDetailRequiredMaterial.map((f, index) => {
                                                return (
                                                    d.pi_reference === f.pirm_intervention_id
                                                        ? <tr className=" border " key={index}>
                                                            <td id="rm0" className="border fs-16">{f.pirm_ref}</td>
                                                            <td id="rmv0" className="border fs-16 text-center">{f.pirm_quantity}</td>
                                                        </tr>
                                                        : <tr></tr>
                                                )
                                            }

                                            )}

                                            <tr>
                                                <td className=" border font-weight-bolder fs-16">Date et heure de fin de l'intervention  </td>
                                                <td colSpan="2" id="pi-date-end" className="border fs-16">{moment(d.pi_end_time).format("YYYY-MM-DD HH:mm") === "Invalid date" ? ("Not finished") : moment(d.pi_end_time).format("YYYY-MM-DD HH:mm")}</td>
                                            </tr>

                                            <tr>
                                                <td className=" border font-weight-bolder fs-16 h-110px" >Observations</td>
                                                <td colSpan="2" id="pi-remark" className="border fs-16">{d.pi_remarque}</td>
                                            </tr>

                                            <tr>
                                                <td rowSpan="2" className=" border font-weight-bolder fs-16" >Réalisateur/Technicien</td>
                                                <td colSpan="2" className="text-center border font-weight-bolder fs-16">Nom et Signature :</td>
                                            </tr>
                                            <tr>
                                                <td colSpan="2" className="h-80px border fs-16"></td>
                                            </tr>
                                            <tr className="  border">
                                                <td rowSpan="2" className="border font-weight-bolder fs-16" >Utilisateur</td>
                                                <td colSpan="2" className=" text-center border font-weight-bolder fs-16">Nom et Signature :</td>
                                            </tr>
                                            <tr >
                                                <td colSpan="2" className="h-80px border fs-16"></td>
                                            </tr>

                                            <tr className="  border">
                                                <td rowSpan="3" className="border font-weight-bolder fs-16" >Responsable IT</td>
                                                <td colSpan="2" className=" text-center border font-weight-bolder fs-16">Nom et Signature :</td>
                                            </tr>
                                            <tr >
                                                <td colSpan="2" className="h-80px border fs-16"></td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        );

                    })}
                </div>

            </div>
            <Footer />
        </>
    )
}

export default Users;