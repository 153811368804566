import logo from './logo.svg';
import './App.css';
import "./Assets/custom/css/style.bundle.css";
import "./Assets/custom/plugins/global/plugins.bundle.css";
import { ReactSession } from "react-client-session";
import Home from "./component/Home";
import Login from "./component/Login";
import Equipements from "./component/Equipements";
import Authentication from "./Authentication";

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Pintervention from './component/Pintervention';
import Materiels from './component/Materiels';
import AffectationSite from './component/AffectationSite';
import Test from './component/Test';
import Users from './component/Users';
import Notifications from './component/Notifications';
import Logs from './component/Logs';

function App() {
  ReactSession.setStoreType("localStorage");
  return (
    <Router>
      <div className="App">
        <div className="d-flex flex-column flex-root">
          <div className="d-flex flex-row flex-column-fluid page">
            <div
              className="d-flex flex-column flex-row-fluid wrapper"
              id="kt_wrapper"
            >
              <Routes>

                <Route exact path="/" element={<Login />} />
                <Route exact path="/Login" element={<Login />} />
                {/* <Route exact path="/Home" element={<Home />} /> */}


                <Route element={<Authentication />}>
                  <Route exact path="/Home" element={<Home />} />
                  <Route exact path="/Pintervention" element={<Pintervention />} />
                  <Route exact path="/Equipements" element={<Equipements />} />
                  <Route exact path="/Materiels" element={<Materiels />} />
                  <Route exact path="/AffectationSite" element={<AffectationSite />} />
                  <Route exact path="/Users" element={<Users />} />
                  <Route exact path="/Notifications" element={<Notifications />} />
                  <Route exact path="/Logs" element={<Logs />} />
                </Route>

              </Routes>
            </div>
          </div>
        </div>
      </div>
    </Router>
  );
}

export default App;
