import axios from "axios";
import { useEffect, useState, useRef } from "react";
import "../Assets/css/bootstrap.min.css";
import Header from "./header";
import Footer from "./footer";
import Modal from "react-bootstrap/Modal";
import toast from "react-hot-toast";
import baseurl from "./globals";
import Dropdown from "react-bootstrap/Dropdown";
import moment from "moment"
import { ReactSession } from "react-client-session";
import Header_mobile from "./header_mobile";
// import Fiche from "./Fiche";
import logo from "../Assets/img/auto-logo.jpg";
import { useReactToPrint } from "react-to-print";
import { downloadExcel } from "react-export-table-to-excel";
import { FcManager, FcDebian, FcClearFilters } from 'react-icons/fc';
import { SiMicrosoftexcel } from 'react-icons/si';
import { MdOutlineAddModerator } from 'react-icons/md';
import { HiXCircle, HiCheckCircle } from "react-icons/hi";


function Pintervention() {
    const [refresh, setRefresh] = useState(false);
    const [refreshTab, setRefreshTab] = useState(false);
    const [spinView, setSpinView] = useState(false);
    const [modalFilter, setModalFilter] = useState(false);

    const [currentPage, setCurrentPage] = useState(1);
    const [linePerPage, setlinePerPage] = useState(10);

    const [supportForm, setSupportForm] = useState("");

    const [modalCreatIntervention, setModalCreatIntervention] = useState(false);
    const [modalDescription, setModalDescription] = useState(false);
    const [modalAssignTo, setModalAssignTo] = useState(false);
    const [modalCloseIntervention, setModalCloseIntervention] = useState(false);
    const [modalConfirmGenerate, setModalConfirmGenerate] = useState(false);
    const [modalView, setModalView] = useState(false)
    const [modalConfirmDateExport, setModalConfirmDateExport] = useState(false);
    const [signAction, setSignAction] = useState(false);
    const [userAsignCode, setUserAssignCode] = useState(-1);
    const [refSign, setRefSign] = useState("");


    const [mat, setMat] = useState("");

    const [listIntervention, setListIntervention] = useState([]);
    const [listIntervention1, setListIntervention1] = useState([]);
    const [listIntervention2, setListIntervention2] = useState([]);
    const [listEquipement, setListEquipement] = useState([]);
    const [listMaterials, setListMaterials] = useState([]);
    const [listChoosenMaterials, setListChoosenMaterials] = useState([]);
    const [listChoosenMaterials1, setListChoosenMaterials1] = useState([]);
    const [listUserToAssign, setListUserToAssign] = useState([]);
    const [listAssign, setListAssign] = useState([]);


    const [refIntervention, setRefIntervention] = useState("");
    const [desc, setDesc] = useState("");
    const [userAssignTo, setUserAssignTo] = useState("");

    const [equip_code, setEquip_code] = useState(-1);
    const [equip_design, setEquip_design] = useState("");
    const [equip_age, setEquip_age] = useState("");
    const [equip_userName, setEquip_userName] = useState("");
    const [equip_userId, setEquip_userId] = useState(-1);
    const [equip_siteAffect, setEquip_siteAffect] = useState("");
    const [equip_dateInterv, setEquip_dateInterv] = useState("");
    const [workToBeDone, setWorkToBeDone] = useState("");

    const [intReelStartDate, setIntReelStartDate] = useState("");
    const [intReelEndDate, setIntReelEndDate] = useState("");
    const [intObservation, setIntObservation] = useState("");

    const [exportStartDate, setExportStartDate] = useState("");
    const [exportEndDate, setExportEndDate] = useState("");

    const [filterType, setFilterType] = useState("");
    const [filterText, setFilterText] = useState("");




    const [messageCodeEquip, setMessageCodeEquip] = useState("");
    const [messageDateInt, setMessageDateInt] = useState("");
    const [messageWorkToBeDone, setMessageWorkToBeDone] = useState("");
    const [messageAssignTo, setMessageAssignTo] = useState("");

    const [messageReelStartDate, setMessageReelStartDate] = useState("");
    const [messageReelEndDate, setMessageReelEndDate] = useState("");
    const [messageCodeGen, setMessageCodeGen] = useState("");

    const [messageExportStartDate, setMessageExportStartDate] = useState("");
    const [messageExportEndDate, setMessageExportEndDate] = useState("");


    const [perm_view_equipement, setPerm_view_equipement] = useState(-1);
    const [perm_edit_equipement, setPerm_edit_equipement] = useState(-1);
    const [perm_add_equipement, setPerm_add_equipement] = useState(-1);
    const [perm_view_materials, setPerm_view_materials] = useState(-1);
    const [perm_edit_materials, setPerm_edit_materials] = useState(-1);
    const [perm_add_materials, setPerm_add_materials] = useState(-1);
    const [perm_view_affectation_site, setPerm_view_affectation_site] = useState(-1);
    const [perm_edit_affectation_site, setPerm_edit_affectation_site] = useState(-1);
    const [perm_add_affectation_site, setPerm_add_affectation_site] = useState(-1);
    const [perm_create_intervention, setPerm_create_intervention] = useState(-1);
    const [perm_edit_intervention, setPerm_edit_intervention] = useState(-1);
    const [perm_view_intervention, setPerm_view_intervention] = useState(-1);
    const [perm_assign_intervention, setPerm_assign_intervention] = useState(-1);
    const [perm_close_intervention, setPerm_close_intervention] = useState(-1);
    const [perm_edit_assign, setPerm_edit_assign] = useState(-1);
    const [perm_add_signature, setPerm_add_signature] = useState(-1);
    const [perm_cancel_intervention, setPerm_cancel_intervention] = useState(-1);
    const [perm_Report, setPerm_Report] = useState(-1);
    const [perm_List_User, setPerm_List_User] = useState(-1);
    const [perm_add_user, setPerm_add_user] = useState(-1);
    const [perm_edit_user, setPerm_edit_user] = useState(-1);
    const [perm_status_user, setPerm_status_user] = useState(-1);
    const [perm_edit_equipment_user, setPerm_edit_equipment_user] = useState(-1);
    const [perm_history_equipement, setPerm_history_equipement] = useState(-1);
    const [perm_status_equipement, setPerm_status_equipement] = useState(-1);




    const indexOfLastPost = currentPage * linePerPage;
    const indexOfFirstPost = indexOfLastPost - linePerPage;
    const currentIntervention = listIntervention.slice(indexOfFirstPost, indexOfLastPost);
    const header = [
        "#",
        "REFERENCE",
        "EQUIPEMENT CODE",
        "DESIGNATION",
        "USER",
        "DESCRIPTION",
        "STATUT",
        "ENVOYÉ PAR",
        "ASSIGNÉ A",
        "DATE DE CRÉATION",
        "DATE D'INTERVENTION",
    ];

    const [windowSize, setWindowSize] = useState(getWindowSize());

    useEffect(() => {
        handleOnLoad();

        function handleWindowResize() {
            setWindowSize(getWindowSize());
        }

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };

    }, []);

    const handleOnLoad = () => {
        axios.post(baseurl + "?p=getUserPermissions", {
            userId: ReactSession.get('prev_id')
        }).then((response) => {
            (response.data.list).map((e, index) => {
                switch (e['usrper_per_id']) {
                    case 214:
                        setPerm_view_equipement(e['usrper_val']);
                        break;

                    case 215:
                        setPerm_edit_equipement(e['usrper_val']);
                        break;

                    case 216:
                        setPerm_add_equipement(e['usrper_val']);
                        break;

                    case 217:
                        setPerm_view_materials(e['usrper_val']);
                        break;

                    case 218:
                        setPerm_edit_materials(e['usrper_val']);
                        break;

                    case 219:
                        setPerm_add_materials(e['usrper_val']);
                        break;

                    case 223:
                        setPerm_view_affectation_site(e['usrper_val']);
                        break;

                    case 224:
                        setPerm_edit_affectation_site(e['usrper_val']);
                        break;

                    case 225:
                        setPerm_add_affectation_site(e['usrper_val']);
                        break;

                    case 300:
                        setPerm_create_intervention(e['usrper_val']);
                        break;

                    case 301:
                        setPerm_edit_intervention(e['usrper_val']);
                        break;

                    case 302:
                        setPerm_view_intervention(e['usrper_val']);
                        break;

                    case 303:
                        setPerm_assign_intervention(e['usrper_val']);
                        break;

                    case 304:
                        setPerm_close_intervention(e['usrper_val']);
                        break;

                    case 305:
                        setPerm_edit_assign(e['usrper_val']);
                        break;

                    case 306:
                        setPerm_add_signature(e['usrper_val']);
                        break;

                    case 307:
                        setPerm_cancel_intervention(e['usrper_val']);
                        break;

                    case 308:
                        setPerm_Report(e['usrper_val']);
                        break;

                    case 400:
                        setPerm_List_User(e['usrper_val']);
                        break;

                    case 401:
                        setPerm_add_user(e['usrper_val']);
                        break;

                    case 402:
                        setPerm_edit_user(e['usrper_val']);
                        break;

                    case 403:
                        setPerm_status_user(e['usrper_val']);
                        break;

                    case 404:
                        setPerm_edit_equipment_user(e['usrper_val']);
                        break;
                    case 240:
                        setPerm_history_equipement(e['usrper_val']);
                        break;
                    case 241:
                        setPerm_status_equipement(e['usrper_val']);
                        break;

                    default:
                        break;
                }
            })
        }).catch(function (error) {
            console.log("INTERNAL ERROR (handleOnLoad)", error.response.data, moment().format('DD-MM HH:mm'));
        });
    }

    function getWindowSize() {
        const { innerWidth, innerHeight } = window;
        return { innerWidth, innerHeight };
    }

    const notifySuccess = (msg) =>
        toast.success(msg, {
            style: {
                padding: "16px",
                backgroundColor: "rgba(124, 140, 135, 0.70)",
                color: "#FFF",
                maxWidth: "280px",
                textAlign: "left",
                fontSize: "13px",
            },
        });

    const notifyError = (msg) =>
        toast.error(msg, {
            style: {
                padding: "16px",
                backgroundColor: "rgba(124, 140, 135, 0.70)",
                color: "#FFF",
                maxWidth: "280px",
                textAlign: "left",
                fontSize: "13px",
            },
        });

    const Pagination = ({ postsPerPage, totalPosts, paginate }) => {
        var pageNumbers = [];
        var pageslink = 6;
        var startlink = 1;

        var totalPages = Math.ceil(totalPosts / postsPerPage);

        if (currentPage - 2 > 1 && currentPage + 2 <= totalPages) {
            startlink = currentPage - 2;
            pageNumbers = [];
            for (let i = startlink; i <= currentPage + 2; i++) {
                pageNumbers.push(i);
            }
        } else if (currentPage - 2 > 1 && currentPage + 1 <= totalPages) {
            startlink = currentPage - 3;
            pageNumbers = [];
            for (let i = startlink; i <= currentPage + 1; i++) {
                pageNumbers.push(i);
            }
        } else if (currentPage == totalPages) {
            if (currentPage - 5 >= 0) startlink = currentPage - 4;
            else if (currentPage - 4 >= 0) startlink = currentPage - 3;
            else if (currentPage - 3 >= 0) startlink = currentPage - 2;
            else if (currentPage - 2 >= 0) startlink = currentPage - 1;
            pageNumbers = [];
            for (let i = startlink; i <= totalPages; i++) {
                pageNumbers.push(i);
            }
        } else {
            startlink = 1;
            pageNumbers = [];
            if (pageslink > totalPages) pageslink = totalPages;
            for (let i = startlink; i <= pageslink; i++) {
                pageNumbers.push(i);
            }
        }

        return (
            <>
                <nav className="d-flex">
                    <ul className="pagination">
                        <li className="page-item">
                            {
                                <a onClick={() => paginate(1)} href="#" className="">
                                    {"«"}
                                </a>
                            }
                        </li>

                        <li className="page-item">
                            {currentPage == 1 ? (
                                <a href="#" className="" disabled>
                                    {"<"}
                                </a>
                            ) : (
                                <a
                                    onClick={() => paginate(currentPage - 1)}
                                    href="#"
                                    className=""
                                >
                                    {"<"}
                                </a>
                            )}
                        </li>

                        {pageNumbers.map((number) => (
                            <li key={number} className="page-item">
                                {currentPage == number ? (
                                    <a
                                        onClick={() => paginate(number)}
                                        href="#"
                                        className="bg-red"
                                    >
                                        {number}
                                    </a>
                                ) : (
                                    <a onClick={() => paginate(number)} href="#" className="">
                                        {number}
                                    </a>
                                )}
                            </li>
                        ))}

                        <li className="page-item">
                            {currentPage == totalPages ? (
                                <a href="#" className="" disabled>
                                    {">"}
                                </a>
                            ) : (
                                <a
                                    onClick={() => paginate(currentPage + 1)}
                                    href="#"
                                    className=""
                                >
                                    {">"}
                                </a>
                            )}
                        </li>

                        <li className="page-item">
                            {
                                <a onClick={() => paginate(totalPages)} href="#" className="">
                                    {"»"}
                                </a>
                            }
                        </li>
                    </ul>
                </nav>
            </>
        );
    };

    const handleTypeClose = () => {
        setModalCreatIntervention(false);
        setModalDescription(false);
        setModalAssignTo(false);
        setModalCloseIntervention(false);
        setModalConfirmGenerate(false);
        setModalView(false)
        setModalConfirmDateExport(false);
        setSignAction(false);
        setSpinView(false)
        setModalFilter(false);

        setDesc("");
        setUserAssignTo("");
        setRefIntervention("")
        setMessageCodeEquip("");
        setEquip_code(-1);
        setEquip_design("");
        setEquip_age("");
        setEquip_userName("");
        setEquip_userId(-1);
        setEquip_siteAffect("");
        setEquip_dateInterv("");
        setWorkToBeDone("");
        setIntReelStartDate("");
        setIntReelEndDate("");
        setIntObservation("");
        setGenDate("");
        setExportEndDate("");
        setExportStartDate("");
        setRefSign("");


        setMessageAssignTo("");
        setMessageCodeEquip("");
        setMessageDateInt("");
        setMessageReelEndDate("");
        setMessageReelStartDate("");
        setMessageWorkToBeDone("");
        setMessageCodeGen("");
        setMessageExportEndDate("");
        setMessageExportStartDate("");


        setListChoosenMaterials([]);
        setListChoosenMaterials1([]);
        setListEquipement([])


        setRefresh(true);
        setRefreshTab(true);
    };

    const getIntrervention = async () => {
        axios.post(baseurl + "?p=getIntrervention", {
        }).then((response) => {
            if (response.data.status) {
                setListIntervention(response.data.result);
                setListIntervention1(response.data.result);

            } else {
            }
        }).catch(function (error) {
            console.log("INTERNAL ERROR (getIntrervention)", error.response.data, moment().format('DD-MM HH:mm'));
        });
    };

    const creatIntervention = () => {

        if (messageCodeEquip === "" || messageCodeEquip.length > 45) {
            var req = true;
            if (equip_dateInterv === "" || equip_dateInterv === "0000-00-00 00:00:00") { setMessageDateInt("Complete this field"); req = false }
            else setMessageDateInt("");
            if (workToBeDone === "") { setMessageWorkToBeDone("Complete this field"); req = false }
            else setMessageWorkToBeDone("")

            if (req) {
                setSpinView(true)
                axios.post(baseurl + "?p=creatIntervention", {
                    createdBy: ReactSession.get("prev_id"),
                    refIntervention: refIntervention,
                    eqCode: equip_code,
                    eqDesi: equip_design,
                    eqAge: equip_age,
                    eqUser: equip_userId,
                    eqSiteAf: equip_siteAffect,
                    eqDateInt: equip_dateInterv,
                    eqWorkDone: workToBeDone,
                    listMaterial: listChoosenMaterials
                }).then((response) => {
                    if (response.data.status) {
                        listChoosenMaterials.map((d, index) => (
                            axios.post(baseurl + "?p=addRequireMaterial", {
                                pirm_intervention_id: refIntervention,
                                pirm_ref: d.name,
                                pirm_quantity: d.qty
                            }).then((response) => {
                            })
                        ))
                        notifySuccess("Intervention added succesfully")
                        setSpinView(false);
                        handleTypeClose();
                    } else {
                        notifyError("Error when we add the intervention")
                    }
                }).catch(function (error) {
                    console.log("INTERNAL ERROR (creatIntervention)", error.response.data, moment().format('DD-MM HH:mm'));
                });
            }

        }

    }

    const getMaterial = () => {
        axios.post(baseurl + "?p=getMaterial", {
        }).then((response) => {
            if (response.data.status) {
                setListMaterials(response.data.list);
            }
        }).catch(function (error) {
            console.log("INTERNAL ERROR (getMaterial)", error.response.data, moment().format('DD-MM HH:mm'));
        });
    }

    const getDetailEquipement = () => {
        axios.post(baseurl + "?p=getDetailEquipement", {
            eqCode: equip_code
        }).then((response) => {
            if (response.data.status) {
                var now = moment();
                var dateLast = moment(response.data.lastIntNoClosed);
                var monthDif = now.diff(dateLast, 'month');

                if (response.data.lastIntNoClosed === "0000-00-00 00:00:00") {
                    setMessageCodeEquip("This equipment have an intervention open");
                    setEquip_design("");
                    setEquip_age("");
                    setEquip_userName("");
                    setEquip_userId(-1);
                    setEquip_siteAffect("");
                } else if (monthDif <= 2) {
                    setMessageCodeEquip("This equipment have a recent intervention. (" + monthDif + ") month ago");
                    setEquip_design(response.data.eqDesign);
                    setEquip_age(response.data.eqAge);
                    setEquip_userName(response.data.eqUserName);
                    setEquip_userId(response.data.eqUserId);
                    setEquip_siteAffect(response.data.eqSiteAffect);
                } else {
                    setEquip_design(response.data.eqDesign);
                    setEquip_age(response.data.eqAge);
                    setEquip_userName(response.data.eqUserName);
                    setEquip_userId(response.data.eqUserId);
                    setEquip_siteAffect(response.data.eqSiteAffect);
                    setMessageCodeEquip("");
                }

            } else {
                setMessageCodeEquip(response.data.msg);

                setEquip_design("");
                setEquip_age("");
                setEquip_userName("");
                setEquip_userId(-1);
                setEquip_siteAffect("");
            }
        }).catch(function (error) {
            console.log("INTERNAL ERROR (getDetailEquipement)", error.response.data, moment().format('DD-MM HH:mm'));
        });
    }

    const getReferenceNo = () => {
        axios.post(baseurl + "?p=getReferenceNo", {
        }).then((response) => {
            if (response.data.status) {
                if (response.data.reference === null) {
                    setRefIntervention("MP-IT-100001")
                } else {
                    setRefIntervention((response.data.reference.substring(0, 6)) + "" + (parseInt((response.data.reference).substring(6, response.data.reference.length)) + 1));

                }
            } else {
                notifyError("There is an error")
                setModalCreatIntervention(false);
            }
        }).catch(function (error) {
            console.log("INTERNAL ERROR (getReferenceNo)", error.response.data, moment().format('DD-MM HH:mm'));
        });
    }

    const getListUserToAssign = () => {
        axios.post(baseurl + "?p=getListUserToAssign", {
        }).then((response) => {
            if (response.data.status) {
                setListUserToAssign(response.data.list);
            }
        }).catch(function (error) {
            console.log("INTERNAL ERROR (getListUserToAssign)", error.response.data, moment().format('DD-MM HH:mm'));
        });
    }

    const getRequiredMat = (pi_ref, stat) => {
        axios.post(baseurl + "?p=getREquireMat", {
            pi_ref: pi_ref
        }).then((response) => {
            if (response.data.status) {
                setListChoosenMaterials(response.data.list);
                const filtered = listIntervention.filter(filtre => {
                    return filtre.pi_reference.indexOf(pi_ref) >= 0;
                })
                setWorkToBeDone(filtered[0].pi_description_work_done);

                if (stat === "true" && (response.data.list).length >= 0) {
                    axios.post(baseurl + "?p=getMaterials", {
                        list: response.data.list
                    }).then((response) => {
                        if (response.data.status) {
                            setListChoosenMaterials1(response.data.list)
                        } else {
                        }
                    })
                }
            }
        }).catch(function (error) {
            console.log("INTERNAL ERROR (getRequiredMat)", error.response.data, moment().format('DD-MM HH:mm'));
        });
    }

    const getEquipements = () => {
        axios.post(baseurl + "?p=getEquipements", {
        }).then((response) => {
            if (response.data.status) {
                setListEquipement(response.data.list);
            }
        }).catch(function (error) {
            console.log("INTERNAL ERROR (getEquipements)", error.response.data, moment().format('DD-MM HH:mm'));
        });
    }

    const assignInt = () => {
        if (userAssignTo !== "") {
            setSpinView(true);
            setMessageAssignTo("");
            axios.post(baseurl + "?p=assignInt", {
                creator: ReactSession.get("prev_id"),
                user_id: userAssignTo,
                pi_reference: refIntervention
            }).then((response) => {
                if (response.data.status) {
                    notifySuccess("process success");
                    handleTypeClose();
                    setSpinView(false)


                } else {
                    notifyError("Error when we assign the user");
                    setSpinView(false)

                }
            }).catch(function (error) {
                console.log("INTERNAL ERROR (assignInt)", error.response.data, moment().format('DD-MM HH:mm'));
            });
        } else {
            setMessageAssignTo("Choose one user");
            setSpinView(false)

        }
        setSpinView(false)

    }

    const assignAllInt = () => {
        let valid = true
        axios.post(baseurl + "?p=assignAllInt", {
            creator: ReactSession.get("prev_id"),
            user_id: userAssignTo,
            listIntervention: listIntervention.filter((obj) => obj.add === 1)
        }).then((response) => {
            if (response.data.status) {
                notifySuccess("process success");
                handleTypeClose();
                setSpinView(false);
            } else {
                valid = false
                notifyError("Error when we assign the user");
            }
        }).catch(function (error) {
            console.log("INTERNAL ERROR (assignAllInt)", error.response.data, moment().format('DD-MM HH:mm'));
        });
        setSpinView(false);
    }

    const checkAll = (evnt) => {
        if (evnt.target.checked === true) {
            setListIntervention(listIntervention.map(x => {
                if (x.pi_status_id === 1) {
                    return { ...x, add: 1 };
                }
                return { ...x, add: 0 };
            }))
        }
        else {
            const checkedLength = listIntervention.filter((obj) => obj.add === 1).length

            setListIntervention(listIntervention.map(x => {
                return { ...x, add: 0 };
            }))
        }
    }

    const closeInt = () => {
        var close = true;
        if (intReelStartDate === "") { setMessageReelStartDate("Complete the start date"); close = false }
        else setMessageReelStartDate("");
        if (intReelEndDate === "") { setMessageReelEndDate("Complete the end date"); close = false }
        else setMessageReelEndDate("");
        if (workToBeDone === "") { setMessageWorkToBeDone("This field cannot be empty"); close = false }
        else setMessageWorkToBeDone("")

        if (close) {
            setSpinView(true);
            axios.post(baseurl + "?p=closeInt", {
                creator: ReactSession.get('prev_id'),
                creatorName: ReactSession.get('prev_username'),
                pi_reference: refIntervention,
                start_date: intReelStartDate,
                end_date: intReelEndDate,
                workToBeDone: workToBeDone,
                int_observation: intObservation
            }).then((response) => {
                if (response.data.status) {
                    notifySuccess("The intervention has been closed succesfully");
                    setSpinView(false)
                    handleTypeClose();
                } else {
                    notifyError("Error when we close the intervention");
                }
            }).catch(function (error) {
                console.log("INTERNAL ERROR (closeInt)", error.response.data, moment().format('DD-MM HH:mm'));
            });
        }
    }

    const [genDate, setGenDate] = useState('')

    const generateIntervention = async () => {
        // let ref = refIntervention;
        const pirm_ref = "SOU001";
        const pirm_quantity = 1;

        if (genDate !== "") {
            setSpinView(true);
            let result = false
            await axios.post(baseurl + "?p=generateInt", {
                listEquipement,
                refIntervention,
                pirm_ref,
                pirm_quantity,
                genDate,
                created_by: ReactSession.get('prev_id')
            }).then((response) => {
                if (response.data.status) {
                    result = true
                }
                else {
                    console.log(response)
                }
            }).catch(function (error) {
                console.log("INTERNAL ERROR (generateIntervention)", error.response.data, moment().format('DD-MM HH:mm'));
            });
            if (result) {
                await notifySuccess("Generation finished")
                await handleTypeClose();
                await getIntrervention();
                setSpinView(false);
            }
        }
    }

    useEffect(() => {
        getIntrervention();
        getMaterial();
        getListUserToAssign();

        getEquipements();

        setRefreshTab(false);

    }, [refresh]);


    useEffect(() => {
        setRefreshTab(false);

        getIntrervention();
        setSupportForm("print-Container");

    }, [refreshTab])



    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const addToTable = (x) => {
        const found = listChoosenMaterials.find(obj => {
            return obj.name === x
        })
        if (found === null || found === undefined) {
            setListChoosenMaterials(current => [...current,
            {
                name: x,
                qty: 1
            }
            ])
        }
        else {
            const found = listChoosenMaterials.map((obj) => {
                if (obj.name === x)
                    return { ...obj, qty: obj.qty + 1 }
                return { ...obj }
            })
            setListChoosenMaterials(found)
        }
    }

    const deleteRow = (x) => {
        setListChoosenMaterials((current) =>
            current.filter((obj) => obj.name !== x.name)
        );
    }

    const filterList = (interv) => {
        setListIntervention2(
            listIntervention.filter(filtre => {
                return filtre.pi_reference.indexOf(interv) >= 0;
            })
        )
    }

    const changeValue = (val, ref, loc) => {
        if (loc === "list") {
            setListIntervention(
                listIntervention1.map(int => {
                    if (int.pi_reference === ref) {
                        return { ...int, pi_date_intervention: val }
                    } else {
                        return int;
                    }
                })
            )
        } else {
            setListIntervention1(
                listIntervention.map(int => {
                    if (int.pi_reference === ref) {
                        return { ...int, pi_date_intervention: int.pi_date_intervention }
                    } else {
                        return int
                    }
                })
            )

            axios.post(baseurl + "?p=changeValue", {
                pi_ref: ref,
                val: listIntervention[val].pi_date_intervention
            }).then((response) => {
                if (response.data.status) {
                    notifySuccess("the operation has been succesfully")
                } else {
                    notifyError("An error was occured")
                }
            }).catch(function (error) {
                console.log("INTERNAL ERROR (changeValue)", error.response.data, moment().format('DD-MM HH:mm'));
            });
        }

    }

    const componentRef = useRef();
    const handlePrint = useReactToPrint({

        content: () => componentRef.current,
        documentTitle: "Fiche-Intrervention-Preventive",
        onAfterPrint: () => console.log("Print success")
    })

    const get_data_repport = () => {
        var x = true;
        if (exportStartDate === "") { setMessageExportStartDate("Complete the date"); x = false }
        else { setMessageExportStartDate(""); }
        if (exportEndDate === "") { setMessageExportEndDate("Complete the date"); x = false }
        else { setMessageExportEndDate("") }

        if (x) {
            setSpinView(true);
            var listIntervent = [];
            console.log(ReactSession.get('prev_id'));
            axios.post(baseurl + "?p=getImportIntervention", {
                userId: ReactSession.get('prev_id'),
                dateStart: exportStartDate,
                dateEnd: exportEndDate
            }).then((response) => {
                if (response.data.status) {
                    downloadExcel({
                        fileName: "Repport",
                        sheet: "react-export-table-to-excel",
                        tablePayload: {
                            header,
                            // accept two different data structures
                            body: response.data.list,
                        },

                    });
                    handleTypeClose();
                    setSpinView(false);

                }
            }).catch(function (error) {
                console.log("INTERNAL ERROR (get_data_repport)", error.response.data, moment().format('DD-MM HH:mm'));
            });

        }


    };

    const addListToAssign = (pi_ref) => {
        setListIntervention(listIntervention.map(x => {
            if (x.pi_reference === pi_ref) {
                if (x.add === 1) {
                    return { ...x, add: 0 };
                }
                else
                    return { ...x, add: 1 };
            }
            return x;
        }));
    }

    useEffect(() => {
        const checkedLength = listIntervention.filter(obj => obj.add === 1).length
        const checkedLength1 = listIntervention.filter(obj => obj.pi_status_id === 1).length
        if (checkedLength1 === checkedLength)
            document.getElementById('checkAllButton').checked = true
        else
            document.getElementById('checkAllButton').checked = false
    }, [listIntervention])

    const signIntervention = () => {
        setSpinView(true);
        axios.post(baseurl + "?p=signIntervention", {
            author: ReactSession.get('prev_id'),
            authorName: ReactSession.get('prev_username'),
            userAssign: userAssignTo,
            refIntervention
        }).then((response) => {
            if (response.data.status) {
                notifySuccess("The intervention has been signed succesfully");
                setSpinView(false);
                setUserAssignTo(-1);
                handleTypeClose()
            } else {
                notifyError("Error when we sign the iintervention");
            }
        }).catch(function (error) {
            console.log("INTERNAL ERROR (signIntervention)", error.response.data, moment().format('DD-MM HH:mm'));
        });
    }

    const filtering = () => {
        setSpinView(true);
        if (filterType === 1) {
            if (filterText !== "") {
                const filtered = listIntervention.filter(filtre => {
                    return filtre.pi_reference.toLowerCase().indexOf(filterText.toLowerCase()) >= 0;
                });
                setListIntervention(filtered);
                setModalFilter(false);
                paginate(1);

            } else {
                setModalFilter(true);
            }
        } else if (filterType === 2) {
            if (modalFilter !== "") {
                const filtered = listIntervention.filter(filtre => {
                    return filtre.eq_designation.toLowerCase().indexOf(filterText.toLowerCase()) >= 0;
                });
                setListIntervention(filtered);
                setModalFilter(false);
                paginate(1);
            }
        } else if (filterType === 3) {
            if (modalFilter !== "") {
                const filtered = listIntervention.filter(filtre => {
                    return filtre.pi_description_work_done.toLowerCase().indexOf(filterText.toLowerCase()) >= 0;
                });
                setListIntervention(filtered);
                setModalFilter(false);
                paginate(1);
            }
        } else if (filterType === 4) {
            if (modalFilter !== "") {
                const filtered = listIntervention.filter(filtre => {
                    return filtre.pi_status_name.toLowerCase().indexOf(filterText.toLowerCase()) >= 0;
                });
                setListIntervention(filtered);
                setModalFilter(false);
                paginate(1);
            }
        } else if (filterType === 5) {
            if (modalFilter !== "") {
                const filtered = listIntervention.filter(filtre => {
                    return filtre.user_name.toLowerCase().indexOf(filterText.toLowerCase()) >= 0;
                });
                setListIntervention(filtered);
                setModalFilter(false);
                paginate(1);
            }
        } else if (filterType === 6) {
            if (modalFilter !== "") {
                const filtered = listIntervention.filter(filtre => {
                    return filtre.creator.toLowerCase().indexOf(filterText.toLowerCase()) >= 0;
                });
                setListIntervention(filtered);
                setModalFilter(false);
                paginate(1);
            }
        } else {
            if (filterText !== "") {
                const filtered = listIntervention.filter(filtre => {
                    return filtre.pi_signature.toLowerCase().indexOf(filterText.toLowerCase()) >= 0;
                });

                setListIntervention(filtered);
                setModalFilter(false);
                paginate(1);
            }
        }

        setSpinView(false);

    };

    const addCustomLogs = () => {
        axios.post(baseurl + "?p=addCustomLogs", {
            title: 'PRINT',
            desc: 'Print intervention',
            userId: ReactSession.get('prev_id')
        }).then((response) => {
            if (response.data.status) {

            }
        }).catch(function (error) {
            console.log("INTERNAL ERROR (addCustomLogs)", error.response.data, moment().format('DD-MM HH:mm'));
        });
    }



    return (
        <>
            <Header />
            <Header_mobile />
            <Modal show={modalCreatIntervention} onHide={handleTypeClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Creat a intervention <span className="ml-25">Ref N° : {refIntervention}</span></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form>
                        <div className="form-group mb-1 mt-1 row g-2 ">
                            <div className="col-md-6">
                                <label className="font-weight-bold">
                                    Code equipement<span className="text-danger pl-3">*</span>
                                </label>
                                <input
                                    id="ticket-subject"
                                    name="subject"
                                    className="form-control rounded-0"
                                    onChange={(e) => {
                                        setEquip_code((e.target.value).toUpperCase());
                                    }}
                                    onBlur={getDetailEquipement}
                                />
                                <div id="ticketprio-error" className="text-danger pt-2">
                                    {messageCodeEquip}
                                </div>
                            </div>

                            <div className="col-md-6">
                                <label className="font-weight-bold">
                                    Designation de l'equipement<span className="text-danger pl-3">*</span>
                                </label>
                                <input
                                    id="ticket-subject"
                                    name="subject"
                                    className="form-control rounded-0"
                                    value={equip_design}
                                    readOnly
                                />
                            </div>
                        </div>
                        <div className="form-group mb-1 mt-1 row g-1">
                            <div className="col-md-6">
                                <label className="font-weight-bold">
                                    Age of equipement<span className="text-danger pl-3">*</span>
                                </label>
                                <input
                                    id="ticket-subject"
                                    name="subject"
                                    className="form-control rounded-0"
                                    value={equip_age}
                                    readOnly
                                />
                            </div>

                            <div className="col-md-6">
                                <label className="font-weight-bold">
                                    Utilisateur<span className="text-danger pl-3">*</span>
                                </label>
                                <input
                                    id="ticket-subject"
                                    name="subject"
                                    className="form-control rounded-0"
                                    value={equip_userName}
                                    readOnly
                                />
                            </div>

                        </div>

                        <div className="form-group mb-1 mt-1 row g-2">
                            <div className="col-md-6">
                                <label className="font-weight-bold">
                                    Site d'affectation de l'equipement<span className="text-danger pl-3">*</span>
                                </label>
                                <input
                                    id="ticket-subject"
                                    name="subject"
                                    className="form-control rounded-0"
                                    value={equip_siteAffect}
                                    readOnly
                                />
                            </div>

                            <div className="col-md-6">
                                <label className="font-weight-bold">
                                    date of intervention<span className="text-danger pl-3">*</span>
                                </label>
                                <input
                                    id="ticket-subject"
                                    type="date"
                                    name="subject"
                                    className="form-control rounded-0"
                                    onChange={(e) => (setEquip_dateInterv(e.target.value))}
                                />

                                <div id="ticketprio-error" className="text-danger pt-2">
                                    {messageDateInt}
                                </div>
                            </div>


                        </div>
                        <div className="form-group mb-4 mt-3">
                            <label className="font-weight-bold">
                                Work to be done <span className="text-danger pl-3">*</span>
                            </label>
                            <textarea id="pidesc" className="form-control rounded-0" rows="4" onChange={(e) => (setWorkToBeDone(e.target.value))}>
                            </textarea>

                            <div id="ticketprio-error" className="text-danger pt-2">
                                {messageWorkToBeDone}
                            </div>
                        </div>

                        <div className="container text-left">
                            <div className="row text-left">
                                <div className="col-5">
                                    <div className="form-group">
                                        <label className="font-weight-bold">
                                            Pièces et matériels utilisés<span className="text-danger pl-3">*</span>
                                        </label>
                                        <select multiple id="materials-list" className="form-control rounded-0" size="10">
                                            {listMaterials ? listMaterials.map((d, index) => (
                                                <option key={index} value={d.mat_code} onClick={() => { setMat(d.mt_code) }}>{d.mt_code + " - " + d.mt_description}</option>
                                            )) : (
                                                <></>
                                            )}
                                        </select>
                                        <div id="ticketprio-error" className="text-danger pt-2">
                                        </div>
                                    </div>
                                </div>

                                <div className="col-1 mt-2">
                                    <div className="col-xl-1 mt-5">
                                        <p id="add" onClick={() => {
                                            addToTable(mat)
                                        }
                                        } className="btn btn-light border fs-13 pl-5 pr-3 pt-3 pb-3" title="add a row" >
                                            <i className="ki ki-bold-arrow-next icon-xs"></i>
                                        </p>
                                        <p onClick={() => { }} id="empty-tab" className="btn btn-light border fs-13 pl-3 pr-2 pt-3 pb-3 mt-3" title="Empty tab" >
                                            <i className="la la-trash"></i>
                                        </p>
                                    </div>
                                </div>

                                <div className="col-5 ml-2" style={{ marginTop: '45px' }}>
                                    <div className="col">
                                        <table id="materials" className="table border">
                                            <thead className="bg-light border-top">
                                                <tr key={"0"}>
                                                    <th scope="col" className="w-40px"></th>
                                                    <th scope="col" className="border-left">Référence</th>
                                                    <th scope="col" className="w-50px border-left">Quantité</th>
                                                </tr>
                                            </thead>
                                            <tbody id="">
                                                {listChoosenMaterials
                                                    ? listChoosenMaterials.map((x, index) => {
                                                        return <tr id='td0' key={index}>
                                                            <td className="w-12 pt-3 cursor-pointer" onClick={() => deleteRow(x)}><i className="la la-trash-alt"></i></td>
                                                            <td id="tb-code" className="w-50 border-left pt-6">{x.name}</td>
                                                            <td id="tb-quantity" className="w-30 border-left col-xs-2">{x.qty}</td>
                                                        </tr>
                                                    })
                                                    : null
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-secondary" onClick={handleTypeClose}>Annuler</button>
                    {spinView ?
                        <>
                            <button className="btn btn-primary" type="button" disabled>
                                <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                                Creating...
                            </button>
                        </>
                        :
                        <button type="button" className="btn btn-success" onClick={() => { creatIntervention(); }}>Envoyer</button>
                    }

                </Modal.Footer>
            </Modal>
            <Modal show={modalDescription} onHide={handleTypeClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Description of work to be done</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <p>
                            {desc}
                        </p>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-secondary" onClick={handleTypeClose}>Close</button>
                </Modal.Footer>
            </Modal>
            <Modal show={modalAssignTo} onHide={handleTypeClose} centered>
                <Modal.Header closeButton>
                    {refIntervention !== '' ? (
                        <Modal.Title>Assignation for Intervention Ref <span className="ml-5">{refIntervention}</span></Modal.Title>

                    ) : (
                        <Modal.Title>Multiple assign</Modal.Title>

                    )}
                </Modal.Header>
                <Modal.Body>
                    {refIntervention !== '' ? (
                        <form className="pb-2">
                            <div className="form-group mb-4 mt-3 ">
                                <label className="font-weight-bold">
                                    Assign to<span className="text-danger pl-3">*</span>
                                </label>
                                <select
                                    id="type"
                                    onChange={(e) => {
                                        setUserAssignTo(e.target.value);
                                    }}
                                    value={userAssignTo}
                                    name="plan-month"
                                    className="form-control rounded-0 cursor-pointer"
                                >
                                    <option value="" key="0"></option>
                                    {
                                        listUserToAssign ? (listUserToAssign.map((d, index) => (
                                            <option key={index} value={d.user_id}>
                                                {d.user_name}
                                            </option>
                                        ))
                                        ) : (
                                            <>

                                            </>
                                        )
                                    }
                                </select>
                                <div id="ticketprio-error" className="text-danger pt-2">
                                    {messageAssignTo}
                                </div>
                            </div>
                        </form>
                    ) : (
                        <form className="pb-2">
                            <div className="form-group mb-4 mt-3 ">
                                <label className="font-weight-bold">
                                    Assign : {listAssign.map((e) => e + ', ')} to<span className="text-danger pl-3">*</span>
                                </label>
                                <select
                                    id="type"
                                    onChange={(e) => {
                                        setUserAssignTo(e.target.value);
                                    }}
                                    value={userAssignTo}
                                    name="plan-month"
                                    className="form-control rounded-0 cursor-pointer"
                                >
                                    <option value="" key="0"></option>
                                    {
                                        listUserToAssign ? (listUserToAssign.map((d, index) => (
                                            <option key={index} value={d.user_id}>
                                                {d.user_name}
                                            </option>
                                        ))
                                        ) : (
                                            <>

                                            </>
                                        )
                                    }
                                </select>
                                <div id="ticketprio-error" className="text-danger pt-2">
                                    {messageAssignTo}
                                </div>
                            </div>
                        </form>
                    )}

                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-secondary" onClick={() => setModalAssignTo(false)}>Annuler</button>
                    {spinView ?
                        <button className="btn btn-primary" type="button" disabled>
                            <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                            Loading...
                        </button>
                        :
                        refIntervention !== '' ? (
                            <button type="button" className="btn btn-danger" onClick={assignInt}>Assign</button>

                        ) : (
                            <button type="button" className="btn btn-danger" onClick={() => { assignAllInt(); setSpinView(true); }}>Assign All</button>

                        )}
                </Modal.Footer>
            </Modal>
            <Modal show={modalCloseIntervention} onHide={handleTypeClose} size="lg" centered>
                <Modal.Header closeButton>
                    <Modal.Title>
                        CLOSE INTERVENTION N° <span className="ml-40 text-danger"> Ref N° : {refIntervention}</span>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form>
                        <div className="form-group mb-4 mt-3 row">
                            <div className="col-md-6">
                                <label className="font-weight-bold">
                                    Temps réel du début de l'intervention<span className="text-danger pl-3">*</span>
                                </label>
                                <input
                                    id="ticket-subject"
                                    type="datetime-local"
                                    name="subject"
                                    className="form-control rounded-0"
                                    onChange={(e) => (setIntReelStartDate(e.target.value))}
                                />
                                <div id="ticketprio-error" className="text-danger pt-2">
                                    {messageReelStartDate}
                                </div>
                            </div>

                            <div className="col-md-6">
                                <label className="font-weight-bold">
                                    Fin de l'intervention<span className="text-danger pl-3">*</span>
                                </label>
                                <input
                                    id="ticket-subject"
                                    type="datetime-local"
                                    name="subject"
                                    className="form-control rounded-0"
                                    onChange={(e) => (setIntReelEndDate(e.target.value))}
                                />
                                <div id="ticketprio-error" className="text-danger pt-2">
                                    {messageReelEndDate}
                                </div>
                            </div>
                        </div>

                        <div className="form-group mb-4 mt-3 row">
                            <div className="col-md-6">
                                <label className="font-weight-bold">
                                    Description des travaux effectués <span className="text-danger pl-3">*</span>
                                </label>
                                <textarea id="pidesc" className="form-control rounded-0" rows="4" value={workToBeDone} onChange={(e) => (setWorkToBeDone(e.target.value))}>
                                </textarea>

                                <div id="ticketprio-error" className="text-danger pt-2">
                                    {messageWorkToBeDone}
                                </div>
                            </div>

                            <div className="col-md-6">
                                <label className="font-weight-bold">
                                    Entrer une observation
                                </label>
                                <textarea id="pidesc" className="form-control rounded-0" value={intObservation} rows="4" onChange={(e) => (setIntObservation(e.target.value))}>
                                </textarea>
                            </div>
                        </div>


                        <div className="container text-left row">
                            <div className="col-md-5">
                                <div className="form-group">
                                    <label className="font-weight-bold">
                                        Pièces et matériels utilisés<span className="text-danger pl-3"></span>
                                    </label>
                                    <select multiple id="materials-list" className="form-control rounded-0" size="10">
                                        {listMaterials ? listMaterials.map((d, index) => (
                                            <option key={index} value={d.mat_code} onClick={() => { setMat(d.mt_code) }}>{d.mt_code + " - " + d.mt_description}</option>
                                        )) : (
                                            <></>
                                        )}
                                    </select>
                                    <div id="ticketprio-error" className="text-danger pt-2">
                                    </div>
                                </div>
                            </div>

                            <div className="col-1 mt-2">
                                <div className="col-xl-1 mt-5">
                                    <p id="add" className="btn btn-light border fs-13 pl-5 pr-3 pt-3 pb-3" title="add a row" >
                                        <i className="ki ki-bold-arrow-next icon-xs"></i>
                                    </p>
                                    <p id="empty-tab" className="btn btn-light border fs-13 pl-3 pr-2 pt-3 pb-3 mt-3" title="Empty tab" >
                                        <i className="la la-trash"></i>
                                    </p>
                                </div>
                            </div>

                            <div className="col-6 " style={{ marginTop: '25px' }}>
                                <div className="col">
                                    <table id="materials" className="table border">
                                        <thead className="bg-light border-top">
                                            <tr key={"0"}>
                                                <th scope="col" className="w-40px"></th>
                                                <th scope="col" className="border-left">Référence</th>
                                                <th scope="col" className="w-50px border-left">Quantité</th>
                                            </tr>
                                        </thead>
                                        <tbody id="">
                                            {listChoosenMaterials
                                                ? listChoosenMaterials.map((x, index) => {
                                                    return <tr key={index} id='td0'>
                                                        <td className="w-12 pt-3 cursor-pointer"><i className="la la-trash-alt"></i></td>
                                                        <td id="tb-code" className="w-50 border-left pt-6">{x.name}</td>
                                                        <td id="tb-quantity" className="w-30 border-left col-xs-2">{x.qty}</td>
                                                    </tr>
                                                })
                                                : null
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-secondary" onClick={handleTypeClose}>Annuler</button>

                    {spinView ?
                        <button className="btn btn-primary" type="button" disabled>
                            <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                            Closing ...
                        </button>
                        :
                        <button type="button" className="btn btn-danger" onClick={closeInt}>Close</button>
                    }

                </Modal.Footer>
            </Modal>
            <Modal show={modalConfirmGenerate} onHide={handleTypeClose} size="sm" centered>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Confirmation
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="col-md-12">
                        <label className="font-weight-bold">
                            Choose the date<span className="text-danger pl-3">*</span>
                        </label>
                        <input
                            type='date'
                            className="form-control rounded-0"
                            onChange={(e) => {
                                setGenDate(e.target.value)
                            }}
                            value={genDate}
                        />
                        <div id="ticketprio-error" className="text-danger pt-2">
                            {messageCodeGen}
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-secondary" onClick={handleTypeClose}>Annuler</button>
                    {spinView ?
                        <button className="btn btn-primary" type="button" disabled>
                            <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                            Generating ...
                        </button>
                        :
                        <button type="button" className="btn btn-danger" onClick={generateIntervention}>Generate</button>
                    }

                </Modal.Footer>
            </Modal>
            <Modal show={modalView} onHide={handleTypeClose} size="lg" centered>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Intervention préventive
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {listIntervention2.length > 0 ? (
                        <div className="row">
                            <div className="col-lg-4 border-right">
                                <div className="border-bottom fs-14 font-weight-bolder mb-5 pb-2 d-flex justify-content-between" id="print-btn">
                                    <div className="pt-2">
                                        INTERVENTION DETAILS                                                </div>
                                    <button id="printpirep" className="btn btn-light border btn-sm font-weight-bold rounded-0" onClick={() => { setSupportForm("print-container"); handlePrint(); setSupportForm(""); addCustomLogs() }}>
                                        <i aria-hidden="true" className="la la-print"></i> Print
                                    </button>
                                </div>

                                <div className="form-group mb-3 d-flex bg-light2 pl-3 pr-3 pt-2">
                                    <label className="font-weight-bolder">REF Intervention : </label>
                                    <p id="pi_ref-v" className="pl-2" onClick={() => console.log(listIntervention2)}>{listIntervention2[0].pi_reference}</p>
                                </div>
                                <div className="form-group mb-3 d-flex bg-light2 pl-3 pr-3 pt-2">
                                    <label className="font-weight-bolder">Status : </label>
                                    <p id="pi_status-v" className="pl-2">{listIntervention2[0].pi_status_name}</p>
                                </div>
                                <div className="form-group mb-3 d-flex bg-light2 pl-3 pr-3 pt-2">
                                    <label className="font-weight-bolder">Created by : </label>
                                    <p id="Created-by-v" className="pl-2">{listIntervention[0].creator}</p>
                                </div>
                                <div className="form-group mb-3 d-flex bg-light2 pl-3 pr-3 pt-2">
                                    <label className="font-weight-bolder">Created date : </label>
                                    <p id="Created-date-v" className="pl-2">{moment(listIntervention2[0].pi_created_date).format('YYYY-MM-DD')}</p>
                                </div>
                            </div>

                            <div className="col-lg-4">
                                <div className="border-bottom fs-14 font-weight-bolder mb-5 pb-4 pt-2 text-center">EQUIPEMENT DETAILS</div>

                                <div className="form-group mb-3 d-flex bg-light2 pl-3 pr-3 pt-2">
                                    <label className="font-weight-bolder">MATERIAL CODE : </label>
                                    <p id="materials_code-v" className="pl-2">{listIntervention2[0].eq_code}</p>
                                </div>

                                <div className="form-group mb-3 d-flex bg-light2 pl-3 pr-3 pt-2">
                                    <label className="font-weight-bolder">DESIGNATION : </label>
                                    <p id="designation-v" className="pl-2">{listIntervention2[0].eq_designation}</p>
                                </div>

                                <div className="form-group d-flex bg-light2 pl-3 pr-3 pt-2">
                                    <label className="font-weight-bolder">AGE  : </label>
                                    <p id="age-v" className="pl-2">{equip_age} </p>
                                </div>

                                <div className="form-group d-flex bg-light2 pl-3 pr-3 pt-2">
                                    <label className="font-weight-bolder">USER  : </label>
                                    <p id="user-v" className="pl-2">{listIntervention2[0].eqUser} </p>
                                </div>

                                <div className="form-group d-flex bg-light2 pl-3 pr-3 pt-2">
                                    <label className="font-weight-bolder">LOCATION  : </label>
                                    <p id="location-v" className="pl-2">{listIntervention2[0].site_name}</p>
                                </div>

                            </div>

                            <div className="col-lg-4 border-left">
                                <div className="border-bottom fs-14 font-weight-bolder mb-5 pb-2 text-center">
                                    <div className="pt-2 pb-2">
                                        INTERVENTION DETAILS                                                </div>
                                </div>

                                <div className="form-group mb-3 d-flex bg-light2 pl-3 pr-3 pt-2">
                                    <label className="font-weight-bolder">Done by : </label>
                                    <p id="pi_assigned_to-v" className="pl-2">{listIntervention2[0].user_name}</p>
                                </div>

                                <div className="form-group mb-3 d-flex bg-light2 pl-3 pr-3 pt-2">
                                    <label className="font-weight-bolder">intervention started time : </label>
                                    <p id="date_s-v" className="">
                                        {moment(listIntervention2[0].pi_start_time).format('MMMM d, YYYY HH:MM:SS') === "Invalid date" ? ("Not start") : moment(listIntervention2[0].pi_start_time).format('YYYY-MM-DD hh:mm:ss')}
                                    </p>
                                </div>

                                <div className="form-group mb-3 d-flex bg-light2 pr-3 pt-2">
                                    <label className="font-weight-bolder ml-3">intervention ended time : </label>
                                    <p id="date_e-v" className="">
                                        {moment(listIntervention2[0].pi_end_time).format('MMMM d, YYYY HH:MM:SS') === "Invalid date" ? ("Not finished") : moment(listIntervention2[0].pi_end_time).format('YYYY-MM-DD hh:mm:ss')}
                                    </p>
                                </div>

                                <div className="form-group mb-3 bg-light2 pl-3 pr-3 pt-2">
                                    <label className="font-weight-bolder">Description work done : </label>
                                    <p id="work_done-v" className="pb-2">{listIntervention2[0].pi_description_work_done}</p>
                                </div>

                                <div className="form-group mb-3 bg-light2 pl-3 pr-3 pt-2">
                                    <label className="font-weight-bolder">Materials used : </label>
                                    {listChoosenMaterials1.length > 0 ? (
                                        listChoosenMaterials1.map((d, index) => (
                                            <p key={index} id="materials_u-v" className="pb-2">{listChoosenMaterials[index].qty + " " + d.mt_description}</p>
                                        ))
                                    ) : ("")}

                                </div>


                            </div>
                        </div>
                    ) : (<></>)}

                </Modal.Body>
                <Modal.Footer>
                    {/* <button type="button" className="btn btn-danger" onClick={() => { handleTypeClose() }}>Annuler</button> */}
                    <button type="button" className="btn btn-danger" onClick={handleTypeClose}>Close</button>
                </Modal.Footer>
            </Modal>
            <Modal show={modalConfirmDateExport} onHide={handleTypeClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Confirmation
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="form-group mb-1 mt-1 row g-2 col-md-12">
                        <div className="col-md-6">
                            <label className="font-weight-bold">
                                Choose start date<span className="text-danger pl-3">*</span>
                            </label>
                            <input
                                type='date'
                                className="form-control rounded-0"
                                onChange={(e) => {
                                    setExportStartDate(e.target.value)
                                }}
                                value={exportStartDate}
                            />
                            <div id="ticketprio-error" className="text-danger pt-2">
                                {messageExportStartDate}
                            </div>
                        </div>

                        <div className="col-md-6">
                            <label className="font-weight-bold">
                                Choose End date<span className="text-danger pl-3">*</span>
                            </label>
                            <input
                                type='date'
                                className="form-control rounded-0"
                                onChange={(e) => {
                                    setExportEndDate(e.target.value)
                                }}
                                value={exportEndDate}
                            />
                            <div id="ticketprio-error" className="text-danger pt-2">
                                {messageExportEndDate}
                            </div>
                        </div>
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-secondary" onClick={handleTypeClose}>Annuler</button>
                    {spinView ?
                        <button className="btn btn-primary" type="button" disabled>
                            <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                            Exporting ...
                        </button>
                        :
                        <button type="button" className="btn btn-danger" onClick={get_data_repport}>Export</button>
                    }


                </Modal.Footer>
            </Modal>
            <Modal show={signAction} onHide={handleTypeClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Confirmation</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure?
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-secondary" onClick={handleTypeClose}>Close</button>

                    {spinView ?
                        <button className="btn btn-primary" type="button" disabled>
                            <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                            Loading ...
                        </button>
                        :
                        <button type="button" className="btn btn-danger" onClick={signIntervention}>Confirm</button>

                    }
                </Modal.Footer>
            </Modal>
            <Modal show={modalFilter} onHide={handleTypeClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Filter</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form>
                        <div className="form-group mb-4 mt-3">
                            <label className="font-weight-bold">
                                {
                                    filterType === 1 ? (
                                        "Reference"
                                    ) : filterType === 2 ? (
                                        "Designation"
                                    ) : filterType === 3 ? (
                                        "DESCRIPTION"
                                    ) : filterType === 4 ? (
                                        "STATUS"
                                    ) : filterType === 5 ? (
                                        "ASSIGN TO"
                                    ) : filterType === 6 ? (
                                        "CREATED BY"
                                    ) : "SIGNATURE"
                                }
                                <span className="text-danger pl-3">*</span>
                            </label>
                            <input
                                id="eq-subject"
                                onChange={(e) => {
                                    setFilterText(e.target.value);
                                }}
                                name="subject"
                                className="form-control rounded-0"
                            />
                            <div id="ticketprio-error" className="text-danger pt-2">

                            </div>
                        </div>

                        <button ref={null} hidden className="btn">
                            submit
                        </button>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-secondary" onClick={handleTypeClose}>Close</button>
                    {spinView ?
                        <button className="btn btn-success" type="button" disabled>
                            <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                            Filtering  ...
                        </button>
                        :
                        <button type="button" className="btn btn-success" onClick={filtering}>Add filter</button>
                    }


                </Modal.Footer>
            </Modal>
            <div
                className="d-flex flex-column flex-column-fluid"
                style={{ minHeight: "85vh", backgroundColor: "#eef0f8" }}
            >
                <div className="container mt-20 mb-20 col-12">
                    <div className="row mb-5">
                        <div className="col-xl-1"></div>
                        <div className="col-xl-12 p-0">
                            <div className="row">
                                <div className="col-xl-12 col-md-12" id="tck1">
                                    <div className="card card-custom gutter-b">
                                        <div className="card-body d-flex flex-column pb-0">
                                            <div className="flex-grow-1 pb-0">
                                                <div className=" text-left card-header " style={{ backgroundColor: "white" }}>

                                                    <div className="row text-left">
                                                        <div className="container text-right ">
                                                            <div className="row text-right ">
                                                                <div className="col-md-3 col-lg-2 col-6 mt-3 text-left">
                                                                    <label className="fs-14">List of intervention</label>
                                                                    <i className="flaticon-refresh ml-1 cursor-pointer" onClick={() => { setRefreshTab(true) }}> </i>
                                                                </div>
                                                                <div className="col-md-9 col-lg-10 col-6 m-0 ">
                                                                    {windowSize.innerWidth < 768 ?
                                                                        <>
                                                                            {listIntervention.filter(obj => obj.add === 1).length > 0 && perm_assign_intervention == 1 ? (
                                                                                <FcManager size={40} className="btn btn-light cursor-pointer border" onClick={() => { setModalAssignTo(true); }} />
                                                                            ) : (<></>)}

                                                                            {perm_Report === 1 ?
                                                                                <SiMicrosoftexcel size={40} className="text-success btn btn-light ml-1 cursor-pointer border" onClick={() => { setModalConfirmDateExport(true) }} />
                                                                                :
                                                                                <></>
                                                                            }

                                                                            {perm_create_intervention === 1 ?
                                                                                <FcDebian size={40} className="btn btn-light ml-1 cursor-pointer border" onClick={() => { setModalConfirmGenerate(true); getReferenceNo(); }} />
                                                                                :
                                                                                <></>
                                                                            }


                                                                            <FcClearFilters size={40} className="btn btn-light ml-1 cursor-pointer border" onClick={() => { setRefreshTab(true) }} />
                                                                            {/* <MdOutlineAddModerator size={40} className="text-danger btn btn-light ml-1 cursor-pointer border" onClick={() => { setModalCreatIntervention(true); getReferenceNo() }} /> */}
                                                                        </>
                                                                        :

                                                                        <>
                                                                            {listIntervention.filter(obj => obj.add === 1).length > 0 && perm_assign_intervention === 1 ? (
                                                                                <button className="btn btn-warning" onClick={() => { setModalAssignTo(true); }}>Assign all</button>
                                                                            ) : (<></>)}

                                                                            {perm_Report === 1 ?
                                                                                <button className="btn btn-light ml-2" onClick={() => { setModalConfirmDateExport(true) }}>EXPORTER</button>
                                                                                :
                                                                                <></>
                                                                            }



                                                                            {perm_create_intervention === 1 ?
                                                                                <button className="btn btn-danger ml-2" onClick={() => { setModalConfirmGenerate(true); getReferenceNo(); }}>Generate intervention</button>
                                                                                : <></>}

                                                                            <button className="btn btn-light ml-2" onClick={() => { setRefreshTab(true) }}>Supprimer le filtre</button>

                                                                            {perm_create_intervention === 1 ?
                                                                                <button className="btn btn-danger ml-2" onClick={() => { setModalCreatIntervention(true); getReferenceNo() }}>Create intervention</button>
                                                                                :
                                                                                <></>
                                                                            }

                                                                        </>}

                                                                </div>
                                                            </div>

                                                        </div>

                                                    </div>

                                                </div>

                                                <div className="col text-right">

                                                </div>
                                                <div className="col text-right">


                                                </div>

                                                <div className="card-body">
                                                    <div className="table-responsive">
                                                        <table id="result" className="table data">
                                                            <thead className="text-left">
                                                                <tr key={"xd"}>
                                                                    <th scope="col" className="w-25px ">#</th>
                                                                    <th scope="col"> <input id="checkAllButton" type={"checkbox"} onClick={(evnt) => checkAll(evnt)} /></th>
                                                                    <th scope="col"></th>
                                                                    <th scope="col" className="col-2">
                                                                        PI REF
                                                                        <a href="#" data-toggle="modal" data-target="#fi_ref_modal">
                                                                            <i className="la la-filter" onClick={() => { setModalFilter(true); setFilterType(1); setListIntervention(listIntervention1); setFilterText(""); }}></i>
                                                                        </a>
                                                                    </th>
                                                                    <th scope="col" className="col-2 ">
                                                                        DESIGNATION
                                                                        <a href="#" data-toggle="modal" data-target="#fi_ref_modal">
                                                                            <i className="la la-filter" onClick={() => { setModalFilter(true); setFilterType(2); setListIntervention(listIntervention1); setFilterText(""); }}></i>
                                                                        </a>
                                                                    </th>
                                                                    <th scope="col" className="col-2 ">
                                                                        DESCRIPTION
                                                                        <a href="#" data-toggle="modal" data-target="#fi_ref_modal">
                                                                            <i className="la la-filter" onClick={() => { setModalFilter(true); setFilterType(3); setListIntervention(listIntervention1); setFilterText(""); }}></i>
                                                                        </a>
                                                                    </th>
                                                                    <th scope="col" className="col-2 ">
                                                                        STAUS
                                                                        <a href="#" data-toggle="modal" data-target="#fi_ref_modal">
                                                                            <i className="la la-filter" onClick={() => { setModalFilter(true); setFilterType(4); setListIntervention(listIntervention1); setFilterText(""); }}></i>
                                                                        </a>
                                                                    </th>
                                                                    <th scope="col" className="col-2 ">
                                                                        INTERVENTION DATE
                                                                    </th>
                                                                    <th scope="col">
                                                                        ASSIGN TO
                                                                        <a href="#" data-toggle="modal" data-target="#fi_ref_modal">
                                                                            <i className="la la-filter" onClick={() => { setModalFilter(true); setFilterType(5); setListIntervention(listIntervention1); setFilterText(""); }}></i>
                                                                        </a>
                                                                    </th>
                                                                    <th scope="col" >
                                                                        CREATED BY
                                                                        <a href="#" data-toggle="modal" data-target="#fi_ref_modal">
                                                                            <i className="la la-filter" onClick={() => { setModalFilter(true); setFilterType(6); setListIntervention(listIntervention1); setFilterText(""); }}></i>
                                                                        </a>
                                                                    </th>
                                                                    <th scope="col" >
                                                                        SIGNATURE
                                                                        <a href="#" data-toggle="modal" data-target="#fi_ref_modal">
                                                                            <i className="la la-filter" onClick={() => { setModalFilter(true); setFilterType(7); setListIntervention(listIntervention1); setFilterText(""); }}></i>
                                                                        </a>
                                                                    </th>
                                                                    <th scope="col">

                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody id="resultdata">

                                                                {currentIntervention ? (
                                                                    currentIntervention.map((d, index) => (
                                                                        <tr key={index}>
                                                                            <td className="text-left align-middle">{index + 1}</td>
                                                                            <td className="text-center align-middle">
                                                                                {d.pi_status_id != 2 ? <input checked={d.add === 1 ? true : false} id={d.pi_reference} key={d.pi_reference} type="checkbox" value={d.pi_reference} onChange={(e) => { }} onClick={(e) => { addListToAssign(e.target.value); setRefIntervention('') }} /> : null}
                                                                            </td>
                                                                            <td className="text-left align-middle">
                                                                                <Dropdown>
                                                                                    <Dropdown.Toggle
                                                                                        variant="dropdown-basic"
                                                                                        className="w-60px py-0 text-white p-0 m-0"
                                                                                    >
                                                                                        <div
                                                                                            className="dropdown dropdown-inline bg-light2 py-0 rounded"
                                                                                            data-toggle="dropdown"

                                                                                        >
                                                                                            <a

                                                                                                type="button"
                                                                                                className="btn btn-light2 btn-icon py-0 btn-sm"
                                                                                            >
                                                                                                <i className="ki ki-bold-more-hor"></i>
                                                                                            </a>
                                                                                        </div>
                                                                                    </Dropdown.Toggle>

                                                                                    <Dropdown.Menu className="">
                                                                                        {perm_view_intervention === 1 ?

                                                                                            <Dropdown.Item
                                                                                                href="#"
                                                                                                className="dropdown-item border-bottom border-top"
                                                                                                onClick={() => { setModalView(true); filterList(d.pi_reference); setEquip_age(d.pi_material_age); getRequiredMat(d.pi_reference, "true") }}
                                                                                            >
                                                                                                <i className="fa fa-list"></i>{" "}
                                                                                                <span className="pl-3">View</span>
                                                                                            </Dropdown.Item>
                                                                                            : <></>
                                                                                        }
                                                                                    </Dropdown.Menu>


                                                                                </Dropdown>
                                                                            </td>
                                                                            <td className="text-left align-middle" style={{ width: '100px' }}>{d.pi_reference}</td>
                                                                            <td className="text-left align-middle" style={{ width: '150px' }}>{d.eq_designation}</td>
                                                                            {/* <td className="text-left align-middle">{d.eq_user}</td> */}
                                                                            <td className="text-left align-middle">{(d.pi_description_work_done)}</td>
                                                                            <td className="text-left align-middle">
                                                                                <span id="tb-status" className={(d.pi_status_name === "Open" ? ("available") : ("done")) + " py-1 px-2 text-white rounded py-1 px-2 text-white rounded"}  >
                                                                                    {d.pi_status_name}
                                                                                </span>
                                                                            </td>
                                                                            <td className="text-left align-middle">
                                                                                <div className="container text-left">
                                                                                    <div className="row text-left">
                                                                                        <div className="col">
                                                                                            {d.pi_date_intervention !== null ?
                                                                                                perm_edit_intervention === 1 ? (
                                                                                                    <input type="date" readOnly={d.pi_status_name === "Done" ? true : false} value={moment(d.pi_date_intervention).format('YYYY-MM-DD')} style={{ border: "none" }} onChange={(e) => { changeValue(e.target.value, d.pi_reference, "list") }} />
                                                                                                ) : moment(d.pi_date_intervention).format('YYYY-MM-DD') : (<></>)}
                                                                                        </div>


                                                                                        {d.pi_date_intervention ? (
                                                                                            d.pi_date_intervention !== listIntervention1[index + (linePerPage * (currentPage - 1))].pi_date_intervention ? (
                                                                                                <div className="col-md-2 cursor-pointer" onClick={() => { changeValue(index + (linePerPage * (currentPage - 1)), d.pi_reference, "BD") }}>
                                                                                                    <i className="fa fa-paper-plane" size="2"></i>
                                                                                                </div>
                                                                                            ) : (<></>)
                                                                                        ) : (<></>)}

                                                                                    </div>
                                                                                </div>
                                                                                {/* {moment(d.pi_date_intervention).format('MMMM d, YYYY')} */}
                                                                            </td>
                                                                            <td className="text-left align-middle">{d.user_name}</td>
                                                                            <td className="text-left align-middle">{d.creator}</td>
                                                                            <td className="text-left align-middle">{d.pi_signature === 'Y' ? <HiCheckCircle size={25} className="text-success" /> : <HiXCircle size={25} className="text-danger" />}</td>
                                                                            <td className="text-left align-middle">
                                                                                {d.pi_support_id === -1 && perm_assign_intervention === 1 ? (
                                                                                    <a onClick={() => { setModalAssignTo(true); setRefIntervention(d.pi_reference) }} className="btn btn-sm bg-red text-white">Assign</a>
                                                                                ) : d.pi_status_name === "Open" && d.pi_support_id !== -1 && perm_close_intervention === 1 ? (
                                                                                    <a onClick={() => { setModalCloseIntervention(true); getRequiredMat(d.pi_reference); setRefIntervention(d.pi_reference); }} className="btn btn-sm bg-red text-white">Close</a>
                                                                                ) : d.pi_status_id === 2 && d.pi_signature === "N" && perm_add_signature === 1 ? (
                                                                                    <a onClick={() => { setUserAssignTo(d.pi_support_id); setSignAction(true); setRefIntervention(d.pi_reference) }} className="btn btn-sm btn-warning text-white">SIGN</a>
                                                                                ) : ""}
                                                                            </td>
                                                                            <td className="text-left align-middle">

                                                                            </td>
                                                                        </tr>
                                                                    ))
                                                                ) : (
                                                                    <></>
                                                                )}
                                                            </tbody>

                                                        </table>
                                                        {windowSize.innerWidth < 768 && perm_create_intervention === 1 ?
                                                            <MdOutlineAddModerator size={40} style={{
                                                                top: "85%",
                                                                left: "80%",
                                                                width: "50px",
                                                                position: "fixed",
                                                                overflow: "hidden",
                                                            }} className="text-danger btn btn-light ml-1 cursor-pointer border text-right" onClick={() => { setModalCreatIntervention(true); getReferenceNo() }} />
                                                            : <></>}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="card-footer pt-2 pb-2 col-12 mb-0 bg-white">
                                            <div className="col-xl-4 col-sm-12 col-12 d-flex">
                                                <select className="page-range" onChange={(e) => { setlinePerPage(e.target.value); setCurrentPage(1) }}>
                                                    <option value={10}>10</option>
                                                    <option value={25}>25</option>
                                                    <option value={50}>50</option>
                                                    <option value={listIntervention.length}>All</option>
                                                </select>
                                                <div className="mt-6"> </div>
                                                <div className="mt-3"><div id="resultfound">{listIntervention.length} résultat(s)</div> </div>
                                            </div>
                                            <div className="col-xl-4 col-sm-12 col-12">
                                                <div className="d-flex align-items-center justify-content-center">

                                                    <Pagination
                                                        postsPerPage={linePerPage}
                                                        totalPosts={listIntervention.length}
                                                        paginate={paginate}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-xl-4">

                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div hidden>
                {listIntervention2.length > 0 ? (
                    <div className={supportForm} ref={componentRef} style={{ width: '90%', height: window.innerHeight, marginLeft: '5%', marginTop: '5%' }}>
                        <div className="card-body">
                            <div className="">
                                <table id="" className="table">
                                    <tbody id="">
                                        <tr className>
                                            <td rowSpan="3" colSpan="2" className="border">
                                                <div className="d-flex">
                                                    <div className="w-150px">
                                                        <img
                                                            alt="Logo"
                                                            src={logo}
                                                            className="logo-default max-h-80px"
                                                        />
                                                    </div>
                                                    <div className="text-center font-weight-bolder fs-24 pt-6 border-left-0 pl-10">
                                                        FICHE D'INTERVENTION PREVENTIVE
                                                    </div>
                                                </div>
                                            </td>
                                            <td className="text-center border fs-16">Code : FR23-S3 </td>
                                        </tr>
                                        <tr>
                                            <td className="text-center border fs-16">Version : 001</td>
                                        </tr>
                                        <tr>
                                            <td className="text-center border fs-16">Date de mise en application: 12-01-2022</td>
                                        </tr>
                                        <tr>
                                            <td className="border border-right-0"></td>
                                            <td className="border border-left-0 fs-16 text-right">
                                                Référence de la fiche : <span></span><br />
                                            </td>
                                            <td id="pi-ref" className="border border-left-0  fs-16" >{listIntervention2[0].pi_reference}</td>
                                        </tr>

                                        <tr className="text-center bg-light border">
                                            <td colSpan="3" className="text-center font-weight-bolder border fs-16">Détails</td>
                                        </tr>
                                        <tr>
                                            <td className=" border font-weight-bolder fs-16">Code de l'infrastructure </td>
                                            <td colSpan="2" id="pi-code-inf" className=" border fs-16 ">{listIntervention2[0].eq_code}</td>
                                        </tr>
                                        <tr>
                                            <td className=" border font-weight-bolder fs-16">Désignation de l'infrastructure </td>
                                            <td colSpan="2" id="eq-designation" className=" border fs-16">{listIntervention2[0].eq_designation}</td>
                                        </tr>
                                        <tr>
                                            <td className=" border font-weight-bolder fs-16">Etat/Age de l'infrastructure </td>
                                            <td colSpan="2" id="eq-age" className=" border fs-16 ">{listIntervention2[0].pi_material_age}</td>
                                        </tr>
                                        <tr>
                                            <td className=" border font-weight-bolder fs-16">Utilisateur</td>
                                            <td colSpan="2" id="eq-user" className=" border fs-16 ">{listIntervention2[0].eqUser}</td>
                                        </tr>
                                        <tr>
                                            <td className=" border font-weight-bolder fs-16">Intervenant/Technicien </td>
                                            <td colSpan="2" id="pi-support" className="border fs-16">{listIntervention2[0].user_name}</td>
                                        </tr>
                                        <tr>
                                            <td className=" border font-weight-bolder fs-16">Référence des modes opératoires</td>
                                            <td colSpan="2" id="operatoire-mode" className="border fs-16 "></td>
                                        </tr>
                                        <tr>
                                            <td className=" border font-weight-bolder fs-16">Lieu d'intervention </td>
                                            <td colSpan="2" id="pi-site" className=" border fs-16 ">{listIntervention2[0].site_name}</td>
                                        </tr>
                                        <tr>
                                            <td className=" border font-weight-bolder fs-16">Date prévue du debut de l'intervention </td>
                                            <td colSpan="2" id="pi-date" className="border fs-16">{moment(listIntervention2[0].pi_date_intervention).format("YYYY-MM-DD")}</td>
                                        </tr>


                                        <tr className="text-center bg-light border ">
                                            <td colSpan="3" className=" text-center font-weight-bolder border fs-16">Travaux effectué</td>
                                        </tr>


                                        <tr>
                                            <td className=" border font-weight-bolder fs-16 h-60px" >Date réel du debut de l'intervention </td>
                                            <td colSpan="2" id="pi-start-date" className="border fs-16">{moment(listIntervention2[0].pi_start_time).format('MMMM d, YYYY HH:MM:SS') === "Invalid date" ? ("Not start") : moment(listIntervention2[0].pi_start_time).format('YYYY-MM-DD hh:mm:ss')}</td>
                                        </tr>

                                        <tr>
                                            <td className=" border font-weight-bolder fs-16 h-60px" >Description des travaux effectués </td>
                                            <td colSpan="2" id="pi-work_done" className="border fs-16">{listIntervention2[0].pi_description_work_done}</td>
                                        </tr>

                                        <tr className=" border ">
                                            <td className="font-weight-bolder  border fs-16" rowSpan={listChoosenMaterials1.length + 1} >Pieces et Matériels requis</td>
                                            <td className=" text-center font-weight-bolder bg-light border fs-16">Référence </td>
                                            <td className=" text-center font-weight-bolder bg-light border fs-16">Quantité</td>
                                        </tr>
                                        {/* <tr className=" border "> */}

                                        {listChoosenMaterials1.map((d, index) => {
                                            return (<tr className=" border ">
                                                <td id="rm0" className="border fs-16">{d.mt_description}</td>
                                                <td id="rmv0" className="border fs-16 text-center">{listChoosenMaterials[index].qty}</td>
                                            </tr>)
                                        }

                                        )}

                                        <tr className=" border ">
                                            <td className="font-weight-bolder  border fs-16" rowSpan={listChoosenMaterials1.length + 1} >Pieces et Matériels utilisés  </td>
                                            <td className="text-center font-weight-bolder bg-light border fs-16">Référence </td>
                                            <td className="text-center font-weight-bolder bg-light border fs-16">Quantité </td>
                                        </tr>

                                        {listChoosenMaterials1.map((d, index) => {
                                            return (<tr className=" border ">
                                                <td id="rm0" className="border fs-16">{d.mt_description}</td>
                                                <td id="rmv0" className="border fs-16 text-center">{listChoosenMaterials[index].qty}</td>
                                            </tr>)
                                        }

                                        )}

                                        <tr>
                                            <td className=" border font-weight-bolder fs-16">Date et heure de fin de l'intervention  </td>
                                            <td colSpan="2" id="pi-date-end" className="border fs-16">{moment(listIntervention2[0].pi_end_time).format("YYYY-MM-DD HH:MM:SS") === "Invalid date" ? ("Not finished") : moment(listIntervention2[0].pi_end_time).format("YYYY-MM-DD HH:MM:SS")}</td>
                                        </tr>

                                        <tr>
                                            <td className=" border font-weight-bolder fs-16 h-110px" >Observations</td>
                                            <td colSpan="2" id="pi-remark" className="border fs-16">{listIntervention2[0].pi_remarque}</td>
                                        </tr>

                                        <tr>
                                            <td rowSpan="2" className=" border font-weight-bolder fs-16" >Réalisateur/Technicien</td>
                                            <td colSpan="2" className="text-center border font-weight-bolder fs-16">Nom et Signature :</td>
                                        </tr>
                                        <tr>
                                            <td colSpan="2" className="h-80px border fs-16"></td>
                                        </tr>
                                        <tr className="  border">
                                            <td rowSpan="2" className="border font-weight-bolder fs-16" >Utilisateur</td>
                                            <td colSpan="2" className=" text-center border font-weight-bolder fs-16">Nom et Signature :</td>
                                        </tr>
                                        <tr >
                                            <td colSpan="2" className="h-80px border fs-16"></td>
                                        </tr>
                                        <tr className="  border">
                                            <td rowSpan="3" className="border font-weight-bolder fs-16" >Responsable IT</td>
                                            <td colSpan="2" className=" text-center border font-weight-bolder fs-16">Nom et Signature :</td>
                                        </tr>
                                        <tr >
                                            <td colSpan="2" className="h-80px border fs-16"></td>
                                        </tr>

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                ) : (<></>)}
            </div>



            <Footer />
        </>
    );
}


export default Pintervention;